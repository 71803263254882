import React, {ReactElement, useContext, useMemo} from "react";
import {DisplayContext} from "../../context/display-context";
import {QuestionContext} from "../../context/question-context";
import {ProgressBar} from "../progress-bar";
import {PulseButton} from "../pulse-button";
import styles from "./question-nav.module.scss";
import {useMobileOrDesktop} from "../../hooks/useMobileOrDesktop";

export interface QuestionNavProps {
	/**
	 * Disclaimer text
	 */
	disclaimer: string | null;
	/**
	 * If user can continue or not
	 */
	canContinue: boolean;
	/**
	 * Question number we are currently on
	 */
	currentQuestion: number;
	/**
	 * Question count
	 */
	questionCount: number;
	anonymous: boolean;
	/**
	 * Accept answer and move on
	 */
	acceptAnswer: () => Promise<void>;
	isSkippable: boolean;
	skipQuestion: () => Promise<void>;
}

const QuestionNav = (props: QuestionNavProps): ReactElement => {
	const {
		disclaimer,
		canContinue,
		currentQuestion,
		questionCount, anonymous,
		acceptAnswer,
		isSkippable,
		skipQuestion,
	} = props;
	// eslint-disable-next-line max-len
	const {questionType, selected, numberAnswer, text, barcode, files, video, pdf} = useContext(QuestionContext);
	const {hideVideo, setHideVideo} = useContext(DisplayContext);

	const mobile = useMobileOrDesktop();

	const handleHideVideo = (): void => {
		setHideVideo(false);
	};

	const handleAccept = (): void => {
		acceptAnswer().then(() => setHideVideo(true));
		setHideVideo(true);
	};

	// There is probably a simpler way to do this but first thing that... happened.
	const isDisabled = useMemo(() => {
		if (canContinue) return false;
		// eslint-disable-next-line max-len
		if ((questionType === "SHORT" || questionType === "LONG" || questionType === "NUMBER") && text && hideVideo) return false;
		if (questionType === "RANKED" && hideVideo) return false;
		if (questionType === "BARCODE" && barcode.length > 0) return false;
		if (questionType === "NUMBER" && numberAnswer && hideVideo) return false;
		if (questionType === "VIDUPLOAD" && video && hideVideo) return false;
		if (questionType === "PDF" && pdf.length > 0 && hideVideo) return false;
		if (questionType === "PICTURE" && files.length > 0 && hideVideo) return false;
		if (questionType === "MULTISELECT" && selected.length > 0 && hideVideo) return false;
		// eslint-disable-next-line max-len
		if ((questionType === "STAR" || questionType === "SLIDER") && numberAnswer !== undefined && hideVideo) return false;
		if (mobile) {
			if (questionType === "CHOICE") return true;
			if (questionType === "MULTISELECT" && selected.length > 0) {
				if (!hideVideo) return true;
				return false;
			}
			return true;
		}
		return true;
	// eslint-disable-next-line max-len
	}, [questionType, selected, canContinue, barcode, hideVideo, mobile, numberAnswer, text, files, video, pdf]);

	return (
		<div className={styles.container}>
			<div className={styles.navigation}>
				<div id="questionCount" className={styles["count-container"]}>
					<div className={styles["top-questions"]}>
						<span>Question {currentQuestion} of {questionCount}</span>
						<div className={styles.logo}>
							<img src="images/favicon.png" />
							<span>vurvey</span>
						</div>
					</div>
					<ProgressBar percent={((currentQuestion - 1) / questionCount) * 100}/>
				</div>
				<div className={styles.actions}>
					{!anonymous && <a
						href="https://help.vurvey.co"
						className={styles.link}
						target="_blank"
						rel="noreferrer noopener"
					>
						Help
					</a>}
					{isSkippable && <button className={styles.skip} onClick={skipQuestion}>Skip &#62;</button>}
					<PulseButton
						isDisabled={isDisabled}
						text="Next"
						clicked={canContinue ? handleAccept : handleHideVideo}
					/>
				</div>
			</div>
			{
				Boolean(disclaimer) && <div className={styles.disclaimer}>
					{disclaimer}
				</div>
			}
		</div>
	);
};

export {QuestionNav};
