import React, {ReactElement, useRef} from "react";
import styles from "./video-input.module.scss";
import {Input} from "../input";
import {Icon} from "../icon";

import classNames from "classnames";
import {Spinner} from "../spinner";

export interface VideoInputProps {
	id: string;
	onChange: (file: FileList | null) => void;
	loading: boolean;
	maxFileSize: number;
	rawFile?: FileList;
	handleDelete: () => void;
}

const VideoInput = (props: VideoInputProps): ReactElement => {
	const {id, onChange, handleDelete, rawFile, loading, maxFileSize} = props;
	const ref = useRef<HTMLInputElement>(null);

	const deleteVideo = () => {
		if (ref.current) {
			ref.current.value = "";
		}
		handleDelete();
	}
	return (<>
		<Input id={id}>
			<label htmlFor={id} className={classNames(!loading && !rawFile ? styles.input : styles.uploaded)}>
				{ !loading && !rawFile ?
					<>
						<Icon name="upload" />
						<div className={styles.middle}>
							<span>Upload Video</span>
							<span>.MP3, .MP4, .MOV</span>
							<span>max file size {maxFileSize} MB</span>
						</div>
					</>
					: loading ? <Spinner className={styles.spinner}/> : <span>Uploaded</span>
				}
				<input
					type="file"
					id={id}
					ref={ref}
					onChange={e => {
						try {
							onChange(e.target.files)
						} catch {
							e.target.value = "";
						}
					}}
					disabled={rawFile && rawFile.length > 0}
					accept="video/*"
				/>
			</label>
		</Input>
		{rawFile && rawFile.length === 1 &&
			<div className={styles.preview}>
				<span>Video preview</span>
				<div className={styles.vidContainer}>
					<div className={styles.delete} onClick={deleteVideo}>X</div>
					<video
						src={URL.createObjectURL(rawFile[0])}
						className={styles.video}
						controls
						controlsList="nodownload"
					/>
				</div>
			</div>
		}
		</>
	);
};

export {VideoInput};
