import { useNavigate } from "react-router-dom";
import {useSendPasswordResetEmail} from "react-firebase-hooks/auth";
import React, {FormEvent, ReactElement} from "react";

import { useSignUpFlow } from "../../../contexts/sign-up-flow-context";
import { Button, Input, StepTemplate } from "../../../components/sign-up-flow";
import { auth } from "../../../utils/firebase";

import styles from "./recover-password.module.scss";

export const RecoverPassword = (): ReactElement => {
	const navigate = useNavigate();
	const [sendPasswordResetEmail] = useSendPasswordResetEmail(auth);
	const {state: {email}} = useSignUpFlow();

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		sendPasswordResetEmail(email ?? "");
		navigate("/login/check-email");
	};

	return (
		<StepTemplate
			imageSrc="/images/sign-up-photo.jpg"
			header="Recover Password"
			revertRoute="/login"
			description="Get instructions sent to this email that explain how to reset your password"
			imageText={
				<>
          “Vurvey is the future <br />
          of brand-consumer <br />
          relationships. <br />
          Be part of it.”
				</>
			}
		>
			<form className={styles.form} onSubmit={handleSubmit}>
				<Input
					placeholder="Email"
					type="text"
					disabled
					value={email ?? ""}
				/>

				<div className={styles.buttonsSection}>
					<Button id="recover-password-button" type="submit">Send</Button>
				</div>
			</form>
		</StepTemplate>
	);
};
