import React, {ReactElement, useContext} from "react";
import {animated, useSpring} from "react-spring";
import {DisplayContext} from "../../context/display-context";
import {Icon} from "../icon";
import styles from "./video-question.module.scss";
import {useMobileOrDesktop} from "../../hooks/useMobileOrDesktop";

export interface VideoQuestionProps {
	/**
	 * If we should show a back arrow on our video recording
	 */
	hasChoices: boolean;
	/**
	 * Video recorder component to pass here
	 */
	videoRecorder: JSX.Element | null;
}

/**
 * @param hasChoices Determines if we should show a back arrow
 * @param videoRecorder Our mediarecorder
 * @param isHidden Determines if the video should be showing or not
 * @param handleBack Callback to handle when user clicks on the back button
 */
const VideoQuestion = (props: VideoQuestionProps): ReactElement => {
	const {hasChoices, videoRecorder} = props;
	const {hideVideo, setHideVideo} = useContext(DisplayContext);
	const windowSize = useMobileOrDesktop();

	const animatedProps = useSpring({
		visibility: hideVideo && hasChoices ? "hidden" : "visible" as any,
		left: hideVideo && hasChoices ? "100%" : "0%" as any,
	});

	const mobileAnimated = useSpring({
		transform: hideVideo && hasChoices ? "translateX(100%)" : "translateX(0%)" as any,
	});

	// We need to be sure the previewStream exists before rendering anything
	return (
		<animated.div
			style={windowSize ? mobileAnimated : animatedProps}
			className={
				windowSize ? [styles.container, styles.absolute].join(" ") : styles.container
			}
		>
			{ hasChoices && <div
				id="backArrow"
				onClick={() => setHideVideo(true)}
				className={styles.arrow}
			>
				<Icon name="arrow"/>
			</div>
			}
			{videoRecorder}
		</animated.div>
	);
};

export {VideoQuestion};
