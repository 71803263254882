import React, {ReactElement} from "react";
import {Input, InputProps} from "../input";
import styles from "./text.module.scss";


export interface TextProps extends InputProps {
	value: string | undefined;
	limit?: number;
	onChange: (newValue: string) => void;
	placeholder?: string;
}

const Text = ({value, id, limit, onChange, placeholder, ...props}: TextProps): ReactElement =>
	<Input {...props} id={id}>
		<input
			type="text"
			id={id}
			className={styles.text}
			value={value || ""}
			maxLength={limit}
			placeholder={placeholder}
			onChange={e => onChange(e.target.value)}
		/>
		{
			limit &&
			<div className={styles.limit}>
				{value?.length || 0}/{limit}
			</div>
		}
	</Input>;

export {Text};
