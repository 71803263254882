import React, {ReactElement} from "react";
import {useSignInWithApple} from "react-firebase-hooks/auth";
import {SIGNUP_METHODS, auth} from "../../../utils/firebase";
import {useNavigate} from "react-router-dom";

import styles from "./buttons.module.scss";
import {sendAnalyticsEvent, setAnalyticsUser} from "../../../utils/analytics";
import {getAdditionalUserInfo} from "firebase/auth";
import classNames from "classnames";

export interface AppleSignInButtonProps {
	text?: string;
	id?: string;
}

const AppleSignInButton = (props: AppleSignInButtonProps): ReactElement => {
	const {text = "Sign up with Apple", id} = props;
	const [signInWithApple] = useSignInWithApple(auth);
	const navigate = useNavigate();
	const surveyId = localStorage.getItem("surveyId");

	const handleSignIn = (): void => {
		if (text === "Sign up with Apple") {
			sendAnalyticsEvent("sign_up", SIGNUP_METHODS.APPLE);
		}
		signInWithApple().then(result => {
			if (!result) return;
			const {isNewUser} = getAdditionalUserInfo(result) ?? {};
			setAnalyticsUser(result.user.uid);

			if (isNewUser) {
				sendAnalyticsEvent("create_account", SIGNUP_METHODS.APPLE);
				sendAnalyticsEvent("complete_registration", SIGNUP_METHODS.APPLE);
				sendAnalyticsEvent("login", SIGNUP_METHODS.APPLE);
				navigate(`/${surveyId}`);
				return;
			}
			sendAnalyticsEvent("login", SIGNUP_METHODS.APPLE);
			navigate(`/${surveyId}`);
		});
	};
	return (
		<button id={id} onClick={handleSignIn} className={classNames(styles.authButton, styles.apple)}>
			<img src="images/apple-icon.svg" alt="Apple Icon" className={styles.icon}/>
			<span className={styles.authButtonText}>{text}</span>
		</button>
	)
};

export {AppleSignInButton};
