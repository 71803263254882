/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/indent */
import React, {ReactElement, useContext} from "react";
import {QuestionContext} from "../../context/question-context";
import {Question} from "../../models/question";
import {NumberInput} from "../number-input";
import {Text} from "../text";
import {Textarea} from "../textarea";
import styles from "./text-question.module.scss";

export interface TextQuestionProps {
	question: Question;
}

const TextQuestion = (props: TextQuestionProps): ReactElement => {
	const {question} = props;
	const {text, setText, numberAnswer, setNumberAnswer} = useContext(QuestionContext);

	return (
		<div className={styles.container}>
			{
				question.subtype === "long" ?
				<Textarea id="answer-text" className={styles.input} value={text} onChange={setText} placeholder="Enter your response..."/>
				: question.subtype === "short" ?
				<Text id="answer-text" className={styles.input} value={text} onChange={setText} limit={100} placeholder="Enter your response..."/>
				: <NumberInput value={numberAnswer} id="answer-text" className={styles.input} onChange={setNumberAnswer}/>
			}
		</div>
	);
};

export {TextQuestion};
