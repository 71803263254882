import {useAuthState} from "react-firebase-hooks/auth";
import {useCallback} from "react";
import {sendEmailVerification, signOut} from "firebase/auth";

import {auth} from "../../utils/firebase";
import {useQuery} from "@apollo/client";
import {GET_ME} from "../../graphql/queries/queries";
import {MeData} from "../../models/user";
import config from "../../config";

// eslint-disable-next-line
export const useAuthentication = () => {
	const [user, loading, error] = useAuthState(auth);
	const {data: me} = useQuery<MeData>(GET_ME);

	const surveyId = localStorage.getItem("surveyId");

	const logout = useCallback(() => {
		return signOut(auth);
	}, []);

	return {
		me,
		user,
		loading,
		error,
		logout,
	};
};
