import classNames from "classnames/bind";
import React, {ReactElement, ButtonHTMLAttributes, cloneElement} from "react"


import styles from "./button.module.scss"
import {Body} from "../typography";

const bStyles = classNames.bind(styles);

export interface ButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, "style"> {
	variant?: "filled" | "outlined" | "text";
	style?: "brand" | "danger" | "ai";
	size?: "default" | "small";
	leftIcon?: ReactElement;
	rightIcon?: ReactElement;
	className?: string;
	filledIcon?: boolean;
	animated?: boolean;
}

const Button = ({
	children,
	variant = "filled",
	style = "brand",
	size = "default",
	leftIcon,
	rightIcon,
	className,
	filledIcon,
	animated = false,
	...props
}: ButtonProps): ReactElement => {

	const renderButtonContent = () :ReactElement => {
		if (size === "small") {
			return <Body size="s" type="medium" className={styles.content}>{children}</Body>
		}

		return <Body type="medium" className={styles.content}>{children}</Body>
	}

	return (
		<button
			className={
				bStyles(
					"button",
					variant,
					style,
					size,
					className,
					{hasLeftIcon: leftIcon, hasRightIcon: rightIcon, animated}
				)}
			{...props}
		>
			{leftIcon && cloneElement(leftIcon, {className: bStyles("icon", {filledIcon})})}
			{renderButtonContent()}
			{rightIcon && cloneElement(rightIcon, {className: bStyles("icon", {filledIcon})})}
		</button>
	)
}

export {Button}
