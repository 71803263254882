import {
	ChoiceQuestion,
	Question,
	QuestionType,
	SliderQuestionType,
	TextQuestionType,
	VideoUploadQuestionType,
	ImageUploadQuestion,
} from "../../models/question";
import React, {ReactElement, useContext} from "react";
import {animated, useSpring} from "react-spring";
import {CustomDragLayer} from "../custom-drag-layer";
import {MultipleChoice} from "../multiple-choice";
import {VideoQuestion} from "../video-question";
import styles from "./question.module.scss";
import {FileUploadQuestion} from "../file-upload";
import {SliderQuestion} from "../slider-question";
import {DisplayContext} from "../../context/display-context";
import {QuestionMediaDisplay} from "../question-media-display";
import {useMobileOrDesktop} from "../../hooks/useMobileOrDesktop";
import {TextQuestion} from "../text-question";
import {VideoUploadQuestion} from "../video-upload";
import {ScanQuestion} from "../scan-question";

export interface QuestionDisplayProps {
	/**
	 * Question we are displaying
	 */
	question: Question;
	/**
	 * Video recorder
	 */
	videoRecorder: JSX.Element | null;
	/**
	 * Handle opening image modal
	 */
	handleShowModal: () => void;
}

/* eslint-disable max-len */
const isChoice = (m: Question): m is ChoiceQuestion => m.type === QuestionType.CHOICE && Boolean(m.choices);
const isSlider = (m: Question): m is SliderQuestionType => m.type === QuestionType.SLIDER;
const isText = (m: Question): m is TextQuestionType => m.type === QuestionType.TEXT && Boolean(m.subtype);
const isVideoUpload = (m: Question): m is VideoUploadQuestionType => m.type === QuestionType.PICTURE && m.subtype === "video";
const isPicture = (m: Question): m is ImageUploadQuestion => m.type === QuestionType.PICTURE && m.subtype === "picture";
const isPdf = (m: Question): m is ImageUploadQuestion => m.type === QuestionType.PICTURE && m.subtype === "pdf";
/* eslint-enable max-len */

const QuestionDisplay = (props: QuestionDisplayProps): ReactElement => {
	const {question, videoRecorder, handleShowModal} = props;
	const {hideVideo} = useContext(DisplayContext);
	const spring = useSpring({
		transform: "translateY(0)",
		from: {transform: "translateY(100%)"},
		config: {duration: 400},
	});
	const windowSize = useMobileOrDesktop();
	const animatedProps = useSpring({
		left: hideVideo && question.videoResponse ? "25%" : "0%",
	});
	const mobileAnimation = useSpring({
		transform: hideVideo || !question.videoResponse	? "translateX(0%)" : "translateX(-100%)",
	});

	return (
		<animated.div style={spring} className={styles.container}>
			<CustomDragLayer />
			{ question.type !== QuestionType.VIDEO && question.type !== QuestionType.BARCODE &&
			<animated.div
				style={windowSize ? mobileAnimation : animatedProps}
				className={styles.questionContainer}
			>
				<h2 className={styles.questionText}>{question.text}</h2>
				<QuestionMediaDisplay
					media={{image: question.image,
						arModel: question.arModel,
						video: question.video}}
					handleShowModal={handleShowModal}
				/>
				{
					/* eslint-disable @typescript-eslint/indent */
					isSlider(question)
					? <SliderQuestion question={question}/>
					: isChoice(question) ?
					<MultipleChoice question={question}/>
					: isText(question) ?
					<TextQuestion question={question}/>
					: isPicture(question) ?
					<FileUploadQuestion question={question} mobile={windowSize} type="image"/>
					: isVideoUpload(question) ?
					<VideoUploadQuestion question={question}/>
					: isPdf(question) ?
					<FileUploadQuestion question={question} mobile={windowSize} type="pdf"/>
					: null
					/* eslint-enable @typescript-eslint/indent */
				}
			</animated.div>
			}
			{
				question.type === QuestionType.BARCODE &&
				<ScanQuestion question={question} />
			}
			{question.videoResponse && <VideoQuestion
				hasChoices={question.type !== QuestionType.VIDEO}
				videoRecorder={videoRecorder}
			/>}
		</animated.div>
	);
};

export {QuestionDisplay};
