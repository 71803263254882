import {InMemoryCache, makeVar} from "@apollo/client";

export const completedSurvey = makeVar<boolean>(false);
export const cache: InMemoryCache = new InMemoryCache({
	typePolicies: {
		User: {
			fields: {
				moreInfo: {
					read(_, {readField}) {
						const gender = readField("gender");
						const countryId = readField("countryId");
						const birthdate = readField("birthdate");
						return !(birthdate && countryId && gender);
					},
				},
			},
		},
		Query: {
			fields: {
				me: {
					merge(_ignored, incoming: any) {
						return incoming;
					},
				},
				questions: {
					read(existing) {
						return existing;
					},
					merge(_ignored, incoming: any) {
						return incoming;
					},
				},
				completedSurvey: { // Set true if the user has completed the survey
					read() {
						return completedSurvey();
					},
				},
			},
		},
	},
});
