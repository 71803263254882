import {useEffect, useState} from "react";

/**
 * Returns the width of the current window;
 */
export const useMobileOrDesktop = (): boolean => {
	const [isSmall, setIsSmall] = useState<boolean>(window.innerWidth < 920);
	useEffect(() => {
		const handleResize = (): void => {
			if (window.innerWidth < 920) {
				setIsSmall(true);
			} else {
				setIsSmall(false);
			}
		};
		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	});
	return isSmall;
};
