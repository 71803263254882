import {User, getAuth, sendEmailVerification} from "firebase/auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import {getAnalytics} from "firebase/analytics";
import config from "../config";

export const firebaseProvider = firebase;
export const app = firebase.initializeApp(config.firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);

export const SIGNUP_METHODS = {
	GMAIL: "sso_gmail",
	MICROSOFT: "sso_microsoft",
	EMAIL: "email",
	APPLE: "sso_apple",
};

export const sendVerificationEmail = (user: User) => {
	sendEmailVerification(user);
};
