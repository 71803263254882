import React, {useContext, useEffect, useRef, useState} from "react";
import {CameraDevice, Html5Qrcode} from "html5-qrcode";
import {QrDimensions} from "html5-qrcode/esm/core";
import {ToastContext} from "../context/toast-context";
import styles from "./scanner-styles.module.scss";
import {Setter} from "../types";
import {QuestionContext} from "../context/question-context";

export interface UseScannerReturn {
	video: JSX.Element;
	scannedText: string;
	stopScan: () => void;
	// eslint-disable-next-line max-len
	startVideo: (devices?: CameraDevice[]) => void; // If we want this more customizable, it would take configs.
	resumeScan: () => void // We pause the scan after detected. Allows us to resume if needed.
	setScannedText: Setter<string>;
}

export const useScanner = (): UseScannerReturn => {
	const [scannedText, setScannedText] = useState("");
	const {updateToast} = useContext(ToastContext);
	const {isPreview} = useContext(QuestionContext);
	const scanRef = useRef<Html5Qrcode | null>(null);

	const qrboxFunction = (viewfinderWidth: number, viewfinderHeight: number): QrDimensions => {
		const minEdgePercentage = 0.75; // 75%
		const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
		const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
		return {
			width: qrboxSize,
			height: qrboxSize,
		};
	};

	const startVideo = (): void => {
		scanRef.current = new Html5Qrcode("reader"); // So we can use it in other functions later.
		scanRef.current.start(
			// We are setting this static for now, get rid of before merging and switch to facingMode
			// eslint-disable-next-line max-len
			{facingMode: "environment"},
			{
				fps: 10,
				qrbox: qrboxFunction,
			},
			decodedText => {
				setScannedText(decodedText);
				scanRef.current?.stop(); // the "true" will also pause the video feed.
			},
			// Error handling if we find a good use for it.
			() => {
				// The errors usually are just constant "NotFound" and not really useful.
			},
		);
	};

	const stopScan = (): void => {
		if (scanRef.current?.isScanning) scanRef.current?.stop();
	};

	const resumeScan = (): void => {
		setScannedText("");
		scanRef.current?.resume();
	};

	useEffect(() => {
		if (isPreview) return;
		Html5Qrcode.getCameras().then(devices => {
			if (devices) startVideo();
		}).catch(err => {
			// eslint-disable-next-line no-console
			console.log(err);
			// The most likely errors are that they denied camera access, or they don't have a camera
			updateToast({description: "This question requires camera access", type: "failure"});
		});
	}, [isPreview]);

	const video = isPreview ?
		<div className={styles.fake}/>
		: <div id="reader" className={styles.scan}/>;

	return {
		video,
		scannedText,
		startVideo,
		stopScan,
		resumeScan,
		setScannedText,
	};
};
