import React, {ReactElement, useRef} from "react";
import {DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from "react-dnd";
import {Choice} from "../../models/answer";
import classnames from "classnames/bind";
import styles from "./option-item.module.scss";
import {QuestionSubtype} from "../../models/question";
import {DragItemTypes} from "../../constants/constants";
import {Icon} from "../icon";
import {checkDraggingReplace} from "../../utility/helpers";

const bStyles = classnames.bind(styles);

export interface OptionItemProps {
	choice: Choice;
	selected: boolean;
	questionType: QuestionSubtype
	index: number;
	onSelect?: (newChoice: string) => void;
	dragOption: (dragIndex: number, hoverIndex: number) => void;
	moveOption: (dragIndex: number, dropIndex: number) => void;
}

const OptionItem = (props: OptionItemProps): ReactElement => {
	const {choice, selected, questionType, index, dragOption, moveOption, onSelect} = props;
	const containerRef = useRef<HTMLDivElement>(null);
	const handleRef = useRef<HTMLDivElement>(null);

	const [, drop] = useDrop({
		accept: DragItemTypes.OPTION,
		hover(item: any, monitor: DropTargetMonitor) {
			if (checkDraggingReplace(containerRef, item.index, index, monitor.getClientOffset())) {
				dragOption(item.index, index);
				item.index = index;
			}
		},
		drop(item: any, monitor: DropTargetMonitor) {
			if (checkDraggingReplace(containerRef, item.index, index, monitor.getClientOffset())) {
				moveOption(item.index, index);
			}
		},
	});

	const [{isDragging}, drag] = useDrag(
		() => ({
			type: DragItemTypes.OPTION,
			canDrag: questionType === "RANKED",
			item: {type: DragItemTypes.OPTION, index, text: choice.text},
			collect: (monitor: DragSourceMonitor) => ({
				isDragging: monitor.isDragging(),
			}),
		}),
		[index, choice.text],
	);

	// // Allows us to use a custom drag layer
	// useEffect(() => {
	// 	preview({captureDraggingState: true});
	// }, []);

	if (questionType === "RANKED") drag(drop(containerRef));

	return (
		<div
			className={bStyles(
				"option",
				questionType === "MULTISELECT" && "multiselect",
				{selected, isDragging, draggable: questionType === "RANKED"},
			)}
			onClick={() => onSelect?.(choice.id)}
			ref={containerRef}
		>
			{questionType === "MULTISELECT" && <div className={bStyles("checkbox", {selected})}/>}
			{questionType === "RANKED" &&
			<div className={bStyles("number", {isDragging})}>{index + 1}</div>
			}
			{choice.text}
			{questionType === "RANKED" &&
			<div
				ref={handleRef}
				className={styles.handle}><Icon name="handle"/></div>
			}
		</div>
	);
};

OptionItem.displayName = "OptionItem";

export {OptionItem};
