import {Decoder, Reader, tools} from "ts-ebml";
import {Question, QuestionSubtype, QuestionType} from "../models/question";
import {isParseable} from "./helpers";
import {format} from "date-fns";
import {CurrencyType} from "../models/survey";


export const formatDate = (date: Date): string => format(new Date(date), "MMM d, yyyy");
export const formatLong = (date: Date): string => format(new Date(date), "MMM d, yyyy h:mm a");
export const formatTZ = (date: Date): string => format(new Date(date), "MMM d, yyyy h:mm a (OOOO)");

/**
 * This function is generally used to update state immutably.
 *
 * @param oldObject The object we want to update
 * @param updatedProperties The properties of said object we want to update
 *
 * @returns A copy of the oldObject but includes updatedProperties where applicable
 */
export const updateObject = <T>(oldObject: T, updatedProperties: Partial<T>): T => {
	return {
		...oldObject,
		...updatedProperties,
	};
};

/**
 *
 * @param blob Blob file we want to convert to array buffer
 *
 * @returns An error if there is one, or the converted blob file as an array buffer
 */
export const readAsArrayBuffer = async(blob: Blob): Promise<ArrayBuffer> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsArrayBuffer(blob);
		reader.onloadend = () => {
			resolve(reader.result as ArrayBuffer);
		};
		reader.onerror = () => {
			reject(reader.error);
		};
	});
};

/**
 * This was created due to how Steve is creating the blob on the recordy-boi.
 * Unsure if we completely need this right now but figure it's a good idea still.
 * @param blob Blob file we want to inject data into
 * @returns Blob with our injected metadata into it.
 */
export const injectMetadata = async(blob: Blob): Promise<Blob> => {
	const decoder = new Decoder();
	const reader = new Reader();
	reader.logging = false;
	reader.drop_default_duration = false;

	const buffer = await readAsArrayBuffer(blob);

	const elms = decoder.decode(buffer);
	for (const elm of elms) reader.read(elm);
	reader.stop();

	const metadataBuffer = tools.makeMetadataSeekable(
		reader.metadatas,
		reader.duration,
		reader.cues,
	);
	const body = buffer.slice(reader.metadataSize);

	return new Blob([metadataBuffer, body], {type: blob.type});
};

/**
 * Takes a total time in seconds and converts it to M:SS format
 * @param totalSeconds Time in seconds
 */
export const formatTime = (totalSeconds: number): string => {
	const minutes = Math.floor(totalSeconds / 60);
	const seconds = (totalSeconds % 60).toString().padStart(2, "0");
	return `${minutes}:${seconds}`;
};

/**
 * Given any array it shuffles the array in place.
 * @param array Any array
 */
export const shuffleArray = (array: any[]): void => {
	for (let i = array.length - 1; i > 0; i -= 1) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
};

/**
 * Given the stringified JSON object of the RTE, checks if it is empty or not and returns
 * true or false based on it.
 * True means there is content
 * False means no content.
 */
export const checkIfEmpty = (value: string): boolean => {
	if (isParseable(value)) {
		const richJson = JSON.parse(value);

		if (richJson.length > 1) return true;
		const [descendent] = richJson;

		const {children} = descendent;

		if (children.length > 1) return true;
		// eslint-disable-next-line prefer-destructuring
		const {text} = children[0];
		return text.trim() !== "";
	}
	return true;
};

export const currencySymbol = (type: CurrencyType): string => {
	switch (type) {
		case "EUR":
			return "€";
		case "CAD":
			return "C$";
		case "THB":
			return "฿";
		case "CNY":
			return "CN¥";
		case "GBP":
			return "£";
		default:
			return "$";
	}
};

export const determineQuestionType = (question?: Question): QuestionSubtype => {
	if (!question) return "VIDEO";
	if (question.type === QuestionType.BARCODE) return "BARCODE";
	if (question.type === QuestionType.PICTURE) {
		return question.subtype === "picture"
			? "PICTURE"
			: question.subtype === "pdf" ? "PDF" : "VIDEO";
	}
	if (question.type === QuestionType.CHOICE) {
		return question.max as number > 1 && !question.rank ?
			"MULTISELECT" :
			question.rank ?
				"RANKED"
				: "CHOICE";
	}
	if (question.type === QuestionType.TEXT) {
		if (question.subtype === "short") return "SHORT";
		if (question.subtype === "long") return "LONG";
		return "NUMBER";
	}
	if (question.type === QuestionType.SLIDER) {
		if (question.subtype === "star") return "STAR";
		return "SLIDER";
	}
	return "VIDEO";
};

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
	const result = Array.from(list);
	const [removed] = result.splice(startIndex, 1);
	result.splice(endIndex, 0, removed);
	return result;
};

export const singularOrPlural = (word: string, n: number): string => (n > 1 ? `${word}s` : word);
