import React, {ReactElement, useCallback, useEffect, useState} from "react";
import styles from "./toast.module.scss";

export interface ToastItem {
	/**
	 * Text to display in toast
	 */
	description: string;
	/**
	 * What kind of toast it is
	 */
	type: "success" | "failure" | "informational";
}

export interface ToastProps {
	/**
	 * The toast to display
	 */
	toastItem?: ToastItem;
	/**
	 * Position the toast should display on the screen
	 */
	position: "top" | "bottom";
}

const Toast = (props: ToastProps): ReactElement => {
	const {toastItem, position} = props;
	const [toast, setToast] = useState<ToastItem | undefined>(toastItem);

	/**
	 * Sets the toast message whenever a toastItem is given
	 */
	useEffect(() => {
		setToast(toastItem);
	}, [toastItem]);
	/**
	 * Sets the interval for clearing the toast message
	 */
	useEffect(() => {
		let interval: NodeJS.Timeout;
		if (toast) {
			interval = setInterval(() => {
				setToast(undefined);
			}, 2500);
		}
		return () => clearInterval(interval);
	}, [toast]);

	const getStyle = useCallback(() => {
		if (toast) {
			if (toast.type === "failure") return styles.failure;
			if (toast.type === "informational") return styles.info;
			return styles.success;
		}
	}, [toast?.type]);
	return (
		<div
			className={[styles.container, styles[position]].join(" ")}
		>
			{/* {toast && toast.description} */}
			{toast && <p
				className={[getStyle(), styles.toast, styles[position]].join(" ")}>
				{toast.description}
			</p>
			}
		</div>
	);
};

export {Toast};
