import React, {ReactElement} from "react";
import styles from "./preview-bar.module.scss";

const PreviewBar = (): ReactElement => {
	return (
		<div className={styles.preview}>
			<p>Survey preview mode. Responses are not saved.</p>
		</div>
	);
};

export {PreviewBar};
