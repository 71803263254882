import React, { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

import {
  StepTemplate,
  GoogleSignInButton,
  MicrosoftSignInButton,
  EmailSignInButton,
	AppleSignInButton,
} from "../../../components/sign-up-flow";
import { Paragraph } from "../../../components/sign-up-flow/paragraph";

import styles from "./initial.module.scss";

export const Initial = (): ReactElement => {
	const navigate = useNavigate();
	const surveyId = localStorage.getItem("surveyId");

	return (
		<StepTemplate
			imageSrc="/images/login-photo.jpg"
			revertRoute={`/${surveyId}`}
			imageText={
				<>
          “It&apos;s like <br />
          private TikTok <br />
          directly with <br />
          brands”
				</>
			}
		>
			<div className={styles.signInButtons}>
				<GoogleSignInButton id="google-sign-up"/>
				<MicrosoftSignInButton id="microsoft-sign-up"/>
				<AppleSignInButton id="apple-sign-up" />
				<EmailSignInButton id="email-sign-up"/>
			</div>
			<div className={styles.bottomSection}>
				<Paragraph color="gray-800" className={styles.terms}>
					Already have an account? Login {" "}
					<a
						onClick={() => navigate("/login/choose-method")}
						className={styles.link}
					>
						here
					</a>
				</Paragraph>
				<Paragraph color="gray-800" className={styles.terms}>
          By signing up you agree to Vurvey&apos;s&nbsp;
					<a
						className={styles.link}
						target="_blank"
						href="https://vurvey.ai/terms-conditions/"
						rel="noreferrer">
              Terms and Conditions.
					</a>
				</Paragraph>
				<Paragraph color="gray-800" className={styles.terms}>
          Read our&nbsp;
					<a
						className={styles.link}
						href="https://vurvey.ai/privacy-policy/"
						target="_blank"
						rel="noreferrer">
            Privacy Policy.
					</a>
				</Paragraph>
			</div>
		</StepTemplate>
	);
};
