import extend from "extend";

export interface Config {
	apiHost: string;
	managerHost: string;
	creatorHost: string;
	logRocketProjectName: string;
	bugsnagApiKey: string;
	bugsnagReleaseStage: string;
	intercomAppId: string;
	firebaseConfig: {
		apiKey: string;
		authDomain: string;
		projectId: string;
		storageBucket: string;
		messagingSenderId: string;
		appId: string;
		measurementId: string;
	};
}

export type Environment = "dev" | "test" | "staging" | "prod";
const base: Partial<Config> = {
	apiHost: "https://localhost:3000",
	bugsnagApiKey: "4d2140bc37294d2a830a47e69a131d23",
	managerHost: "https://localhost:5050",
	creatorHost: "http://localhost:4080",
	/*
	        Using a single client id (the "manager" id until we rename) so
	        that we don't need to login seperately for responder.
	*/
	bugsnagReleaseStage: "development",
	firebaseConfig: {
		apiKey: "AIzaSyBo3WI_YKn6yr0H6Dl_8me9N9u6_Wciskg",
		authDomain: "fir-auth-cdeac.firebaseapp.com",
		projectId: "fir-auth-cdeac",
		storageBucket: "fir-auth-cdeac.appspot.com",
		messagingSenderId: "1046730303135",
		appId: "1:1046730303135:web:d0c03cca3e99bba26564aa",
		measurementId: "G-1M077C3JF7",
	},
};

const envs: {[key in Environment]: Partial<Config>} = {
	dev: {},
	test: {
		apiHost: "https://api2.vurvey.dev",
		managerHost: "https://vurvey.dev",
		creatorHost: "https://creator.vurvey.dev",
		logRocketProjectName: "vurvey/vurvey-responder-dev",
		bugsnagReleaseStage: "testing",
		intercomAppId: "ismzpdxk",
	},
	staging: {
		apiHost: "https://api-staging.vurvey.dev",
		managerHost: "https://staging.vurvey.dev",
		creatorHost: "https://creator.staging.vurvey.dev",
		logRocketProjectName: "vurvey/vurvey-responder-dev",
		bugsnagReleaseStage: "staging",
	},
	prod: {
		apiHost: "https://api.vurvey.app",
		managerHost: "https://vurvey.app",
		creatorHost: "https://vurveyreviews.com",
		logRocketProjectName: "vurvey/vurvey-responder-prod-trcyk",
		bugsnagReleaseStage: "production",
		intercomAppId: "ismzpdxk",
		firebaseConfig: {
			apiKey: "AIzaSyAq2XOtJ4KBoxSUeQ8pQLRUNq2NDr2fO0U",
			authDomain: "fir-prod-290cd.firebaseapp.com",
			projectId: "fir-prod-290cd",
			storageBucket: "fir-prod-290cd.appspot.com",
			messagingSenderId: "536194458678",
			appId: "1:536194458678:web:89f091633750ced8886497",
			measurementId: "G-NCMJ36GX6C",
		},
	},
};

const env = envs[import.meta.env.REACT_APP_ENV || "dev"];
const config: Config = extend({}, base, env);

export default config;
export {config};
