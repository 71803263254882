import React, {ReactElement} from "react";
import {Spinner} from "../../components/spinner";
import {EndLayout} from "../../hoc/end-layout";

const LoadingPage = (): ReactElement => (
	<EndLayout>
		<Spinner />
	</EndLayout>
);

export {LoadingPage};
