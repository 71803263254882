import React, {ReactElement, useState} from "react";
import {Question} from "../../models/question";
import styles from "./video-upload.module.scss";
import {useVideoUpload} from "../../hooks/useVideoUpload";
import {VideoInput} from "../video-input";

export interface VideoUploadQuestionProps {
	question: Question;
}

const MAX_FILE_SIZE = 25;

const VideoUploadQuestion = ({question}: VideoUploadQuestionProps): ReactElement => {
	const [rawFile, setRawFile] = useState<FileList | undefined>();
	const {loading, handleChange, setVideo} = useVideoUpload(rawFile, setRawFile, MAX_FILE_SIZE);

	const handleDelete = (): void => {
		setVideo(null);
		setRawFile(undefined);
	};

	return (
		<div className={styles.container}>
			<VideoInput
				id="video-upload"
				loading={loading}
				maxFileSize={MAX_FILE_SIZE}
				onChange={handleChange}
				rawFile={rawFile}
				handleDelete={handleDelete}
			/>
		</div>
	);
};

export {VideoUploadQuestion};
