import React, {ReactElement} from "react";
import {animated, useSpring} from "react-spring";
import styles from "./request-camera.module.scss";
import {Body} from "../typography";
import classNames from "classnames/bind";

const bStyles = classNames.bind(styles);

export interface RequestCameraProps {
	handleHide: () => void;
}

const RequestCamera = ({handleHide}: RequestCameraProps): ReactElement => {
	const animatedProps = useSpring({
		transform: "translateY(0)",
		from: {transform: "translateY(-100vh)"},
	});

	return (
		<animated.div style={animatedProps} className={styles.container}>
			<Body className={styles.text}>
				We need access to your camera and microphone so you can respond to video questions.
				Enable access and make sure all other recording applications are closed.
			</Body>
			<Body className={bStyles("text", "enableInstructions")}>
				You can enable camera access by following these steps:
			</Body>
			<div className={styles.list}>
				<Body className={styles.text} size="xs">
					<b>Chrome:</b> Click the padlock icon in the address bar, then select "Site settings" and change the camera permission to "Allow".
				</Body>
				<Body className={styles.text} size="xs">
						<b>Firefox:</b> Click the camera icon in the address bar, and then choose "Always allow" from the permissions dropdown.
				</Body>
				<Body className={styles.text} size="xs">
						<b>Safari:</b> Go to "Preferences" &gt; "Websites" &gt; "Camera", find this website, and select "Allow".
				</Body>
				<Body className={styles.text} size="xs">
						<b>Edge:</b> Click the lock icon in the address bar, then select "Permissions for this site" and enable the camera.
				</Body>
			</div>
			<Body className={bStyles("text", "reloadText")}>
				After updating your settings, please reload the page.
			</Body>
				<span className={styles.cancel} onClick={handleHide}>X</span>
		</animated.div>
	);
};

export {RequestCamera};
