/* eslint-disable max-len */
import {
	CREATE_ANSWER_VIDEO,
	CREATE_RECORDING_SESSION,
	START_RECORDING,
	STOP_RECORDING_SESSION,
} from "../graphql/mutations/mutations";
import {useLazyQuery, useMutation} from "@apollo/client";
import {useEffect, useRef, useState} from "react";
import {GET_RECORDING} from "../graphql/queries/queries";
import OT from "@opentok/client";
import {CreateRecordingData, CreateRecordingVars, Setter} from "../types";
import retry from "async-retry";
import Bugsnag from "@bugsnag/js";

class RetryError extends Error {}

const MAX_ATTEMPTS = 50; // we aren't giving up easy! Probably need a better solution... but increasing this actually seems to have helped a good amount.

export enum UploadError {
	NONE = "NONE",
	TIMED_OUT = "TIMED_OUT", // Hit max retries
	FAILED = "FAILED", // Upload failed
	UNSTABLE = "UNSTABLE", // Potentially unstable connection (may not need)
	NETWORK = "NETWORK", // If a user was disconnected while performing an upload.
	SUBMIT = "SUBMIT", // Network error for when a user hits "Next"
	ANSWER = "ANSWER", // Problem with createAnswerVideo mutation. Usually timed out.
}

export interface TokState {
	/**
	 * If we are currently recording
	 */
	isRecording: boolean;
	/**
	 * If we are currently trying to get the video upload
	 */
	isProcessing: boolean;
	/**
	 * If we are starting up the recording
	 */
	isStarting: boolean;
}
export interface TokBoxReturn {
	/**
	 * Resets values of the recording session
	 */
	reset: () => void;
	/**
	 * Starts recording
	 */
	startRecording: (questionId: string) => void;
	/**
	 * Stops recording
	 */
	stopRecording: () => Promise<void>;
	/**
	 * Toggles between available cameras
	 */
	toggleCamera: () => Promise<void>;
	/**
	 * Start the thing
	 */
	startVideo: (publishDiv: HTMLDivElement) => Promise<void>;
	getRecording: () => Promise<void>
	setUploadError: Setter<UploadError>;
	retryAnswerVideo: () => void;
	/**
	 * If user has denied camera / audio access
	 */
	accessDenied: boolean;
	/**
	 * Our current session (if exists)
	 */
	session: OT.Session | undefined;
	/**
	 * The preview URL to
	 */
	previewUrl: string | undefined;
	/**
	 * Recording ID. This ID is the same as the uploadItem ID.
	 */
	recordingId: string | undefined;
	/**
	 * Recorder state (look at TokState)
	 */
	recordingState: TokState;
	/**
	 * Count of available video devices
	 */
	videoDevicesCount: number;
	uploadError: UploadError;
}

/**
 * All the hooks and functions required to use TokBox
 * @returns A lot of info about the ToKBox
 */
export const useTokBox = (): TokBoxReturn => {
	const recordingSessionRef = useRef<any>();
	// Internal state
	const [hasStarted, setHasStarted] = useState<boolean>(false);
	const [session, setSession] = useState<OT.Session>();
	const recordingIdRef = useRef<string>();
	const publisherRef = useRef<OT.Publisher>();
	const [isRecording, setIsRecording] = useState<boolean>(false);
	const [isStarting, setIsStarting] = useState<boolean>(false);
	const [uploadError, setUploadError] = useState<UploadError>(UploadError.NONE);
	const [previewUrl, setPreviewUrl] = useState<string>();
	const [isProcessing, setIsProcessing] = useState<boolean>(false);
	const [accessDenied, setAccessDenied] = useState<boolean>(false);
	const [videoDevicesCount, setVideoDevicesCount] = useState<number>(0);
	const [startSession] = useMutation(CREATE_RECORDING_SESSION);
	const [recordStart] =
	useMutation<CreateRecordingData, CreateRecordingVars>(START_RECORDING);
	const [lazyGetRecording] = useLazyQuery(GET_RECORDING, {
		fetchPolicy: "network-only",
		notifyOnNetworkStatusChange: true,
		onError: error => {
			if (error.networkError) setUploadError(UploadError.NETWORK);
			setIsProcessing(false);
		},
	});
	const [stopSession] = useMutation(STOP_RECORDING_SESSION);
	// This is the mutation that takes an UPLOADED video and associates it with an answer
	const [createAnswerVideo] = useMutation(CREATE_ANSWER_VIDEO);


	/**
	 * Stops the current session and sets our ref to undefined.
	 */
	const endSession = (): void => {
		if (!session) throw new Error("No OpenTok session");
		session.disconnect();
		publisherRef.current = undefined;
		setHasStarted(false);
	};

	/**
	 * Opens up the session to TokBox
	 */
	const connectToSession = async(): Promise<void> => {
		const {data: {createRecordingSession: recordingSession}} = await startSession();
		setSession(OT.initSession(recordingSession.apiKey, recordingSession.id));
		// sessionRef.current = OT.initSession(recordingSession.apiKey, recordingSession.id);
		recordingSessionRef.current = recordingSession;
	};

	/**
	 * Creates the video to associate with question.
	 * Separated from earlier to allow a user to retry this on a failure.
	 * (Related to "TypeError: undefined is not an object (evaluating 'o.createAnswerVideo')")
	 */
	const handleCreateAnswerVideo = (): void => {
		setIsProcessing(true);
		createAnswerVideo({
			variables: {recordingId: recordingIdRef.current},
			onCompleted: data => {
				recordingIdRef.current = data.createAnswerVideo.id;
				setPreviewUrl(data.createAnswerVideo.url);
				setIsProcessing(false);
				setUploadError(UploadError.NONE);
			},
			onError: error => {
				setIsProcessing(false);
				if (error.networkError) setUploadError(UploadError.ANSWER);
				else setUploadError(UploadError.FAILED);
			},
		});
	};
	/**
	 * If we have hit our max number of retries, we want to set an error.
	 * Error will be used to let user know they can try again, or try another recording.
	 *
	 * Not foolproof, but better.
	 */
	const onRetry = (e: Error, attempt: number): void => {
		// I think people are hitting refresh when they see this....which is worse than letting
		// it keep trying to succeed...so we just want tell them until we have a different
		// solution :)
		// if (attempt === MAX_ATTEMPTS / 2) setUploadError(UploadError.UNSTABLE);
		if (attempt === MAX_ATTEMPTS) {
			setUploadError(UploadError.TIMED_OUT);
			setIsProcessing(false);
		}
	};
	/**
	 * Gets the recording after we stop recording.
	 *
	 * Notes: couple of... interesting things happen here. Most commonly seems to be the
	 * issue where "createAnswerVideo" is undefined. The root cause tends to be that
	 * the OT session is not found, or that their connection timed out
	 */
	const getRecording = async(): Promise<void> => {
		if (!recordingIdRef.current) return;
		setPreviewUrl(undefined);
		setIsRecording(false);
		setIsProcessing(true);
		const recordedItem = await retry(async(bail): Promise<any> => {
			try {
				const {data: {recording}} = await lazyGetRecording({
					variables: {id: recordingIdRef.current},
				});
				if (recording.status === "UPLOADED") {
					setUploadError(UploadError.NONE);
					return recording;
				}
				if (recording.status === "FAILED") {
					setUploadError(UploadError.FAILED);
					setIsProcessing(false);
					bail(new Error("Failed to upload"));
				}
				throw new RetryError("Recording not yet uploaded");
			} catch (err) {
				if (err instanceof RetryError) throw err;
			}
		}, {
			factor: 2,
			randomize: false,
			retries: MAX_ATTEMPTS,
			maxTimeout: 100000,
			onRetry,
		});
		if (recordedItem && recordedItem.id) {
			// Moved this to a function so we can let user try this again on timeout.
			await handleCreateAnswerVideo();
		}
	};

	/**
	 * A note: I hate that I can't make my own video element. Thanks.
	 * This function starts the actual video streaming.
	 * @param publishDiv The div element we want to attach the video element to
	 */
	const startVideo = async(publishDiv: HTMLDivElement): Promise<void> => {
		/*
			Surrounding with try catch because almost all errors that are thrown from this function
			will be from the OT library and not something we can control. We want to handle those
			errors and potentially alert the user that they have an unstable connection instead of
			classifying these as application errors.
		 */
		try {
			if (!session || !recordingSessionRef.current) {
				await connectToSession();
			}
			if (!hasStarted && session) {
				setHasStarted(true);
				publisherRef.current = OT.initPublisher(
					undefined,
					{insertDefaultUI: false},
					err => {
						if (err) throw err;
					},
				);
				publisherRef.current.on("accessDenied", () => {
					setAccessDenied(true);
				});
				if (!accessDenied) {
					publisherRef.current.on("videoElementCreated", event => {
						publishDiv.append(event.element);
					});
					await new Promise<void>((resolve, reject) => {
						// eslint-disable-next-line no-unused-expressions
						session?.connect(recordingSessionRef.current.token, err => {
							if (err) {
								reject(err);
							} else {
								resolve();
							}
						});
					});
					session.publish(publisherRef.current, err => {
						if (err) throw err;
					});
				}
			}
		} catch (e) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			Bugsnag.notify(<any>e);
		}
	};

	/**
	 * Stops the current video from the session.
	 * Internal function only.
	 */
	const stopVideo = (): void => {
		if (session && publisherRef.current) {
			session.unpublish(publisherRef.current);
			session.disconnect();
			setHasStarted(false);
		}
	};

	/**
	 * Stops the recording
	 */
	const stopRecording = async(): Promise<void> => {
		if (!recordingIdRef.current) return;
		if (previewUrl) setPreviewUrl(undefined);
		await stopSession({
			variables: {id: recordingIdRef.current},
		});
	};

	/**
	 * Starts recording
	 * The main error that seems to throw here is "Session not found"
	 * Checked with Steve on this and we discovered that the session
	 * *is* available and still running in OpenTok, and doesn't report any errors. Might be an issue
	 * on them or maybe how we're integrating with them, but maybe something we can mitigate on
	 * the front end a bit.
	 */
	const startRecording = (questionId: string): void => {
		if (!session) throw new Error("No OpenTok session");
		if (recordingIdRef.current && previewUrl) {
			recordingIdRef.current = undefined;
			setPreviewUrl(undefined);
		}
		if (previewUrl) setPreviewUrl(undefined);
		if (uploadError !== UploadError.NONE) setUploadError(UploadError.NONE);
		setIsStarting(true);
		setIsRecording(true);
		recordStart({
			variables: {sessionId: session.sessionId, questionId},
			onCompleted: ({createRecording}) => {
				recordingIdRef.current = createRecording.id;
				// Set up listener for when we start successfully
				session.once("archiveStopped", () => {
					getRecording();
				});
				setIsStarting(false);
			},
			onError: error => {
				// If we have a network error on this mutation, just want to let the user try again
				if (error.networkError) {
					setIsRecording(false);
					setIsStarting(false);
					setUploadError(UploadError.UNSTABLE);
				} else {
					/**
					 * The only other being thrown is Session not found error.
					 * This may be an issue with OT, but we should try to handle it.
					 *
					 * Attempted restarting session but learned that if the user just... keeps
					 * trying to connect using the sessionId we have, it eventually works.
					 */
					setUploadError(UploadError.UNSTABLE);
					setIsRecording(false);
					setIsStarting(false);
					endSession();
				}
			},
		});
	};

	/**
	 * Resets the states for the recording session
	 * Most important ones are the recordingId and stopping the video
	 */
	const reset = (): void => {
		recordingIdRef.current = undefined;
		setIsRecording(false);
		setPreviewUrl(undefined);
		stopVideo();
	};

	const toggleCamera = async(): Promise<void> => {
		if (!publisherRef.current) return;
		try {
			await publisherRef.current.cycleVideo();
		} catch (e) {
			// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
			Bugsnag.notify(<any>e);
		}
	};

	OT.getDevices((error, devices = []) => {
		if (error) return setVideoDevicesCount(0);

		const numberOfVideoDevices = devices.filter(device => device.kind === "videoInput").length;
		return setVideoDevicesCount(numberOfVideoDevices);
	});

	useEffect(() => {
		connectToSession();
		return () => session && endSession();
	}, []);

	return {
		reset,
		startRecording,
		stopRecording,
		getRecording,
		retryAnswerVideo: handleCreateAnswerVideo,
		session,
		previewUrl,
		startVideo,
		accessDenied,
		toggleCamera,
		videoDevicesCount,
		recordingId: recordingIdRef.current,
		recordingState: {isRecording, isProcessing, isStarting},
		uploadError,
		setUploadError,
	};
};
