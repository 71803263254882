import React, {ReactElement, useState} from "react";
import {Toast, ToastItem} from "../components/toast";

type ToastContextProps = {
	/**
	 * The function that will update the toast
	 */
	updateToast: (newToast: ToastItem) => void;
};

const ToastContext = React.createContext<ToastContextProps>({
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	updateToast: () => {},
});

const ToastContextProvider = (props: {children: ReactElement}): ReactElement => {
	const [toast, setToast] = useState<ToastItem>();

	const updateToast = (newToast: ToastItem): void => {
		setToast(newToast);
	};
	return (
		<ToastContext.Provider
			value={{
				updateToast,
			}}
		>
			<Toast toastItem={toast} position="top"/>
			{props.children}
		</ToastContext.Provider>
	);
};

export {ToastContext, ToastContextProvider};
