import React, {ReactElement, useEffect} from "react";
import {Question, QuestionType} from "../models/question";
import {Setter} from "../types";

interface DisplayContext {
	hideVideo: boolean;
	setHideVideo: Setter<boolean>;
}

const DisplayContext = React.createContext<DisplayContext>({
	hideVideo: false,
	setHideVideo: () => undefined,
});

interface DisplayContextProps {
	videoState: [boolean, Setter<boolean>]
	question?: Question;
	children: React.ReactNode;
}

const DisplayContextProvider = (props: DisplayContextProps): ReactElement => {
	const {question, children, videoState} = props;
	const [hideVideo, setHideVideo] = videoState;

	// Might only be useful for the preview?
	useEffect(() => {
		if (!question) return setHideVideo(true);
		return setHideVideo((question.videoResponse && question.type !== QuestionType.VIDEO) || true);
	}, [question?.id]);
	return (
		<DisplayContext.Provider
			value={{
				hideVideo,
				setHideVideo,
			}}
		>
			{children}
		</DisplayContext.Provider>
	);
};

export {DisplayContextProvider, DisplayContext};
