import React, {ReactElement} from "react";
import styles from "./error-page.module.scss";

export interface ErrorPageProps {
	/**
	 * Type of error, right now it is only media error
	 */
	type?: "media";
}
/**
 * An error page. Right now the only error this is for is that the user
 * did not give access to camera / mic.
 */
const ErrorPage = (props: ErrorPageProps): ReactElement => {
	const {type} = props;
	if (type) {
		return (
			<div className={styles.container}>
				<div
					className={styles.error}
				>In order to respond to surveys on Vurvey, you must allow access to your camera.
					Please change your settings and then try answering the survey again.</div>
			</div>
		);
	}
	return <div>no errors</div>;
};

export {ErrorPage};
