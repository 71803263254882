/* eslint-disable multiline-ternary */
import React, {ReactElement} from "react";
import {QuestionMedia} from "../../models/question";
import {Icon} from "../icon";
import {ImageZoom} from "../image-zoom";
import styles from "./question-media-display.module.scss";

export interface QuestionMediaDisplayProps {
	media: QuestionMedia;
	handleShowModal: () => void;
}

const QuestionMediaDisplay = (props: QuestionMediaDisplayProps): ReactElement | null => {
	const {media: {image, arModel, video}, handleShowModal} = props;
	if (!(arModel || image || video)) return null;
	return (
		<>
			{arModel ? <div className={styles.arModel}>
				<model-viewer
					src={arModel.glbUrl}
					ios-src={arModel.usdzUrl}
					ar
					camera-controls
					alt="A 3D model"
				>
					<p slot="poster">Loading... please wait</p>
				</model-viewer>
				<div className={styles.zoom} onClick={handleShowModal}>
					<Icon name="zoom"/>
				</div>
			</div> : video ? <div className={styles.video} onClick={handleShowModal}>
				<img src={video.small}/>
				<div className={styles.play}>
					<div className={styles.arrow}/>
				</div>
			</div> : image ? <ImageZoom
				image={image.small}
				onClick={handleShowModal}
				className={styles.image}
			/> : null
			}
		</>
	);
};

export {QuestionMediaDisplay};
