import React, {HTMLAttributes, LegacyRef, ReactElement, ReactNode, forwardRef} from "react";
import classNames from "classnames/bind";

import styles from "./button.module.scss";

const bStyles = classNames.bind(styles);

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
	children?: ReactNode;
	icon?: ReactElement;
	disabled?: boolean;
	type?: "button" | "submit" | "reset";
}

export const Button = forwardRef(({
	icon,
	children,
	className,
	disabled,
	type = "button",
	...restProps
}: ButtonProps, ref: LegacyRef<HTMLButtonElement> | undefined) :ReactElement => (
	<button
		ref={ref}
		type={type}
		disabled={disabled}
		{...restProps}
		className={bStyles("button", className)}
	>
		{icon}
		{children}
	</button>
));

Button.displayName = "Button";
