/* eslint-disable max-len */
import React, {ReactElement} from "react";

export interface PlayButtonProps {
	/**
	 * If the current video is playing or not
	 */
	isPlaying: boolean;
	/**
	 * Action to take on pressing button
	 */
	onClick: () => void;
}
/**
 * A play or pause button determined by props passed to it.
 * @param isPlaying Determines whether to show the play button or pause button
 * @param onClick Function to happen when clicking on the button
 */
const PlayButton = (props: PlayButtonProps): ReactElement => {
	const {isPlaying, onClick} = props;

	const getButton = (): JSX.Element => {
		if (isPlaying) {
			return <>
				<rect x="18" y="0" width="10" height="34" fill="white"/>
				<rect x="0" y="0" width="10" height="34" fill="white"/>
			</>;
		}
		return <>
			<path d="M26.2885 15.2537L3.05177 0.886855C1.71935 0.0630453 0 1.02144 0 2.58797V31.4015C0 32.9703 1.72373 33.9283 3.05599 33.1L26.2927 18.6533C27.5533 17.8697 27.551 16.0343 26.2885 15.2537Z" fill="white"/>
		</>;
	};
	return (
		<svg
			onClick={onClick}
			style={{zIndex: 1}}
			width="28"
			height="34"
			viewBox="0 0 28 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			{getButton()}
		</svg>
	);
};

export {PlayButton};
