import {OAuthCredential} from "firebase/auth";

export enum SignUpFlowActionTypes {
	SET_FIREBASE_CREDENTIALS = "SET_FIREBASE_CREDENTIALS",
	SET_EMAIL = "SET_EMAIL",
}

export interface SignUpFlowState {
	firebaseCredentials?: OAuthCredential;
	email?: string;
}

export interface SetFirebaseCredentialsAction {
	type: SignUpFlowActionTypes.SET_FIREBASE_CREDENTIALS;
	payload: OAuthCredential;
}

export interface SetEmailAction {
	type: SignUpFlowActionTypes.SET_EMAIL;
	payload: string;
}

export type SignUpFlowAction =
	| SetFirebaseCredentialsAction
	| SetEmailAction;

export const SignUpFlowReducer = (
	state,
	action: SignUpFlowAction,
): SignUpFlowState => {
	switch (action.type) {
		case SignUpFlowActionTypes.SET_FIREBASE_CREDENTIALS:
			return {
				...state,
				firebaseCredentials: action.payload,
			};
		case SignUpFlowActionTypes.SET_EMAIL:
			return {
				...state,
				email: action.payload,
			};
		default:
			return state;
	}
};
