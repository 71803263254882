/* eslint-disable */
import React, {ReactElement} from "react";
import {Route, Routes} from "react-router-dom";
import {ErrorPage} from "./containers/error-page";
import {Intro} from "./containers/intro";
import LogRocket from "logrocket";
import {QuestionPage} from "./containers/question";
import {ThankYou} from "./containers/thank-you";
import {ToastContextProvider} from "./context/toast-context";
import {AuthenticationWrapper} from "./containers/authentication-wrapper";
import Bugsnag from "@bugsnag/js";
import {Previewer} from "./containers/previewer";
import {Home} from "./containers/home";
import {useAuthentication} from "./hooks/user/useAuthentication";
import {SignUpFlowWrapper, Initial} from "./containers/login";
import {ChooseLoginMethod} from "./containers/login/choose-login-method";
import {Email} from "./containers/login/email";
import {Password} from "./containers/login/password";
import {Create} from "./containers/login/create";
import {VerifyEmail} from "./containers/login/verify-email";
import {RecoverPassword} from "./containers/login/recover-password";
import {Redirect} from "./containers/login/redirect";
import {CheckEmail} from "./containers/login/check-email";
import {v4 as uuidv4} from "uuid";
import "firebase/auth";
import "firebase/compat/auth";
import "../src/styles/application.scss";
import { LoadingPage } from "./containers/loading-page";

const App = (): ReactElement | null => {
	const {user, loading} = useAuthentication();
	if (loading) {
		return <LoadingPage />
	}

	if (user) {
		if (import.meta.env.REACT_APP_ENV) {
			LogRocket.identify(user.uid || uuidv4(), {
				name: user.displayName || user.email || "Anonymous",
				email: user.email || "",
			});
			Bugsnag.setUser(
				(user as any).email,
				(user as any).email,
				(user as any).displayName,
			);
		}

		if (!user.emailVerified && user.email) {
			return (
				<ToastContextProvider>
					<Routes>
						<Route element={<SignUpFlowWrapper />}>
							<Route path="/*" element={<VerifyEmail />} />
						</Route>
					</Routes>
				</ToastContextProvider>
			);
		}

		return (
			<ToastContextProvider>
				<Routes>
					<Route path="/" caseSensitive element={<Home />} />
					<Route path="/:surveyId/preview" element={<Previewer />} />
					<Route path="/:surveyId" caseSensitive element={<Intro />} />
					<Route path="/:surveyId/error" caseSensitive element={<ErrorPage />} />
					<Route path="/:surveyId/questions" element={<QuestionPage />} />
					<Route path="/:surveyId/thank-you" element={<ThankYou />} />
					<Route path="/login/*" element={<Redirect />} />
				</Routes>
			</ToastContextProvider>
		);
	}

	return (
		<ToastContextProvider>
			<Routes>
				<Route
					path="/"
					element={
						<AuthenticationWrapper>
							<Home />
						</AuthenticationWrapper>
					}
				/>
				<Route path="/:surveyId" element={<Intro />} />
				<Route path="/:surveyId/preview" element={<Previewer />} />
				<Route path="/login" element={<SignUpFlowWrapper />}>
					<Route index element={<Initial />} />
					<Route path="choose-method" element={<ChooseLoginMethod />} />
					<Route path="email" element={<Email />} />
					<Route path="password" element={<Password />} />
					<Route path="create" element={<Create />} />
					<Route path="recover-password" element={<RecoverPassword />} />
					<Route path="check-email" element={<CheckEmail />} />
				</Route>
			</Routes>
		</ToastContextProvider>
	);
};

export {App};
