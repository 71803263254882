// import Editor, {createEditorStateWithText} from "@draft-js-plugins/editor";
import {Descendant, createEditor} from "slate";
import {Editable, RenderElementProps, RenderLeafProps, Slate, withReact} from "slate-react";
import React, {ReactElement, useCallback, useMemo, useRef, useState} from "react";
import {isParseable} from "../../utility/helpers";
import styles from "./rich-text-input.module.scss";

/**
 * Leaf is a node of text that is rendered by our RTE above.
 * Allows us to style a section of Text based on props received
 * @param props RenderLeafProps
 */
const Leaf = (props: RenderLeafProps): JSX.Element => {
	return (
		<span
			{...props.attributes}
			style={{
				fontWeight: props.leaf.bold ? "bold" : "normal",
				fontStyle: props.leaf.italic ? "italic" : "normal",
				textDecoration: props.leaf.under ? "underline" : "none",
				textAlign: "center",
			}}>
			{props.children}
		</span>
	);
};

/**
 * An element. General wrapper, always has at least one
 * @param props RenderElementProps
 */
const Element = (props: RenderElementProps): JSX.Element => {
	const {attributes, element, children} = props;
	// console.log("hi", attributes);
	switch (element.type) {
		case "heading-one":
			return <h1
				className={styles.h1}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</h1>;
		case "heading-two":
			return <h1
				className={styles.h2}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</h1>;
		case "heading-three":
			return <h1
				className={styles.h3}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</h1>;
		case "numbered-list":
			return <ol
				className={styles.ol}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</ol>;
		case "list-item":
			return <li {...attributes} style={{textAlign: props.element.textAlign}}>{children}</li>;
		case "unordered-list":
			return <ul
				className={styles.ul}
				style={{textAlign: props.element.textAlign}}
				{...attributes}
			>
				{children}
			</ul>;
		case "right-align":
			return <div style={{textAlign: "right"}} {...attributes}>{children}</div>;
		case "center-align":
			return <div style={{textAlign: "center"}} {...attributes}>{children}</div>;
		default:
			// eslint-disable-next-line max-len
			return <p
				className={styles.p}
				style={{textAlign: props.element.textAlign}}
				{...attributes}>
				{children}
			</p>;
	}
};

export interface RichTextInputProps {
	/**
	 * Initial value.
	 */
	text: string;
	/**
	 * Placeholder if needed
	 */
	placeholder?: string;
	/**
	 * Flex amount, if needed.
	 */
	flex?: number;
}


const RichTextInput = (props: RichTextInputProps): ReactElement => {
	const {flex, text, placeholder} = props;
	const editor = useMemo(() => withReact(createEditor()), []);
	const [value, setValue] = useState<Descendant[]>(
		isParseable(text)
			? JSON.parse(text)
			: [
				{
					type: "paragraph",
					children: [{text: text || placeholder || "Enter content..."}],
					textAlign: "left",
				},
			],
	);
	const ref = useRef<HTMLDivElement>(null);

	const renderElement = useCallback(elementProps => <Element {...elementProps}/>, []);
	const renderLeaf = useCallback(leafProps => <Leaf {...leafProps}/>, []);
	return (
		<div
			ref={ref}
			className={styles.container}
			style={{flex: `${flex}`}}
		>
			<Slate
				editor={editor}
				initialValue={value}
				onChange={newValue => setValue(newValue)}
			>
				<Editable
					className={styles.editor}
					renderElement={renderElement}
					renderLeaf={renderLeaf}
					readOnly
				/>
			</Slate>
		</div>
	);
};


export {RichTextInput};
