import React, {ReactElement} from "react";
import styles from "./end-layout.module.scss";

export interface EndLayoutProps {
	/**
	 * Children to render
	 */
	children: React.ReactNode;
}

const EndLayout = (props: EndLayoutProps): ReactElement => {
	return (
		<div className={styles.container}>
			{props.children}
		</div>
	);
};

export {EndLayout};
