import React, {ReactElement} from "react";
import styles from "./generic-error-page.module.scss";
import {Layout} from "../../hoc";
import {StatusBlocked} from "../status-blocked";
import {SignedIn} from "../signed-in";

export const GenericErrorPage = (): ReactElement => {
	return (
		<>
			<Layout>
				<StatusBlocked blockedReason="GENERIC" />
			</Layout>
			<div className={styles.bottom}>
				<SignedIn />
			</div>
		</>
	);
};
