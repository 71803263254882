/**
 * Returns true or false if we can parse given item
 * @param item The item we are checking if it is parseable
 */

import React from "react";
import {XYCoord} from "react-dnd";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isParseable = (item: any): boolean => {
	try {
		const value = JSON.parse(item);
		if (value && typeof value === "object") return true;
	} catch (e) {
		return false;
	}
	return false;
};

export const checkDraggingReplace = (
	containerRef: React.RefObject<HTMLDivElement>,
	dragIndex: number,
	targetIndex: number,
	clientOffset: XYCoord | null,
): boolean => {
	if (!containerRef.current) return false;
	const hoverBoundingRect = containerRef.current?.getBoundingClientRect();
	const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
	const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

	if (dragIndex < targetIndex && hoverClientY < hoverMiddleY) return false;
	if (dragIndex > targetIndex && hoverClientY > hoverMiddleY) return false;
	return true;
};
