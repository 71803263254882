import React, {ReactElement} from "react";

import {
	StepTemplate,
	GoogleSignInButton,
	MicrosoftSignInButton,
	EmailSignInButton,
	AppleSignInButton
} from "../../../components/sign-up-flow";

import styles from "./choose-login-method.module.scss";

export const ChooseLoginMethod = (): ReactElement => {
	return (
		<StepTemplate
			header="Log in"
			imageSrc="/images/login-photo.jpg"
			imageText={
				<>
          “It&apos;s like <br />
          private TikTok <br />
          directly with <br />
          brands”
				</>
			}
		>
			<div className={styles.signInButtons}>
				<GoogleSignInButton id="google-login" text="Log in with Google" />
				<MicrosoftSignInButton id="microsoft-login" text="Log in with Microsoft" />
				<AppleSignInButton id="apple-login" text="Log in with Apple"/>
				<EmailSignInButton id="email-login" text="Log in with email" searchParam="?isLogin=true" />
			</div>
		</StepTemplate>
	);
};
