import React, {ReactElement} from "react";
import {animated, useSpring} from "react-spring";
import styles from "./progress-bar.module.scss";

export interface ProgressBarProps {
	/**
	 * Percent to fill up
	 */
	percent: number;
	/**
	 * Color to fill
	 */
	color?: string;
}

const ProgressBar = (props: ProgressBarProps): ReactElement => {
	const {percent, color} = props;
	const animatedProps = useSpring({
		width: `${percent}%`,
		from: {width: "0%"},
	});
	return (
		<div className={styles.bar}>
			<animated.div
				style={{...animatedProps, color}}
				className={styles.fill}
			/>
		</div>
	);
};

export {ProgressBar};
