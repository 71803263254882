import classNames, {Argument} from "classnames";
import React, {ReactElement} from "react";
import {Icon} from "../icon";
import styles from "./image-zoom.module.scss";

export interface ImageZoomProps {
	/**
	 * The image to display
	 */
	image: string;
	/**
	 * Function to call when clicked
	 */
	onClick: () => void;
	className?: Argument;
}

/**
 * Simple image display with a black overlay that shows magnifying glass
 * @param image Image we want to display
 * @param onClick What we want to do when user clicks on image
 * @param cssStyles Optional CSSProperties to add to container
 *
 */
const ImageZoom = (props: ImageZoomProps): ReactElement => {
	const {image, onClick, className} = props;
	return (
		<div
			className={classNames(className, styles.container)}
			onClick={onClick}
		>
			<img
				className={styles.image}
				src={image}
			/>
			<Icon
				className={styles.zoom}
				name="zoom"
				size="small"
			/>
		</div>
	);
};

export {ImageZoom};
