import React, {ReactElement} from "react";
import styles from "../intro/intro.module.scss";
import {EndLayout} from "../../hoc/end-layout";
// import FirebaseAuth from "../../firebase";

const Home = (): ReactElement | null => {
	return (
		<EndLayout>
			<div className={styles.container}>
				<div className={styles.completed}>
					<svg
						width="52"
						height="38"
						viewBox="0 0 52 38"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path d="M2 20L17 35L50 2" stroke="white" strokeWidth="4" />
					</svg>
				</div>
				<div style={{margin: 15}}>
					<h1>Please re-enter your Survey Link into the url and click Enter.</h1>
					<h1>Or...</h1>
					<h1>If you came here from scanning a QR code, please rescan the QR code.</h1>
				</div>
			</div>
		</EndLayout>
	);
};

export {Home};
