/* eslint-disable */
import {useMutation} from "@apollo/client";
import React, {ReactElement, useEffect, useState} from "react";
import {GET_USER_FROM_ACCESS_TOKEN} from "../../graphql/mutations/mutations";
import { useAuthentication } from "../../hooks/user/useAuthentication";


export const AuthenticationWrapper = ({children}: {children: ReactElement}): ReactElement => {
	const [loggingIn, setLoggingIn] = useState(false);
	const {user, logout} = useAuthentication();
	const [getUserFromAccessToken] = useMutation(GET_USER_FROM_ACCESS_TOKEN, {
		onError: err => {
			throw err;
		},
	});
	useEffect(() => {
		if (!loggingIn) return;
		try {
			if (!user) return;
			getUserFromAccessToken({
				variables: {
					accessToken: (user as any)?.stsTokenManager?.accessToken,
					name: (user as any)?.displayName,
				},
				onCompleted: data => {
					console.log(data);
				},
				onError: error => {
					console.log(error);
					if (error.message.includes("token")) {
						localStorage.removeItem("token");
						logout();
					}
				},
			});
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(e);
		}
	}, [loggingIn]);


	if (user && user?.email && !loggingIn) setLoggingIn(true);


	return <>{children}</>
};

