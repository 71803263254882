/* eslint-disable no-sequences */
/* eslint-disable no-return-assign */
import {ApolloClient, ApolloProvider, from} from "@apollo/client";
import React, {ReactElement} from "react";
import {createRoot} from "react-dom/client";
import {App} from "./app";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import {HashRouter} from "react-router-dom";
import LogRocket from "logrocket";
import {cache} from "./cache";
import {config} from "./config";
import {createUploadLink} from "apollo-upload-client";
import {resolvers} from "./graphql/resolvers/resolvers";
import {setContext} from "@apollo/client/link/context";
import {TouchBackend} from "react-dnd-touch-backend";
import {DndProvider} from "react-dnd";
import {onError} from "@apollo/client/link/error";
import setupLogRocketReact from "logrocket-react";
import {IntercomProvider} from "react-use-intercom";
import "firebase/firestore";
import { auth } from "./utils/firebase";
import {v4 as uuid} from "uuid";

/**
 * Setup component
 *
 * @returns our app with the ApolloClient and BrowserRouter wrapper components
 */
const Setup = (): ReactElement => {
	let ErrorBoundary;
	const env = import.meta.env.REACT_APP_STAGE;

	if (env === "development") {
		ErrorBoundary = React.Fragment;
	} else {
		// setup logging services
		if (config.logRocketProjectName) LogRocket.init(config.logRocketProjectName);
		setupLogRocketReact(LogRocket);
		const bugsnag = Bugsnag.start({
			apiKey: config.bugsnagApiKey,
			plugins: [new BugsnagPluginReact()],
			releaseStage: config.bugsnagReleaseStage,
			appVersion: import.meta.env.REACT_APP_VERSION,
		});
		bugsnag.addMetadata("LogRocket", {
			sessionURL: LogRocket.sessionURL,
		});

		ErrorBoundary =
			Bugsnag?.getPlugin("react")?.createErrorBoundary(React) ?? React.Fragment;
	}
	const uploadLink = createUploadLink({
		uri: `${config.apiHost}/graphql`,
		credentials: "include",
	});
	const reportError = (error): void =>
		(config.bugsnagReleaseStage === "development"
			? // eslint-disable-next-line no-console
			console.error(error)
			: Bugsnag.notify(error));
	const bearerLink: any = setContext(async(_, {headers}) => {
		const user = auth?.currentUser;
		let token: string | null = null;

		if (user) {
			token = await user.getIdToken();
			localStorage.setItem("token", token);
		}
		const storageToken = localStorage.getItem("token");
		const idToken = token || storageToken;
		return {
			headers: {
				...headers,
				authorization: token ? `Bearer ${idToken}` : "",
				"X-Request-ID": uuid(),
			},
		};
	});

	const concattedLink = bearerLink.concat(uploadLink);
	const errorLink = onError(({graphQLErrors, networkError}) => {
		if (graphQLErrors) {
			graphQLErrors.forEach(({message, locations, path}) => {
				reportError(
					new Error(`[GraphQL error]: Message: ${message},
					 Location: ${JSON.stringify(locations)},
					 Path: ${path}`),
				);
			});
		}
		if (networkError) reportError(new Error(`[Network error]: ${networkError}`));
	});
	const client = new ApolloClient({
		link: from([errorLink, concattedLink]),
		cache,
		resolvers,
		connectToDevTools: true,
	});

	if (client === undefined) {
		return <div>Loading...</div>;
	}

	return (
		<ErrorBoundary>
			<ApolloProvider client={client}>
				<HashRouter>
					<DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
						<IntercomProvider appId={config.intercomAppId}>
							<App />
						</IntercomProvider>
					</DndProvider>
				</HashRouter>
			</ApolloProvider>
		</ErrorBoundary>
	);
};
const container = document.getElementById("root");
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
root.render(<Setup />);
