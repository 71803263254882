import { useNavigate } from "react-router-dom";
import React, {ReactElement} from "react";

import { Button, StepTemplate } from "../../../components/sign-up-flow";
import { useSignUpFlow } from "../../../contexts/sign-up-flow-context";

import styles from "./check-email.module.scss";

export const CheckEmail = (): ReactElement => {
	const navigate = useNavigate();
	const {state: {email}} = useSignUpFlow();

	const handleClick = (): void => {
		navigate('/login');
	};

	return (
		<StepTemplate
			imageSrc="/images/sign-up-photo.jpg"
			header="Check Your Email"
			description={`Follow the instructions sent to ${email} to recover your password`}
			revertRoute="/login/recover-password"
			imageText={
				<>
          “Vurvey is the future <br />
          of brand-consumer <br />
          relationships. <br />
          Be part of it.”
				</>
			}
		>

			<div className={styles.buttonsSection}>
				<Button type="submit" onClick={handleClick}>Done</Button>
			</div>
		</StepTemplate>
	);
};
