import React, {FormEvent, ReactElement, useContext, useMemo, useState} from "react";
import {fetchSignInMethodsForEmail} from "firebase/auth";
import {FirebaseError} from "@firebase/util";

import { auth } from "../../../utils/firebase";
import { Button, Input, ProgressBar, StepTemplate } from "../../../components/sign-up-flow";
import { SignUpFlowActionTypes } from "../../../reducers/sign-up-flow-reducer";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSignUpFlow } from "../../../contexts/sign-up-flow-context";
import { ToastContext } from "../../../context/toast-context";

import styles from "./email.module.scss";

export const Email = (): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const {dispatch} = useSignUpFlow();
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");

	const isSignIn = useMemo(() => {
		return searchParams.get("isLogin") === "true";
	}, [history.length]);

	const changeEmail = (payload: string): void => {
		dispatch({type: SignUpFlowActionTypes.SET_EMAIL, payload});
	};

	const isEmailInDatabase = async(): Promise<void> => {
		try {
			const options = await fetchSignInMethodsForEmail(auth, email);

			if (options.includes("password")) {
				navigate("/login/password");
			} else if (options.length > 0) {
				if (options[0] === "google.com") {
					updateToast({type: "informational", description: "You already have an account. Please log in with Google."});
				}

				if (options[0] === "microsoft.com") {
					updateToast({type: "informational", description: "You already have an account. Please log in with Microsoft."});
				}

				navigate("/login");
			} else {
				navigate("/login/create")
			}
		} catch (error) {
			if (!(error instanceof FirebaseError)) {
				throw error;
			}
			const {code} = error;
			if (code === "auth/invalid-email") {
				setEmailError("Please enter a valid email address.");
				return;
			}
			setEmailError(error.message);
		}
	};

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		changeEmail(email);
		isEmailInDatabase();
	};

	const handleChangeEmail = (e: FormEvent<HTMLInputElement>): void => {
		setEmail(e.currentTarget.value);
		setEmailError("");
	};

	return (
		<StepTemplate
			imageSrc="/images/sign-in-photo.jpg"
			revertRoute="/login"
			header={isSignIn ? "Log in" : undefined}
			imageText={
				<>
          “Vurvey is my <br />
          bridge to brands. <br />
          Direct, honest, <br />
          rewarding.”
				</>
			}
		>
			<form className={styles.form} onSubmit={handleSubmit}>
				<Input
					placeholder="Email"
					value={email}
					onChange={handleChangeEmail}
					errorMessage={emailError}
				/>
				<div className={styles.buttonsSection}>
					<Button
						id={isSignIn ? "email-signin-next" : "signup-email-next"}
						disabled={Boolean(emailError)}
						type="submit"
					>
            Next
					</Button>
				</div>
				{!isSignIn && (
					<ProgressBar
						showNumber
						initialValue={0}
						value={33}
					/>
				)}
			</form>
		</StepTemplate>
	);
};
