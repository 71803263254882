import React, {ReactElement} from "react";
import styles from "./css-spinner.module.scss";

export interface CssSpinnerProps {
	/**
	 * Size of the spinner. Currently small and medium (large would be easy to add)
	 */
	size: "small" | "medium";
}
/**
 * A simple CSS spinner for when we are uploading a video
 */

const CssSpinner = (props: CssSpinnerProps): ReactElement => {
	const {size} = props;
	return <div className={styles[size]}/>;
};

export {CssSpinner};
