import React, {ReactElement} from "react";
import { useNavigate } from "react-router-dom";

import { MailIcon } from "../../../icons";
import { sendAnalyticsEvent } from "../../../utils/analytics";
import { SIGNUP_METHODS } from "../../../utils/firebase";

import styles from "./buttons.module.scss";

export interface EmailSignInButtonProps {
	text?: string;
	searchParam?: string;
	id?: string;
}

export const EmailSignInButton = (
	{text = "Sign up with email", searchParam = "", id}: EmailSignInButtonProps,
): ReactElement => {
	const navigate = useNavigate();

	const navigateToLoginPage = (): void => {
		if (text === "Sign up with email") {
			sendAnalyticsEvent("sign_up", SIGNUP_METHODS.EMAIL);
		}

		navigate({
			pathname: "/login/email",
			search: searchParam,
		})
	}

	return (
		<button id={id} className={styles.authButton} onClick={navigateToLoginPage}>
			<MailIcon className={styles.authButtonIcon} />
			<span className={styles.authButtonText}>{text}</span>
		</button>
	);
};
