import {useSignInWithGoogle} from "react-firebase-hooks/auth";
import React, {ReactElement} from "react";
import {useNavigate} from "react-router-dom";
import { getAdditionalUserInfo } from "firebase/auth";

import {SIGNUP_METHODS, auth} from "../../../utils/firebase";
import { sendAnalyticsEvent, setAnalyticsUser } from "../../../utils/analytics";

import styles from "./buttons.module.scss";

export interface GoogleSignInButtonProps {
	text?: string;
	id?: string;
}

export const GoogleSignInButton = ({
	text = "Sign up with Google",
	id,
}: GoogleSignInButtonProps): ReactElement => {
	const [signInWithGoogle] = useSignInWithGoogle(auth);
	const navigate = useNavigate();
	const surveyId = localStorage.getItem("surveyId");

	const handleSignIn = (): void => {
		if (text === "Sign up with Google") {
			sendAnalyticsEvent("sign_up", SIGNUP_METHODS.GMAIL);
		}
		signInWithGoogle().then(result => {
			if (result) {
				const {isNewUser} = getAdditionalUserInfo(result) ?? {};
				setAnalyticsUser(result.user.uid);

				if (isNewUser) {
					sendAnalyticsEvent("create_account", SIGNUP_METHODS.GMAIL);
					sendAnalyticsEvent("complete_registration", SIGNUP_METHODS.GMAIL);
					sendAnalyticsEvent("login", SIGNUP_METHODS.GMAIL);

					navigate(`/${surveyId}`);
					return;
				}

				sendAnalyticsEvent("login", SIGNUP_METHODS.GMAIL);
				navigate(`/${surveyId}`);
			}
		});
	};

	return (
		<button id={id} onClick={handleSignIn} className={styles.authButton}>
			<img src="/images/google-icon.svg" alt="Google Icon" />
			<span className={styles.authButtonText}>{text}</span>
		</button>
	);
};
