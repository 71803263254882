/*	eslint-disable max-len */
import React, {CSSProperties, ReactElement} from "react";
import classnames from "classnames";
import styles from "./icon.module.scss";

interface IconData {
	width?: number;
	height?: number;
	x?: number;
	y?: number;
	data: (any) => ReactElement;
}
export type IconName = "big-grey-checkmark" | "calendar"| "checkmark" | "green-checkmark" | "garbage" | "grey-checkmark" | "cross" | "arrow"
| "envelope" | "eye" | "zoom" | "handle" | "help" | "lock" | "star" | "error" | "upload" | "vurvey-full-logo" | "vurvey-logo";

const iconData: {[key in IconName]: IconData} = {
	arrow: {
		width: 18,
		height: 18,
		data: ({fill}) => (
			<path d="M11.91 15.09L5.83 9L11.91 2.91L10.5 1.5L3 9L10.5 16.5L11.91 15.09Z" fill={fill || "white"}/>
		),
	},
	"big-grey-checkmark": {
		width: 70,
		height: 70,
		data: ({fill}) => (
			<path fillRule="evenodd" clipRule="evenodd" d="M63.124 13.517C62.0196 12.3277 60.2126 12.3277 59.1082 13.517L25.1339 50.1047L10.8918 34.767C9.78743 33.5777 7.98042 33.5777 6.87603 34.767C5.79132 35.9352 5.79132 37.8148 6.87603 38.983L23.126 56.483C24.2304 57.6723 26.0374 57.6723 27.1418 56.483L63.124 17.733C64.2087 16.5648 64.2087 14.6852 63.124 13.517Z" fill={fill || "#888888"}/>
		),
	},
	calendar: {
		width: 32,
		height: 32,
		data: ({fill}) => (
			<path d="M11.8214 3.625H20.1786V1.35938C20.1786 0.608887 20.7996 0 21.5714 0C22.3433 0 22.9643 0.608887 22.9643 1.35938V3.625H25.2857C27.3344 3.625 29 5.24775 29 7.25V25.375C29 27.3744 27.3344 29 25.2857 29H6.71429C4.66272 29 3 27.3744 3 25.375V7.25C3 5.24775 4.66272 3.625 6.71429 3.625H9.03571V1.35938C9.03571 0.608887 9.6567 0 10.4286 0C11.2004 0 11.8214 0.608887 11.8214 1.35938V3.625ZM5.78571 25.375C5.78571 25.8734 6.20125 26.2812 6.71429 26.2812H25.2857C25.7964 26.2812 26.2143 25.8734 26.2143 25.375V10.875H5.78571V25.375Z" fill={fill || "black"}/>
		),
	},
	checkmark: {
		width: 16,
		height: 16,
		data: ({fill}) => (
			<path d="M4 8.5L6.5 11L12 5.5" stroke={fill || "#CCCCCC"} strokeLinecap="round"/>
		),
	},
	cross: {
		width: 18,
		height: 18,
		data: ({fill}) => (
			<path fillRule="evenodd" clipRule="evenodd" d="M15.6565 3.15681L14.9494 2.44971L8.99963 8.39945L3.04988 2.44971L2.34277 3.15681L8.29252 9.10656L2.34277 15.0563L3.04988 15.7634L8.99963 9.81367L14.9494 15.7634L15.6565 15.0563L9.70673 9.10656L15.6565 3.15681Z" fill={fill || "white"}/>
		),
	},
	error: {
		width: 70,
		height: 61,
		data: () => (
			<>
				<path d="M34.5096 14.8701C33.8202 14.8927 33.1654 15.1774 32.6786 15.6661C32.1918 16.1547 31.9096 16.8106 31.8896 17.5001V36.6301C31.8923 37.3242 32.1692 37.9891 32.6599 38.4798C33.1507 38.9706 33.8156 39.2475 34.5096 39.2501C34.8483 39.249 35.1833 39.1797 35.4946 39.0463C35.8059 38.9128 36.087 38.718 36.3213 38.4734C36.5556 38.2289 36.7382 37.9396 36.8582 37.6229C36.9782 37.3062 37.0331 36.9685 37.0196 36.6301V17.5001C37.0253 16.8214 36.7664 16.1672 36.2979 15.6762C35.8293 15.1852 35.1879 14.8961 34.5096 14.8701Z" fill="#888888"/>
				<path d="M30.9399 47.2499C30.9343 48.2096 31.3049 49.1334 31.9722 49.8231C32.6395 50.5129 33.5505 50.9138 34.5099 50.9399C34.9882 50.9374 35.4611 50.8394 35.901 50.6516C36.3409 50.4638 36.7388 50.1899 37.0714 49.8462C37.404 49.5024 37.6645 49.0957 37.8377 48.6498C38.0109 48.204 38.0932 47.728 38.0799 47.2499C38.1046 46.769 38.0302 46.2881 37.8611 45.8371C37.6921 45.3862 37.432 44.9749 37.0971 44.6287C36.7623 44.2826 36.3598 44.0091 35.9147 43.8252C35.4696 43.6414 34.9914 43.5511 34.5099 43.5599C34.0316 43.5624 33.5586 43.6604 33.1187 43.8483C32.6788 44.0361 32.2809 44.3099 31.9484 44.6537C31.6158 44.9974 31.3553 45.4042 31.1821 45.85C31.0089 46.2958 30.9265 46.7718 30.9399 47.2499Z" fill="#888888"/>
				<path d="M67.9197 48.7601L41.3797 3.88009C40.6702 2.69089 39.6641 1.70624 38.4598 1.02248C37.2556 0.338714 35.8945 -0.020752 34.5097 -0.020752C33.1249 -0.020752 31.7638 0.338714 30.5596 1.02248C29.3554 1.70624 28.3493 2.69089 27.6397 3.88009L1.08972 48.7701C0.388301 49.9369 0.0127351 51.2705 0.00218183 52.6319C-0.00837144 53.9933 0.346475 55.3325 1.02972 56.5101C1.72331 57.7341 2.73157 58.7504 3.95004 59.4537C5.16852 60.157 6.55289 60.5218 7.95972 60.5101H61.0697C62.4751 60.521 63.8578 60.1559 65.0745 59.4526C66.2913 58.7493 67.2978 57.7333 67.9897 56.5101C68.6713 55.3298 69.0243 53.9885 69.012 52.6255C68.9997 51.2626 68.6225 49.9279 67.9197 48.7601ZM63.3497 53.7601V53.8101V53.8801C63.1469 54.3337 62.805 54.7109 62.3736 54.9572C61.9421 55.2035 61.4434 55.3061 60.9497 55.2501H7.94972C7.47209 55.2602 7.00061 55.141 6.58517 54.9051C6.16973 54.6692 5.82578 54.3254 5.58972 53.9101C5.36373 53.5378 5.24422 53.1106 5.24422 52.6751C5.24422 52.2396 5.36373 51.8124 5.58972 51.4401L32.1697 6.55009C32.4179 6.15334 32.7628 5.8262 33.1722 5.59942C33.5815 5.37263 34.0418 5.25365 34.5097 5.25365C34.9777 5.25365 35.438 5.37263 35.8473 5.59942C36.2566 5.8262 36.6016 6.15334 36.8497 6.55009L63.3897 51.4201C63.6036 51.769 63.7135 52.1717 63.7064 52.5809C63.6993 52.9901 63.5756 53.3888 63.3497 53.7301V53.7601Z" fill="#888888"/>
			</>
		),
	},
	garbage: {
		width: 20,
		height: 20,
		data: ({fill}) => (
			<path d="M17.2656 3.98438H14.4499L13.2878 2.04468C12.9624 1.5517 12.46 1.25 11.8857 1.25H8.42676C7.85254 1.25 7.31934 1.5517 7.02539 2.04468L5.8626 3.98438H3.04688C2.74353 3.98438 2.5 4.22808 2.5 4.53125V5.07812C2.5 5.38232 2.74353 5.625 3.04688 5.625H3.59375V16.5625C3.59375 17.7708 4.573 18.75 5.78125 18.75H14.5312C15.7395 18.75 16.7188 17.7708 16.7188 16.5625V5.625H17.2656C17.5698 5.625 17.8125 5.38232 17.8125 5.07812V4.53125C17.8125 4.22808 17.5698 3.98438 17.2656 3.98438ZM8.37549 2.98906C8.40967 2.92925 8.47803 2.89062 8.5498 2.89062H11.7627C11.8353 2.89062 11.9037 2.92908 11.9379 2.98889L12.5352 3.98438H7.77734L8.37549 2.98906ZM14.5312 17.1094H5.78125C5.4792 17.1094 5.23438 16.8645 5.23438 16.5625V5.625H15.0781V16.5625C15.0781 16.8633 14.832 17.1094 14.5312 17.1094ZM10.1562 15.4688C10.4585 15.4688 10.7031 15.2242 10.7031 14.9219V7.8125C10.7031 7.51021 10.4585 7.26562 10.1562 7.26562C9.85396 7.26562 9.60938 7.51172 9.60938 7.8125V14.9219C9.60938 15.2227 9.85547 15.4688 10.1562 15.4688ZM7.42188 15.4688C7.72266 15.4688 7.96875 15.2227 7.96875 14.9219V7.8125C7.96875 7.51021 7.72416 7.26562 7.42188 7.26562C7.11959 7.26562 6.875 7.51172 6.875 7.8125V14.9219C6.875 15.2227 7.12109 15.4688 7.42188 15.4688ZM12.8906 15.4688C13.1929 15.4688 13.4375 15.2242 13.4375 14.9219V7.8125C13.4375 7.51021 13.1929 7.26562 12.8906 7.26562C12.5883 7.26562 12.3438 7.51172 12.3438 7.8125V14.9219C12.3438 15.2227 12.5898 15.4688 12.8906 15.4688Z" fill={fill || "#BBBBBB"}/>
		),
	},
	"green-checkmark": {
		width: 70,
		height: 70,
		data: () => (
			<>
				<circle cx="8" cy="8" r="7.5" stroke={"#CCCCCC"}/>
				<path d="M4 8.5L6.5 11L12 5.5" stroke="#CCCCCC" strokeLinecap="round"/>
			</>
		),
	},
	"grey-checkmark": {
		width: 16,
		height: 16,
		data: () => (
			<>
				<circle cx="8" cy="8" r="7.5" stroke={"#CCCCCC"}/>
				<path d="M4 8.5L6.5 11L12 5.5" stroke="#CCCCCC" strokeLinecap="round"/>
			</>
		),
	},
	envelope: {
		width: 70,
		height: 70,
		data: () => (
			<>
				<path fillRule="evenodd" clipRule="evenodd" d="M59.0625 17.5H10.9375L10.9375 52.5H59.0625V17.5ZM10.9375 13.125C8.52125 13.125 6.5625 15.0838 6.5625 17.5V52.5C6.5625 54.9162 8.52125 56.875 10.9375 56.875H59.0625C61.4787 56.875 63.4375 54.9162 63.4375 52.5V17.5C63.4375 15.0838 61.4787 13.125 59.0625 13.125H10.9375Z" fill="#888888"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M33.5215 40.9875L7.27148 16.925L10.2278 13.7L34.9996 36.4075L59.7715 13.7L62.7278 16.925L36.4778 40.9875C35.6415 41.7541 34.3578 41.7541 33.5215 40.9875Z" fill="#888888"/>
			</>
		),
	},
	eye: {
		width: 70,
		height: 70,
		data: () => (
			<>
				<path fillRule="evenodd" clipRule="evenodd" d="M0 35C0 35 15.67 59.0625 35 59.0625C54.33 59.0625 70 35 70 35C70 35 54.33 10.9375 35 10.9375C15.67 10.9375 0 35 0 35ZM6.07625 35C6.12346 35.0605 6.17133 35.1217 6.21986 35.1835C7.8797 37.2977 10.2789 40.1122 13.2294 42.9144C19.2626 48.6446 26.9596 53.7153 35 53.7153C43.0404 53.7153 50.7374 48.6446 56.7706 42.9144C59.7211 40.1122 62.1203 37.2977 63.7801 35.1835C63.8287 35.1217 63.8765 35.0605 63.9237 35C63.8765 34.9395 63.8287 34.8783 63.7801 34.8165C62.1203 32.7023 59.7211 29.8878 56.7706 27.0856C50.7374 21.3554 43.0404 16.2847 35 16.2847C26.9596 16.2847 19.2626 21.3554 13.2294 27.0856C10.2789 29.8878 7.8797 32.7023 6.21986 34.8165C6.17133 34.8783 6.12346 34.9395 6.07625 35Z" fill="#888888"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M35 41.5625C38.6244 41.5625 41.5625 38.6244 41.5625 35C41.5625 31.3756 38.6244 28.4375 35 28.4375C31.3756 28.4375 28.4375 31.3756 28.4375 35C28.4375 38.6244 31.3756 41.5625 35 41.5625ZM35 45.9375C41.0406 45.9375 45.9375 41.0406 45.9375 35C45.9375 28.9594 41.0406 24.0625 35 24.0625C28.9594 24.0625 24.0625 28.9594 24.0625 35C24.0625 41.0406 28.9594 45.9375 35 45.9375Z" fill="#888888"/>
				<rect x="58.2021" y="8.70447" width="4.375" height="70" rx="2.1875" transform="rotate(45 58.2021 8.70447)" fill="#888888"/>
			</>
		),
	},
	handle: {
		width: 6,
		height: 26,
		data: () => (
			<g opacity="0.5">
				<circle cx="1" cy="1" r="1" fill="white"/>
				<circle cx="1" cy="5" r="1" fill="white"/>
				<circle cx="1" cy="9" r="1" fill="white"/>
				<circle cx="1" cy="13" r="1" fill="white"/>
				<circle cx="1" cy="17" r="1" fill="white"/>
				<circle cx="1" cy="21" r="1" fill="white"/>
				<circle cx="1" cy="25" r="1" fill="white"/>
				<circle cx="5" cy="1" r="1" fill="white"/>
				<circle cx="5" cy="5" r="1" fill="white"/>
				<circle cx="5" cy="9" r="1" fill="white"/>
				<circle cx="5" cy="13" r="1" fill="white"/>
				<circle cx="5" cy="17" r="1" fill="white"/>
				<circle cx="5" cy="21" r="1" fill="white"/>
				<circle cx="5" cy="25" r="1" fill="white"/>
			</g>
		),
	},
	help: {
		width: 24,
		height: 24,
		data: ({fill}) => (
			<>
				<path d="M15.5 9.45232C15.5 8.74796 15.2472 8.05858 14.7731 7.54905C14.1095 6.79973 13.0666 6.5 11.9447 6.5C11.1546 6.5 10.3488 6.7248 9.73251 7.17439C9.00564 7.68392 8.57901 8.55313 8.5 9.48229H10.2381C10.2223 9.04768 10.3804 8.64305 10.7122 8.3733C11.0124 8.10354 11.4865 7.98365 11.9921 7.98365C12.5609 7.98365 13.114 8.13352 13.4458 8.53815C13.667 8.77793 13.746 9.10763 13.746 9.49728C13.746 10.0068 13.6354 10.4414 13.2246 10.891C12.4661 11.6553 11.8025 11.8501 11.36 12.6594C11.0282 13.2289 10.9334 13.4807 10.9334 14.2H12.9718C12.9718 13.6005 12.956 13.6935 13.2562 13.2139C13.5406 12.7493 13.8567 12.4196 14.3623 11.8951C15.0418 11.2207 15.5 10.4114 15.5 9.45232ZM10.9492 17.5H12.9718V15.6866H10.9492V17.5Z" fill={fill || "black"}/>
				<path fillRule="evenodd" clipRule="evenodd" d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" fill={fill || "black"}/>
			</>
		),
	},
	lock: {
		width: 70,
		height: 70,
		data: () => (
			<path d="M54.6875 27.3437H50.8594V19.5798C50.8594 11.1364 43.9903 4.375 35.5469 4.375C27.1035 4.375 20.2344 11.2441 20.2344 19.5798V27.3437H16.4062C12.1774 27.3437 8.75 30.7771 8.75 35V57.8611C8.75 62.09 12.1774 65.5173 16.4062 65.5173H54.6875C58.9164 65.5173 62.3438 62.09 62.3438 57.8611V35C62.3438 30.7771 58.9104 27.3437 54.6875 27.3437ZM25.9766 19.6875C25.9766 14.4107 30.27 10.1172 35.5469 10.1172C40.8237 10.1172 45.1172 14.4107 45.1172 19.6875V27.3437H25.9766V19.6875ZM56.6016 57.9687C56.6016 59.0241 55.7429 59.8828 54.6875 59.8828H16.4062C15.3509 59.8828 14.4922 59.0241 14.4922 57.9687V35C14.4922 33.9446 15.3509 33.0859 16.4062 33.0859H54.6875C55.7429 33.0859 56.6016 33.9446 56.6016 35V57.9687ZM35.5469 40.7422C33.9618 40.7422 32.6758 42.0282 32.6758 43.6133V49.3555C32.6758 50.9405 33.9618 52.1189 35.5469 52.1189C37.132 52.1189 38.418 50.9465 38.418 49.3555V43.6133C38.418 42.0342 37.1379 40.7422 35.5469 40.7422Z" fill="#888888"/>
		),
	},
	zoom: {
		width: 26,
		height: 26,
		data: ({fill}) => (
			<path fillRule="evenodd" clipRule="evenodd" d="M2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.2123 19.2017 15.2383 17.8776 16.8054L22.7121 21.0376L21.1621 22.7426L16.1671 18.3699C14.7047 19.397 12.9228 20 11 20C6.02944 20 2 15.9706 2 11ZM11 4.36842C7.33748 4.36842 4.36842 7.33748 4.36842 11C4.36842 14.6625 7.33748 17.6316 11 17.6316C14.6625 17.6316 17.6316 14.6625 17.6316 11C17.6316 7.33748 14.6625 4.36842 11 4.36842ZM10 10V7H12V10H15V12H12V15H10V12H7V10H10Z" fill={fill || "white"}/>
		),
	},
	star: {
		width: 50,
		height: 50,
		data: () => (
			<path fillRule="evenodd" clipRule="evenodd" d="M0.108398 18.5336L13.7988 30.2609L9.61617 47.7955L25.0001 38.3991L40.384 47.7955L36.2013 30.2609L49.8917 18.5336L31.9228 17.093L25.0001 0.44873L18.0773 17.093L0.108398 18.5336Z"/>
		),
	},
	upload: {
		width: 28,
		height: 28,
		data: ({fill}) => (
			<>
				<path fillRule="evenodd" clipRule="evenodd" d="M4.375 17.5C4.85825 17.5 5.25 17.8918 5.25 18.375V22.75H22.75V18.375C22.75 17.8918 23.1418 17.5 23.625 17.5C24.1082 17.5 24.5 17.8918 24.5 18.375V23.625C24.5 24.1082 24.1082 24.5 23.625 24.5H4.375C3.89175 24.5 3.5 24.1082 3.5 23.625V18.375C3.5 17.8918 3.89175 17.5 4.375 17.5Z" fill="#5F4DFF"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M14.6187 4.63128C14.4604 4.47294 14.2416 4.375 14 4.375C13.7584 4.375 13.5396 4.47294 13.3813 4.63128L7.25628 10.7563C6.91457 11.098 6.91457 11.652 7.25628 11.9937C7.59799 12.3354 8.15201 12.3354 8.49372 11.9937L13.125 7.36244V18.375C13.125 18.8582 13.5168 19.25 14 19.25C14.4832 19.25 14.875 18.8582 14.875 18.375V7.36244L19.5063 11.9937C19.848 12.3354 20.402 12.3354 20.7437 11.9937C21.0854 11.652 21.0854 11.098 20.7437 10.7563L14.6187 4.63128Z" fill="#5F4DFF"/>
				<path fillRule="evenodd" clipRule="evenodd" d="M4.375 17.5C4.85825 17.5 5.25 17.8918 5.25 18.375V22.75H22.75V18.375C22.75 17.8918 23.1418 17.5 23.625 17.5C24.1082 17.5 24.5 17.8918 24.5 18.375V23.625C24.5 24.1082 24.1082 24.5 23.625 24.5H4.375C3.89175 24.5 3.5 24.1082 3.5 23.625V18.375C3.5 17.8918 3.89175 17.5 4.375 17.5Z" fill={fill || "#5F4DFF"}/>
				<path fillRule="evenodd" clipRule="evenodd" d="M14.6187 4.63128C14.4604 4.47294 14.2416 4.375 14 4.375C13.7584 4.375 13.5396 4.47294 13.3813 4.63128L7.25628 10.7563C6.91457 11.098 6.91457 11.652 7.25628 11.9937C7.59799 12.3354 8.15201 12.3354 8.49372 11.9937L13.125 7.36244V18.375C13.125 18.8582 13.5168 19.25 14 19.25C14.4832 19.25 14.875 18.8582 14.875 18.375V7.36244L19.5063 11.9937C19.848 12.3354 20.402 12.3354 20.7437 11.9937C21.0854 11.652 21.0854 11.098 20.7437 10.7563L14.6187 4.63128Z" fill={fill || "#5F4DFF"}/>
			</>
		),
	},
	"vurvey-full-logo": {
		width: 122,
		height: 32,
		data: () => (
			<svg width="122" height="32" viewBox="0 0 122 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g id="Vurvey Logo Full" clipPath="url(#clip0_480_7710)">
					<path id="Vector" d="M14.6801 0.242432C6.89339 0.242432 0.242188 6.29968 0.242188 14.1924C0.242188 22.4567 6.51661 27.7068 14.2458 27.7068V31.833C23.1839 31.833 30.0418 24.5199 30.0418 15.7623C30.0418 6.53648 23.6509 0.242432 14.6801 0.242432Z" fill="#EBFF00"/>
					<path id="Vector_2" d="M11.9157 8.78516H9.25195V21.1855C9.49889 21.1961 10.0164 21.1974 10.3408 21.1921C17.7816 21.0552 22.8544 15.6387 22.9266 8.78646H20.1472C20.1472 13.8187 16.5838 17.9508 11.9157 18.4654V8.78516Z" fill="#2B2A56"/>
					<path id="Vector_3" d="M73.3232 8.49026C72.6477 9.02183 71.9067 9.93607 71.4852 11.1455L71.2915 7.41016H68.502V21.6187H71.6554V16.3344C71.6554 14.199 72.0874 12.7989 73.2537 11.7201C74.1701 10.8738 75.3063 10.4951 77.0696 10.4951H77.8891V7.42583H76.8563C75.216 7.42583 74.3102 7.71185 73.3232 8.49026Z" fill="#2B2A56"/>
					<path id="Vector_4" d="M45.5508 17.3689L41.7859 7.41406H38.3418L44.0624 21.6187H46.9593L52.6785 7.41406H49.3129L45.5508 17.3689Z" fill="#2B2A56"/>
					<path id="Vector_5" d="M89.839 7.41406L86.0781 17.3689L82.3119 7.41406H78.8691L84.5896 21.6187H87.4853L93.2045 7.41406H89.839Z" fill="#2B2A56"/>
					<path id="Vector_6" d="M63.1855 14.8011C63.1855 16.4611 62.9053 17.4863 62.0558 18.3079C61.5361 18.8107 60.7428 19.1124 59.8776 19.1124C59.0319 19.1124 58.3381 18.8695 57.8603 18.3927C57.1953 17.7292 57.0212 16.9404 57.0212 15.5352V7.41406H53.7773V16.1829C53.7773 18.3823 54.2775 19.6165 55.3246 20.6235C56.2109 21.4764 57.4623 21.9126 58.8775 21.9126C60.8554 21.9126 62.5035 20.9605 63.4369 19.26L63.6398 21.6187H66.4293V7.41406H63.1855V14.8011Z" fill="#2B2A56"/>
					<path id="Vector_7" d="M118.09 7.41406L114.196 17.3558L110.298 7.41406H106.854L112.583 21.3379C112.352 21.9074 112.096 22.3828 111.756 22.7811C111.048 23.6143 110.141 23.9213 108.22 23.9213H107.521V26.9395H108.439C110.359 26.9395 111.616 26.7018 112.701 25.9835C113.849 25.2234 114.536 24.2113 115.268 22.4416C116.574 19.2743 121.456 7.41536 121.456 7.41536H118.09V7.41406Z" fill="#2B2A56"/>
					<path id="Vector_8" d="M100.038 7.12109C95.8006 7.12109 92.8945 10.1159 92.8945 14.6871C92.8945 19.3405 95.7783 21.9135 99.9463 21.9135C103.3 21.9135 105.937 20.3253 106.888 17.3802H103.574C102.947 18.5661 101.765 19.2152 100.046 19.2152C97.8532 19.2152 96.2692 17.8764 96.0794 15.6365H107.074C107.098 15.2095 107.1 14.9456 107.1 14.5304C107.099 9.95915 104.278 7.12109 100.038 7.12109ZM96.1017 13.0924C96.5023 10.8799 97.9343 9.72668 100.007 9.72668C102.079 9.72668 103.511 10.8799 103.912 13.0924H96.1017Z" fill="#2B2A56"/>
				</g>
				<defs>
					<clipPath id="clip0_480_7710">
						<rect width="121.697" height="32" fill="white"/>
					</clipPath>
				</defs>
			</svg>
		),
	},
	"vurvey-logo": {
		width: 31,
		height: 32,
		data: () => (
			<svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M14.6804 0.242432C6.89363 0.242432 0.242432 6.29968 0.242432 14.1924C0.242432 22.4567 6.51686 27.7068 14.2461 27.7068V31.833C23.1841 31.833 30.0421 24.5199 30.0421 15.7623C30.0421 6.53648 23.6512 0.242432 14.6804 0.242432Z" fill="#EBFF00"/>
				<path d="M11.9163 8.78564H9.25256V21.186C9.4995 21.1965 10.017 21.1979 10.3414 21.1925C17.7823 21.0557 22.855 15.6392 22.9272 8.78695H20.1478C20.1478 13.8192 16.5844 17.9513 11.9163 18.4659V8.78564Z" fill="#2B2A56"/>
			</svg>
		),
	},
};

export interface IconProps {
	/**
	 * The icon to display
	 */
	name: IconName;
	/**
	 * Size of the icon.
	 * Supports "small", "medium", and "large" so far. Can add more
	 */
	size?: string;
	/**
	 * Used to override the default coloring
	 * or styles of an icon. (Example with modal.md)
	 */
	className?: string | undefined;
	/**
	 * Function passed to component
	 */
	clicked?: any;
	/**
	 * Children
	 */
	children?: React.ReactNode;
	/**
	 * Optional fill color. Default is white
	 */
	fill?: string;
	/**
	 * Denotes if the icon is clickable (gives pointer style on hover)
	 */
	isClickable?: boolean;
	/**
	 * Styles to be applied. Potentially to remove cursor if needed
	 */
	style?: CSSProperties | undefined;
}

/**
 * Shorter version of the icon component in main app.
 * Returns a ReactElement svg
 * @param name - Name of the icon
 * @param size - Size of the icon (small, medium, large)
 * @param clicked - click event passed to icon
 * @param className - potential override for default className
 * @param isClickable - boolean to show pointer icon on hover of icon
 * @param fill - What color to fill the icon as.
 * @param style - Styles to be applied
 *
 * @returns ReactElement of an icon
 */

const Icon = (props: IconProps): ReactElement => {
	const {name, size, className, clicked, fill, isClickable, style} = props;
	/**
	 * @returns the CSS classname of the SVG
	 */
	const getClassName = (): string => {
		if (className) {
			return className;
		}
		return classnames(styles.icon, isClickable && styles.clickable);
	};

	/* eslint-disable multiline-ternary, operator-linebreak, @typescript-eslint/indent */
	const numberSize =
	size === "tiny" ? 9 :
	size === "extrasmall" ? 18 :
	size === "small" ? 24 :
	size === "medium" ? 28 :
	size === "large" ? 36 :
	size === "extralarge" ? 48 :
	undefined;

	const icon = iconData[name];
	const x = icon.x || 0;
	const y = icon.y || 0;
	const width = numberSize ?? icon.width ?? 24;
	const height = numberSize ?? icon.height ?? 24;

	return (
		<svg
			width={width - x}
			height={height - y}
			style={style}
			onClick={clicked}
			className={getClassName()}
			xmlns="http://www.w3.org/2000/svg"
			viewBox={`${x} ${y} ${icon.width || 28} ${icon.height || 28}`}
		>
			{icon.data({fill})}
		</svg>
	);
};

export {Icon};
