import React, {InputHTMLAttributes, ReactElement} from "react";
import classNames from "classnames/bind";

import { Paragraph } from "..";

import styles from "./input.module.scss";

const cx = classNames.bind(styles);

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	label?: string;
	errorMessage?: string;
}

export const Input = ({
	label,
	errorMessage,
	className,
	...props
}: InputProps): ReactElement => {
	return (
		<div className={styles.inputWrapper}>
			{label && <label className={styles.label}>{label}</label>}
			<input
				{...props}
				className={cx("input", className, {error: errorMessage})}
			/>
			<div className={styles.errorWrapper}>
				{errorMessage && <Paragraph className={styles.errorMessage}>{errorMessage}</Paragraph>}
			</div>
		</div>
	);
};
