import {QUESTION_FRAGMENT, SURVEY_FRAGMENT} from "../fragments/fragments";
import {gql} from "@apollo/client";

export const GET_SURVEY_DELETE_ME = gql`
	query GetSurvey($id: GUID!) {
		survey(id: $id) {
			...SurveyFields
		}
		myResponse (surveyId: $id) {
			id
		}
	}
	${SURVEY_FRAGMENT}
`;
export const GET_SURVEY = gql`
	query GetSurvey($id: GUID!) {
		survey(id: $id) {
			...SurveyFields
		}

	}
	${SURVEY_FRAGMENT}
`;

export const GET_PREVIEW_DATA = gql`
	query GetPreview($id: GUID!) {
		survey(id: $id) {
			...SurveyFields
			thankYouMessage
			redirectUrl
			accessLevel
		}
		questions(surveyId: $id) {
			items {
				...QuestionFields
				required
			}
		}
	}
	${SURVEY_FRAGMENT} ${QUESTION_FRAGMENT}
`;

/**
 * Gets the current logged in user's responses to the survey, if any
 */
export const MY_RESPONSES = gql`
	query MyResponses ($surveyId: GUID!) {
		myResponses (surveyId: $surveyId) {
				id
				completedAt
		}
	}
`;
/**
 * Gets just the token for the survey
 */
export const GET_RESPONSE_TOKEN = gql`
	query GetToken ($id: GUID!) {
		survey(id: $id) {
			id
			responseToken
		}
	}
`;
/**
 * Gets the next question. Requires a responseId.
 */
export const GET_NEXT_QUESTION = gql`
	query NextQuestion ($responseId: GUID! $surveyId: GUID!) {
		nextQuestion(responseId: $responseId) {
			...QuestionFields
		}
		survey(id: $surveyId) {
			status
			questionCount
			disclaimer
			offlineAssist
			redirectUrl
			thankYouMessage
			accessLevel
		}
	}
	${QUESTION_FRAGMENT}
`;

// Mostly using this to mostly check if the user is missing any info we want
export const GET_ME = gql`
	query GetCurrentUser {
		me {
			id
			name
			email
			gender
			countryId
			birthdate
			moreInfo @client
		}
	}
`;

export const GET_TOKEN_AND_USER = gql`
	query GetInfo {
		csrfToken
		me {
			id
			name
			email
		}
	}
`;

export const GET_ANSWERED = gql`
	query GetAnswered ($id: GUID!) {
		survey (id: $id) {
			id
			answeredByMe
		}
	}
`;

/**
 * Queries for current status of the recording
 */
export const GET_RECORDING = gql`
	query GetRecording($id: String!) {
		recording(id: $id) {
			id
			status
			url
		}
	}
`;
/**
 * Queries for an upload item
*/
export const GET_UPLOAD_ITEM = gql`
	query GetUploadItem($id: String!) {
		uploadItem(id: $id) {
			id
			filename
			mimetype
			url
		}
	}
`;
