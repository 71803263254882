import React, {ReactElement, useEffect, useRef} from "react";
import {QuestionMedia} from "../../models/question";
import {Icon} from "../icon";
import styles from "./image-modal.module.scss";

export interface ImageModalProps {
	media: QuestionMedia;
	/**
	 * If the modal should be showing or not
	 */
	isShowing: boolean;
	/**
	 * If we should mute the audio or not
	 */
	muteAudio: boolean;
	/**
	 * Handle closing the modal
	 */
	handleClose: () => void;
}

const ImageModal = (props: ImageModalProps): ReactElement => {
	const {isShowing, handleClose, muteAudio, media: {video, image, arModel}} = props;
	const videoRef = useRef<HTMLVideoElement>(null);
	const backdropRef = useRef<HTMLDivElement>(null);

	/**
	 * Handles click event on the backdrop
	 */
	const handleClick = ({target}): void => {
		if (target === backdropRef.current) handleClose();
	};
	/**
	 * Adds event listeners to the backdropRef, if it exists
	 */
	useEffect(() => {
		const {current} = backdropRef;
		if (current) {
			current.addEventListener("click", handleClick);
		}
		return () => {
			if (current) {
				current.removeEventListener("click", handleClick);
			}
		};
	}, [isShowing, backdropRef.current, handleClose]);

	useEffect(() => {
		if (!videoRef.current) return;
		if (!isShowing) videoRef.current.pause();
	}, [isShowing, videoRef.current]);

	return (
		<div
			style={{display: isShowing ? "flex" : "none"}}
			className={styles.container}
			ref={backdropRef}
		>
			<div className={styles.circle} onClick={handleClose}>
				<Icon name="cross" className={styles.cross}/>
			</div>
			{video && <div
				className={styles["video-container"]}
			>
				<video
					id={video.id}
					ref={videoRef}
					controls
					controlsList="nodownload"
					className={styles.video}
					playsInline
					onContextMenu={event => event.preventDefault()}
					muted={muteAudio}
					src={video.mp4}
				/>
			</div>
			}
			{image && <img src={image.full} style={{maxWidth: "100%", maxHeight: "100%"}} onClick={handleClose} />  }
			{arModel &&
			<model-viewer
				src={arModel.glbUrl}
				ios-src={arModel.usdzUrl}
				arc
				camera-controls
				alt="A 3D model"
			/>
			}
		</div>
	);
};

export {ImageModal};
