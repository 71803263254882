/* eslint-disable */
import React, {ReactElement, useEffect} from "react";
import {
	GET_USER_FROM_ACCESS_TOKEN,
} from "../../graphql/mutations/mutations";
import {SurveyData} from "../../models/survey";
import {QueryFunction} from "../../graphql/types";
import styles from "./signed-in.module.scss";
import {useMutation} from "@apollo/client";
import {PulseButton} from "../pulse-button";

import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../hooks/user/useAuthentication";
import Bugsnag from "@bugsnag/js";

export interface SignedInProps {
	surveyId?: string;
	/**
	 * Trigger refetch on logging out
	 */
	onLogout?: QueryFunction<SurveyData>;

	accessLevel?: string;
}

const SignedIn = (props: SignedInProps): ReactElement | null => {
	const navigate = useNavigate();
	const {user, logout, me} = useAuthentication();
	const [getUserFromAccessToken] = useMutation(GET_USER_FROM_ACCESS_TOKEN);
	const {surveyId, accessLevel} = props;

	/**
	 * Logs the user out before navigating to the auth page
	 */
	const handleLogout = (): void => {
		/**
		 * Calling logout and then logoutLegacy only works in this order with
		 * how the page reloads with the survey id in the url.
		 * Tried calling the way I did in the manager app within
		 * the onCompleted callback but no luck.
		 */
		logout()
	};

	const navigateToLogin = (): void => {
		localStorage.setItem("surveyId", surveyId as string);
		navigate("/login");
	}

	useEffect(() => {
		const token = (user as any)?.stsTokenManager?.accessToken

		if (token) {
			try {
				getUserFromAccessToken({
					variables: {
						accessToken: token,
						name: user?.displayName,
					},
					onError: error => {
						console.error(error);
						Bugsnag.notify(error);
					},
				});
			} catch (e) {
				// eslint-disable-next-line no-console
				console.error(e);
			}

		}

	}, []);


	/**
	 * The ME check is INCORRECT, it should be me.me?.email because of MeData.
	 * THE QUERY FOR "ME" STILL RETURNS DATA EVEN THOUGH WE ARE "LOGGED OUT"
	 *
	 * _Could_ be a problem of apollo caching, although I've attempted cache.rest() a few times
	 * to no avail :(
	 * I'm keeping it like this because firebase has us logged out even if our API returns
	 * a value for "me".
	 */
	if (user?.email || (me as any)?.email) {
		return (
			<div className={styles.container}>
				<p className={styles.user}>
					Signed in as {user?.email || (me as any)?.email}{" "}
					<span onClick={handleLogout} className={styles.logout}>
						Sign out
					</span>
				</p>
			</div>
		);
	}
	// Check second in case user started off signed in.
	if (accessLevel?.toLocaleLowerCase() === "anonymous") return null;
	return (
		<div>
				<PulseButton
					text={"Sign in"}
					clicked={navigateToLogin}
				/>
		</div>
	);
};

export {SignedIn};
