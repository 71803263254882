import React, {ReactElement, useMemo} from "react";
import {CssSpinner} from "../css-spinner";
import styles from "./record-button.module.scss";

export interface RecordButtonProps {
	/**
	 * If we are starting up the recording
	 */
	isStarting: boolean;
	/**
	 * Are we currently recording?
	 */
	isRecording: boolean;
	/**
	 * Do we have a playback or not? Used to determine pulsing animation
	 */
	hasPlayback: boolean;
	/**
	 * Is this disabled?
	 */
	isDisabled?: boolean;
	/**
	 * Handles click on record (toggle between record and not)
	 */
	onClick: () => void;
}

/**
 * The record button. Mimics a record button on phone (decently enough)
 * @param isStarting If the recording is starting up
 * @param isRecording boolean value to determine if we are recording or not.
 * @param onRecord Callback for recording
 * @param onStopRecording Callback for stopping the recording
 */
const RecordButton = (props: RecordButtonProps): ReactElement => {
	const {isStarting, isRecording, hasPlayback, isDisabled, onClick} = props;

	/**
	 * Using a memo here may not be necessary, but for now it's fine.
	 * Should relook at this later.
	 */
	const computedRecordStyles = useMemo((): string => {
		if (!hasPlayback && !isRecording && !isDisabled) {
			return [styles["record-button"], styles.pulse].join(" ");
		}
		if (isRecording) return [styles["record-button"], styles.live].join(" ");
		if (isDisabled) return [styles["record-button"], styles.disabled].join(" ");
		return styles["record-button"];
	}, [isDisabled, isRecording, hasPlayback]);

	return (
		<div
			className={computedRecordStyles}
			onClick={isDisabled ? undefined : onClick}
		>
			<div className={styles.dot}/>
			{
				isStarting && <div className={styles.spinner}>
					<CssSpinner size="medium"/>
				</div>
			}
			<div className={styles.stop}/>
		</div>
	);
};

export {RecordButton};
