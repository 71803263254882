import {
	ApolloError,
	ApolloQueryResult,
	NetworkStatus,
	OperationVariables,
	useQuery,
} from "@apollo/client";
import {
	GET_SURVEY,
	MY_RESPONSES,
} from "../graphql/queries/queries";
import {Response} from "../models/response";
import {SurveyData} from "../models/survey";

interface MyResponsesData {
	myResponses: Response[];
}

export interface UseGetIntroductionReturn {
	/**
	 * Survey data to return
	 */
	survey?: SurveyData;
	/**
	 * Current response. If survey allows for multi response returns not completed response.
	 */
	currentResponse?: Response;
	/**
	 * Loading. We are using the loading states
	 * of all queries to determine the loading to return back
	 */
	loading: boolean;
	/**
	 * Network status, only used with one of the queries
	 */
	networkStatus: NetworkStatus;
	/**
	 * Apollo error
	 */
	error: ApolloError | undefined;
	/**
	 * Refetch function for the survey
	 */
	refetch:
	(variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<SurveyData>>;
}

// eslint-disable-next-line max-len
export const useGetIntroduction = (surveyId: string, isLoggedIn: boolean | undefined, isPreview: boolean): UseGetIntroductionReturn => {
	const {data: survey, loading: surveyLoading, error: surveyError, networkStatus, refetch} =
	useQuery<SurveyData>(GET_SURVEY, {
		variables: {id: surveyId},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "no-cache",
	});

	const {data: myResponsesData, loading: responseLoading, error: myResponseError} =
	useQuery<MyResponsesData>(MY_RESPONSES, {
		skip: !isLoggedIn || isPreview,
		variables: {surveyId},
		fetchPolicy: "no-cache",
		onCompleted: queryData => {
			const currentResponseId = queryData.myResponses?.find(response =>
				!response.completedAt)?.id;

			if (currentResponseId) {
				localStorage.setItem("responseId", currentResponseId);
			} else {
				localStorage.removeItem("responseId");
			}
		},
	});

	let currentResponse;

	if (survey?.survey.multiResponse) {
		currentResponse = myResponsesData?.myResponses.find(response => !response.completedAt)?.id;
	} else {
		currentResponse = myResponsesData?.myResponses[0];
	}


	return {
		survey,
		currentResponse,
		loading: surveyLoading || responseLoading,
		error: surveyError || myResponseError,
		networkStatus,
		refetch,
	};
};
