import {Choice} from "./answer";
import {SurveyStats} from "./survey";

export interface QuestionPage {
	items: Question[];
	cursor: string;
	remaining: number;
}
// eslint-disable-next-line max-len
export type QuestionSubtype = "MULTISELECT" | "RANKED" | "CHOICE" | "STAR" | "VIDEO" | "SHORT" | "SLIDER" | "LONG" | "NUMBER" | "PICTURE" | "VIDUPLOAD" | "PDF" | "BARCODE";
export type QuestionMedia = {
	image?: QuestionImage
	video?: QuestionVideo
	arModel?: QuestionArModel
};

export interface Question {
	id: string;
	surveyId: string;
	type: QuestionType;
	index: number;
	text: string;
	na: boolean;
	required: boolean;
	videoResponse: boolean;
	imageId?: string;
	image?: QuestionImage;
	video?: QuestionVideo;
	arModel?: QuestionArModel;
	choices?: Choice[];
	followUp?: string;
	shuffle: boolean;
	timeLimit: number;
	max?: number;
	min?: number;
	rank?: boolean;
	step?: number;
	subtype?: string;
	leftLabel?: string;
	rightLabel?: string;
}

export interface ChoiceQuestion extends Question {
	choices: Choice[];
	max: number;
	min: number;
	shuffle: boolean;
}

export interface SliderQuestionType extends Question {
	min: number;
	max: number;
}

export interface TextQuestionType extends Question {
	subtype: string; // All text questions have a subtype defined
}

export interface VideoUploadQuestionType extends Question {
	subtype: "video"
}

export interface ImageUploadQuestion extends Question {
	subtype: "picture"
}

export interface BarcodeQuestion extends Question {
	type: QuestionType.BARCODE;
}

export enum QuestionType {
	VIDEO = "NONE",
	CHOICE = "CHOICE",
	SLIDER = "SLIDER",
	PICTURE = "FILE",
	TEXT = "TEXT",
	BARCODE = "BARCODE",
}

export interface QuestionImage {
	id: string;
	small: string;
	full: string;
}
export interface QuestionVideo {
	id: string;
	small: string;
	video: string;
	mp4: string;
}
export interface QuestionArModel {
	id: string;
	usdzUrl: string;
	glbUrl: string;
}

/**
 * For the GET_QUESTIONS query return value
 */
export interface QuestionPageData {
	questions: QuestionPage;
	survey: SurveyStats;
}
/**
 * For the GET_QUESTION query return value
 */
export interface QuestionData {
	question: Question;
}
/**
 * Same as QuestionData but for the nextQuestion query
 */
export interface NextQuestionData {
	nextQuestion: Question;
}
