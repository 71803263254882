import React, {ReactElement, useRef} from "react";
import {Input, InputProps} from "../input";
import {Icon} from "../icon";
import styles from "./file-input.module.scss";
import {Spinner} from "../spinner";
import {Body} from "../typography";

import classNames from "classnames";

export interface FileInputProps extends InputProps {
	id: string;
	onChange: (file: FileList | null) => void;
	handleRemoveFile: (index: number) => void;
	loading: boolean;
	files: File[];
	allowMultiple?: boolean;
	maxFileSize?: string;
	supportedFileTypes?: string[];
}

const FileInput = ({
	id,
	loading,
	supportedFileTypes,
	maxFileSize,
	onChange,
	allowMultiple = false,
	files,
	handleRemoveFile,
	...props
}: FileInputProps): ReactElement => {
	const ref = useRef<HTMLInputElement>(null);

	const uploadPrompt = supportedFileTypes
		? <span className={styles.supportedFiles}>Upload a file {supportedFileTypes.join(", ")}</span>
		: <span>Upload a file</span>;
	const uploadBoxText = loading
		? <span>Uploading...</span> :
		files.length > 0 ?<>
			<span>Uploaded files</span>
			{(allowMultiple && files.length < 3) &&
			<p className={styles.uploadAnother}>Upload another</p>
			}
		</>
			: uploadPrompt;

	const deleteFile = (index: number): void => {
		if (ref.current) {
			ref.current.value = "";
		}
		handleRemoveFile(index);
	}


	const renderFile = (file: File, index: number):ReactElement => {
		if (file.type.includes("pdf")) {
			return <div className={styles.pdfFile} key={`${file.name}-${index}`}>
				<Body>{file.name}</Body>
				<div className={styles.delete} onClick={() => deleteFile(index)}>X</div>
			</div>
		}

		return <div key={`${file.name}-${index}`} className={styles.previewContainer}>
						<div className={styles.delete} onClick={() => deleteFile(index)}>X</div>
							<img src={URL.createObjectURL(file)} className={styles.preview}/>
					</div>
	}

	return (
		<>
			<Input id={id} {...props}>
				<label htmlFor={id} className={classNames(
					(files.length === 0) ? styles.input : styles.uploaded,
					((allowMultiple && files.length === 3) || (!allowMultiple && files.length > 0)) && styles.disabled
					)}>
					{loading && files.length < 1
						? (
							<Spinner className={styles.spinner} />
						)
						: files.length > 0
							? (
								<Icon
									name="big-grey-checkmark"
									fill="green"
									size="small"
									className={styles.icon}
								/>
							)
							: (
								<Icon name="upload" />
							)}
					<div className={styles.middle}>
						{uploadBoxText}
						{maxFileSize && !files.length && <span>max file size {maxFileSize}</span>}
						{allowMultiple && !files.length && <span>You may upload up to 3 files</span>}
					</div>
					<input
						ref={ref}
						accept={supportedFileTypes?.includes("PDF") ? "application/pdf" : "image/*"}
						type="file"
						id={id}
						onChange={e => onChange(e.target.files)}
						multiple={allowMultiple}
						disabled={files.length > 0 && !allowMultiple}
					/>
				</label>
			</Input>
			{files.length > 0 &&
				<div className={styles.imagePreviews}>
					{files.map((file, i) => renderFile(file, i))}
					{loading && <div className={classNames(styles.previewContainer, styles.loading)}>
						<Spinner />
						<span>Uploading...</span>
					</div>}
				</div>
			}
		</>
	);
};

export {FileInput};
