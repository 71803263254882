import React, {ReactElement} from "react";
import {PreviewBar} from "../components/preview-bar";
import styles from "./layout.module.scss";
import {useInnerWindowHeight} from "../hooks/useInnerWindowHeight";

export interface LayoutProps {
	/**
	 * Children to render
	 */
	children: React.ReactNode;
	/**
	 * If preview bar should be shown
	 */
	withPreview?: boolean;
	/**
	 * If we have a disclaimer, which pushes up some values.
	 */
	hasDisclaimer?: boolean;
}

const Layout = (props: LayoutProps): ReactElement => {
	const {withPreview, hasDisclaimer} = props;
	const windowHeight = useInnerWindowHeight();

	const getHeight = (): any => {
		let height = windowHeight - 80; // 80 is min height of the navigation bar
		if (withPreview && hasDisclaimer) {
		// 44 is height of preview bar, 30 is height of disclaimer
			height -= 44 + 30;
		} else if (withPreview) {
			height -= 44;
		} else if (hasDisclaimer) {
			height -= 30;
		}
		return height;
	};

	return (
		<>
			{
				withPreview && <PreviewBar/>
			}
			<div
				style={{height: getHeight()}}
				className={styles.container}
			>
				{props.children}
			</div>
		</>
	);
};

export {Layout};
