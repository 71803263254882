import {useQuery} from "@apollo/client";
import classNames from "classnames";
import React, {ReactElement, useState} from "react";
import {useParams} from "react-router-dom";
import {IntroDetails, IntroDetailsProps} from "../../components/intro-details";
import {PreviewBar} from "../../components/preview-bar";
import {QuestionDisplay} from "../../components/question";
import {QuestionContextProvider} from "../../context/question-context";
import {GET_PREVIEW_DATA} from "../../graphql/queries/queries";
import {DisplayContextProvider} from "../../context/display-context";
import {PreviewData, SurveyAccessLevel} from "../../models/survey";
import styles from "./previewer.module.scss";
import {PulseButton} from "../../components/pulse-button";
import {QuestionType} from "../../models/question";
import {RichTextInput} from "../../components/rich-text-input";
import {ImageModal} from "../../components/image-modal";
import {RecordButton} from "../../components/record-button";
import {useWindowDimensions} from "../../hooks/useWindowDimensions";
import {isParseable} from "../../utility/helpers";
import {useCarousel} from "../../hooks/useCarousel";
import {Button} from "../../components/button";
import config from "../../config";

interface FakeVideoProps {
	text: string;
	toggleMediaModal?: () => void;
	showMediaButton?: boolean;
}

interface ThankYouProps {
	thankYou?: string;
	redirectUrl?: string | null;
	accessLevel?: SurveyAccessLevel
}


const Introduction = (props: IntroDetailsProps): ReactElement =>
	<div className={styles.introContainer}>
		<IntroDetails {...props}/>
	</div>;

const ThankYouPage = ({thankYou, redirectUrl}: ThankYouProps): ReactElement =>
	<div className={styles.thanksContainer}>
		<div className={styles.completed}>
			<svg
				width="52"
				height="38"
				viewBox="0 0 52 38"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path d="M2 20L17 35L50 2" stroke="white" strokeWidth="4"/>
			</svg>
		</div>
		<div className={styles["thank-you"]}>
			{isParseable(thankYou) ?
				thankYou && <RichTextInput text={thankYou} /> :
				thankYou || "Thank you for responding! 🎉"}
			{redirectUrl && <div className={styles.redirecting}>
				You will be redirected to another page momentarily.
				If you are not redirected automatically, <a href={redirectUrl}>click here.</a>
			</div>}
		</div>
	</div>;

const FakeVideo = ({text, showMediaButton, toggleMediaModal}: FakeVideoProps): ReactElement => {
	return <div className={styles.fakeVideo}>
		<div className={styles.bottom}>
			<p className={styles.text}>{text}</p>
			{showMediaButton &&
				<Button onClick={toggleMediaModal} className={styles.showMediaButton}>Show image/video</Button>
			}
			<RecordButton
				hasPlayback={false}
				isRecording={false}
				isStarting={false}
				isDisabled
				onClick={() => undefined}
			/>
		</div>
	</div>;
};

const Previewer = (): ReactElement => {
	const {surveyId} = useParams<{surveyId: string}>();
	/* eslint-disable-next-line max-len */
	// const auth = new URLSearchParams(new URL(window.location.href.replace("/#", "")).search).get("auth");
	// localStorage.setItem("auth", auth || "");
	const {height: windowHeight} = useWindowDimensions();
	const [index, setIndex] = useState(0);
	const [choice, setChoice] = useState<string[]>([]);
	const [text, setText] = useState<string>();
	const [barcode, setBarcode] = useState<string[]>([]);
	const [numberAnswer, setNumberAnswer] = useState<number | null>();
	const [video, setVideo] = useState<string | null>(null);
	const [files, setFiles] = useState<string[]>([]);
	const [pdf, setPdf] = useState<string[]>([]);
	const [showVideo, setShowVideo] = useState(true);
	const [showModal, setShowModal] = useState<boolean>(false);

	const {
		ref,
		canScrollLeft,
		canScrollRight,
		handleScrollLeft,
		handleScrollRight,
		showArrows,
	} = useCarousel();

	const {data, loading} = useQuery<PreviewData>(GET_PREVIEW_DATA, {
		variables: {id: surveyId},
		onError: (error) => {
			window.location.href = config.managerHost
		}
	});


	const handleToggleModal = (): void => setShowModal(prev => !prev);

	const questions = data?.questions.items;
	const survey = data?.survey;

	const allInfo = questions?.map(question => <QuestionDisplay
		key={question.id}
		question={question}
		handleShowModal={handleToggleModal}
		videoRecorder={question.videoResponse
			? <FakeVideo
					text={question.followUp || question.text}
					toggleMediaModal={handleToggleModal}
					showMediaButton={Boolean(question.image || question.video || question.arModel)}
				/>
			: null} />) ?? [];

	allInfo?.unshift(<Introduction
		survey={survey}
		loading={loading}
		isPreview
	/>);

	allInfo?.push(<ThankYouPage
		key="Thank-you" thankYou={survey?.thankYouMessage}
		redirectUrl={survey?.redirectUrl}
		accessLevel={survey?.accessLevel}
	/>);

	const handleSet = (newIndex: number): void => {
		setChoice([]);
		setText("");
		setNumberAnswer(null);
		setFiles([]);
		setPdf([]);
		setIndex(newIndex);
	};

	const handleSkip = (): void => setIndex(prev => prev + 1);

	return (
		<QuestionContextProvider
			isPreview
			question={index === 0 ? undefined : allInfo?.[index].props?.question}
			files={files}
			barcode={barcode}
			selected={choice}
			numberAnswer={numberAnswer}
			setText={setText}
			setFiles={setFiles}
			setBarcode={setBarcode}
			setNumberAnswer={setNumberAnswer}
			setSelected={setChoice}
			video={video}
			setVideo={setVideo}
			text={text}
			pdf={pdf}
			setPdf={setPdf}
		>
			<DisplayContextProvider
				videoState={[showVideo, setShowVideo]}
				question={allInfo?.[index].props.question}
			>
				<div style={{height: windowHeight}}className={styles.container}>
					<PreviewBar />
					<div style={{height: windowHeight - 124}} className={styles.mainContent}>
						{allInfo?.[index]}
						{
							allInfo?.[index].props.question?.videoResponse
								&& allInfo?.[index].props.question?.type !== QuestionType.VIDEO &&
							<PulseButton
								text="Toggle followup"
								clicked={() => setShowVideo(prev => !prev)}
								className={styles.followupButton}/>
						}
						{
							!allInfo?.[index].props.question?.required && (index !== allInfo.length - 1 && index !== 0) &&
							<PulseButton
								text="Skip >"
								clicked={handleSkip}
								className={styles.skipButton}/>
						}
					</div>
					{allInfo && index !== 0 && index !== allInfo.length - 1 &&
					<ImageModal
						media={{
							image: allInfo[index].props.question.image,
							video: allInfo[index].props.question.video,
							arModel: allInfo[index].props.question.arModel,
						}}
						isShowing={showModal}
						handleClose={handleToggleModal}
						muteAudio={false}
					/>}
					<div className={styles.bottomNav}>
						{showArrows && <>
							<span
								className={classNames(styles.left, !canScrollLeft && styles.disabled)}
								onClick={() => handleScrollLeft(150)}
							/>
							<span
								className={classNames(styles.right, !canScrollRight && styles.disabled)}
								onClick={() => handleScrollRight(150)}/>
						</>
						}
						<div className={styles.previewContainer} ref={ref}>
							{allInfo?.map((item, i) => <span
								className={classNames(styles.navItem, index === i && styles.active)}
								key={item.key}
								onClick={() => handleSet(i)}>
								{i === 0
									? "Introduction"
									: i === allInfo?.length - 1 ? "Thank You" : `${i}. ${item.props.question.text}`}
							</span>)}
						</div>
					</div>
				</div>
			</DisplayContextProvider>
		</QuestionContextProvider>
	);
};

export {Previewer};
