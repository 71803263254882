import {logEvent, setUserId} from "firebase/analytics";
import {analytics} from "./firebase";

export const sendAnalyticsEvent = (eventName: string, method?: string):void => {
	logEvent(analytics, eventName, {
		method,
	});
};

export const setAnalyticsUser = (userId: string):void => {
	setUserId(analytics, userId);
};
