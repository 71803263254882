import React, {ReactElement, useEffect, useMemo} from "react";
import {Question, QuestionSubtype} from "../models/question";
import {Setter} from "../types";
import {determineQuestionType} from "../utility/utility";

interface QuestionContext {
	question: Question | undefined;
	selected: string[];
	isPreview: boolean;
	text: string | undefined;
	barcode: string[];
	numberAnswer: number | undefined | null;
	questionType: QuestionSubtype;
	files: string[];
	pdf: string[];
	video: string | null;
	setSingle: (newSelected: string) => void;
	setMulti: (newSelected: string) => void;
	setRanked: Setter<string[]>;
	setText: Setter<string | undefined>;
	setBarcode: Setter<string[]>;
	setNumberAnswer: Setter<number | undefined | null>;
	setFiles: Setter<string[]>;
	setPdf: Setter<string[]>;
	setVideo: Setter<string | null>;
	emptySelectValues: () => void;
}

const QuestionContext = React.createContext<QuestionContext>({
	question: undefined,
	questionType: "CHOICE",
	selected: [],
	isPreview: false,
	numberAnswer: undefined,
	text: undefined,
	barcode: [],
	files: [],
	pdf: [],
	video: null,
	setSingle: () => undefined,
	setMulti: () => undefined,
	setRanked: () => undefined,
	setNumberAnswer: () => undefined,
	setFiles: () => undefined,
	setPdf: () => undefined,
	setText: () => undefined,
	setBarcode: () => undefined,
	emptySelectValues: () => undefined,
	setVideo: () => undefined,
});

interface QuestionContextPreviewProps {
	isPreview: boolean;
	question: undefined;
	selected: string[];
	text: string | undefined;
	barcode: [];
	numberAnswer: number | undefined | null;
	files: string[];
	pdf: string[];
	video: string | null;
	setText: Setter<string | undefined>;
	setSelected: Setter<string[]>;
	setBarcode: Setter<string[]>;
	setNumberAnswer: Setter<number | undefined | null>;
	setPdf: Setter<string[]>,
	setFiles: Setter<string[]>;
	setVideo: Setter<string | null>;
	children: React.ReactNode;
}
interface QuestionContextPropsReal {
	isPreview?: boolean;
	question: Question | undefined;
	selected: string[];
	text: string | undefined;
	barcode: string[];
	numberAnswer: number | undefined | null;
	files: string[];
	pdf: string[];
	video: string | null;
	setText: Setter<string | undefined>;
	setSelected: Setter<string[]>;
	setBarcode: Setter<string[]>;
	setNumberAnswer: Setter<number | undefined | null>;
	setPdf: Setter<string[]>,
	setVideo: Setter<string | null>;
	setFiles: Setter<string[]>;
	children: React.ReactNode;
}

export type QuestionContextProps = QuestionContextPropsReal | QuestionContextPreviewProps;

const QuestionContextProvider = (props: QuestionContextProps): ReactElement => {
	// eslint-disable-next-line max-len
	const {selected, question, numberAnswer, text, barcode, files, pdf, video, setSelected, setBarcode, setNumberAnswer, setText, setFiles, setPdf, setVideo, isPreview = false} = props;
	const questionType = useMemo(() => determineQuestionType(question), [question]);

	useEffect(() => {
		if (isPreview) return;
		/* eslint-disable-next-line max-len */
		if (questionType === "RANKED" && !question?.shuffle) setSelected((question?.choices ?? []).map(c => c.id));
		if (questionType === "SLIDER") setNumberAnswer(question?.min);
	}, [questionType, question]);

	const setMulti = (newSelected: string): void => {
		const updated = selected.slice();
		const index = updated.findIndex(value => value === newSelected);
		if (index >= 0) {
			updated.splice(index, 1);
		} else {
			updated.push(newSelected);
		}
		setSelected(updated);
	};

	const setSingle = (newSelected: string): void => {
		setSelected([newSelected]);
	};

	const emptySelectValues = (): void => {
		setSelected([]);
	};

	return (
		<QuestionContext.Provider
			value={{
				question,
				barcode,
				selected,
				questionType,
				numberAnswer,
				text,
				files,
				pdf,
				isPreview,
				video,
				setSingle,
				setBarcode,
				setText,
				setNumberAnswer,
				setRanked: setSelected,
				setMulti,
				setFiles,
				setPdf,
				emptySelectValues,
				setVideo,
			}}
		>
			{props.children}
		</QuestionContext.Provider>
	);
};

export {QuestionContext, QuestionContextProvider};

