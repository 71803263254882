import {useMutation} from "@apollo/client";
import {UPLOAD_ANSWER_VIDEO, UPLOAD_FILE} from "../graphql/mutations/mutations";
import {UploadFileReturn} from "../models/mutation-returns";
import {useContext, useEffect, useState} from "react";
import {ToastContext} from "../context/toast-context";
import {Setter} from "../types";
import {QuestionContext} from "../context/question-context";

export interface UseVideoUploadReturn {
	loading: boolean;
	handleChange: (fileList: FileList | null) => void;
	startUploading: () => Promise<any>;
	setLoading: Setter<boolean>;
	setVideo: Setter<string | null>;
}


export const useVideoUpload = (
	rawFile: FileList | undefined,
	setRawFile: Setter<FileList | undefined>,
	maxFileSize: number,
): UseVideoUploadReturn => {
	const [loading, setLoading] = useState(false);

	const [uploadItem] = useMutation<UploadFileReturn>(UPLOAD_FILE);
	const [uploadAnswerVideo] = useMutation(UPLOAD_ANSWER_VIDEO);
	const {updateToast} = useContext(ToastContext);
	const {setVideo, question} = useContext(QuestionContext);

	const handleChange = (fileList: FileList | null): void => {
		if (!fileList) {
			setRawFile?.(undefined);
			setVideo(null);
			return;
		}

		if (fileList[0].size > maxFileSize * 1024 * 1024) {
			updateToast({type: "failure", description: `File size exceeds ${maxFileSize} MB`});
			throw new Error("File size exceeds limit");
		}

		setRawFile?.(fileList);
	};

	const startUploading = async(): Promise<any> => {
		if (!(rawFile && setRawFile)) return;
		setLoading(true);
		const [file] = rawFile;
		const uploadResult = await uploadItem({
			variables: {file},
			onError: () => {
				setLoading(false);
				updateToast({type: "failure", description: "Failed to upload, please try again"});
				setRawFile(undefined);
			},
		});
		if (uploadResult.data) {
			uploadAnswerVideo({
				variables: {uploadItemId: uploadResult.data.upload.id, questionId: question?.id},
				onError: () => {
					updateToast({
						type: "failure",
						// eslint-disable-next-line max-len
						description: "Failed to upload, make sure you are using a supported video format (MP3, MP4, WEBM)",
					});
					setRawFile(undefined);
					setLoading(false);
				},
				onCompleted: data => {
					if (!data) return;
					setLoading(false);
					setVideo(data.uploadAnswerVideo.id);
				},
			});
		}
	};

	useEffect(() => {
		if (rawFile && rawFile.length > 0) startUploading();
	}, [rawFile, setRawFile]);

	return {
		loading,
		handleChange,
		startUploading,
		setLoading,
		setVideo,
	};
};
