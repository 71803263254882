import React, {ReactElement} from "react";
import classNames, {Argument} from "classnames";
import styles from "./spinner.module.scss";

export interface SpinnerProps {
	className?: Argument;
}

const Spinner = ({className}: SpinnerProps): ReactElement =>
	<div className={classNames(styles.spinner, className)}/>;

export {Spinner};
