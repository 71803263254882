/* eslint-disable no-console */
import React, {ReactElement, useRef, useState} from "react";
import styles from "./range-slider.module.scss";
import classNames from "classnames";

export interface RangeSliderProps {
	value: number;
	min: number;
	max: number;
	leftLabel?: string;
	rightLabel?: string;
	onChange: (newValue: number) => void;
}

const getPercent = (value: number, max: number, min: number): number =>
	Math.round(((value - min) / (max - min)) * 100);

const RangeSlider = (props: RangeSliderProps): ReactElement => {
	const {value, min, max, leftLabel, rightLabel, onChange} = props;
	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const ref = useRef<HTMLDivElement>(null);
	const toolRef = useRef<HTMLOutputElement>(null);

	const startDragging = (): void => setShowTooltip(true);
	const stopDragging = (): void => setShowTooltip(false);

	// Done outside of useEffect so initial alignment of tooltip is correct.
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		onChange(e.target.valueAsNumber);
		if (ref.current && toolRef.current) {
			ref.current.style.width = `${getPercent(e.target.valueAsNumber, max, min)}%`;
			const leftVal = ((e.target.valueAsNumber - min) * 100) / (max - min);
			// Static numbers are based on the size of the "thumb"
			toolRef.current.style.left = `calc(${leftVal}% + (${12 - (leftVal * 0.24)}px))`;
		}
	};

	return (
		<div className={styles.container}>
			<input
				type="range"
				value={value}
				className={styles.thumb}
				min={min}
				max={max}
				onMouseDownCapture={startDragging}
				onMouseUpCapture={stopDragging}
				onTouchStart={startDragging}
				onTouchEnd={stopDragging}
				onChange={handleChange}
			/>
			<output
				ref={toolRef}
				className={classNames(styles.tooltip, showTooltip && styles.show)}
			>
				{value}
			</output>
			<div className={styles.slider}>
				<div className={styles.sliderTrack}/>
				<div ref={ref} className={styles.sliderRange}/>
			</div>
			<div className={styles.labels}>
				<span>{leftLabel}</span>
				<span>{rightLabel}</span>
			</div>
		</div>
	);
};

export {RangeSlider};
