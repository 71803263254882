import classNames from "classnames/bind";
import React, {ReactElement, type PropsWithChildren} from "react";

import styles from "./header.module.scss";

const cx = classNames.bind(styles);

export type HeaderVariants = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type HeaderProps = PropsWithChildren<{
	className?: string;
	variant: HeaderVariants;
}>;

export const Header = ({
	children,
	className,
	variant,
}: HeaderProps):ReactElement => {
	const headerClassName = cx(variant, className, "header", "green-500", "lh-120");

	const ElementComponent = variant;

	return (
		<ElementComponent className={headerClassName}>
			{children}
		</ElementComponent>
	);
};
