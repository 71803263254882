import React, { ReactElement, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useAuthentication } from "../../../hooks/user/useAuthentication";

export const Redirect = (): ReactElement => {
  const navigate = useNavigate();
  const surveyId = localStorage.getItem("surveyId");
  const {user} = useAuthentication();

  useEffect(() => {
    if (user) {
      navigate(`/${surveyId}`);
    } else {
      navigate("/login");
    }
  }, [user]);

  return <></>;
}
