import {gql} from "@apollo/client";

export const SURVEY_FRAGMENT = gql`
	fragment SurveyFields on Survey {
		id
		title
		introduction
		instructions
		startText
		accessLevel
		status
		questionCount
		answeredByMe
		offlineAssist
		offlineAssistPdf {
			url
		}
		introImage {
			id
			medium: url(version:MEDIUM)
			full: url(version:FULL_SIZE)
		}
		introVideo {
			id
			medium:url(version:MEDIUM_STILL)
			video:url(version:VP8_VORBIS)
			mp4:url(version:H264_AAC)
		}
		disclaimer
		permissions
		multiResponse
		incentiveAmount
		incentiveCurrency
		closeAt
		closeAfterResponsesCount
		completedResponseCount
		estimatedCompletionTime
	}
`;

export const UPLOAD_FILE_FRAGMENT = gql`
	fragment QuestionFileFields on QuestionImage {
		id
		small: url (version:SMALL)
		full: url (version:FULL_SIZE)
	}
`;

export const QUESTION_FRAGMENT = gql`
	fragment QuestionFields on Question {
		id
		surveyId
		type
		videoResponse
		index
		text
		na
		required
		imageId
		image {
			...QuestionFileFields
		}
		video {
			id
			small:url(version:SMALL_STILL)
			video:url(version:VP8_VORBIS)
			mp4:url(version:H264_AAC)
		}
		arModel {
			id
			usdzUrl:url(version:USDZ)
			glbUrl:url(version:GLB)
		}
		choices {
			id
			text
		}
		followUp
		shuffle
		timeLimit
		min
		max
		rank
		step
		subtype
		leftLabel
		rightLabel
	}
	${UPLOAD_FILE_FRAGMENT}
`;
