/* eslint-disable @typescript-eslint/indent */
import React, {ReactElement} from "react";
import {animated, useSpring} from "react-spring";
import {UploadError} from "../../hooks/useTokBox";
import classnames from "classnames/bind";
import styles from "./retry-banner.module.scss";

const bStyles = classnames.bind(styles);

export interface RetryBannerProps {
	/**
	 * The different functions for retrying based on the error.
	 */
	retry: {
		getRecording: () => Promise<void>;
		acceptAnswer: () => Promise<void>;
		retryAnswerVideo: () => void;
	}
	uploadError: UploadError;
}

const RetryBanner = (props: RetryBannerProps): ReactElement | null => {
	const {retry, uploadError} = props;
	const animatedProps = useSpring({
		transform: "translateY(0)",
		from: {transform: "translateY(-100vh)"},
	});
	if (uploadError === UploadError.NONE) return null;
	return (
		<animated.div
			style={animatedProps}
			className={bStyles(
				"container",
				{
					warning: uploadError !== UploadError.FAILED,
					error: uploadError === UploadError.FAILED,
				},
			)}
		>
			<p>
			{
				uploadError === UploadError.FAILED ?
					<>
						{/* eslint-disable-next-line max-len */}
						Video failed to upload. Make sure you have a stable internet connection and record a new video.
					</>
				: uploadError === UploadError.TIMED_OUT || uploadError === UploadError.NETWORK ?
					<>
						Your connection may be unstable. You can <span
							className={styles.action}
							/* eslint-disable-next-line @typescript-eslint/no-misused-promises */
							onClick={retry.getRecording}
						>
						try again.
						</span>
						{" "}or record a new video to upload.
					</>
				: uploadError === UploadError.SUBMIT ?
					<>
						Your connection may be unstable. Check your connection and <span
							className={styles.action}
							/* eslint-disable-next-line @typescript-eslint/no-misused-promises */
							onClick={retry.acceptAnswer}
						>
					try again.
						</span>
					</>
				: uploadError === UploadError.ANSWER ?
					<>
						The operation timed out. Check your connection and <span
							className={styles.action}
							onClick={retry.retryAnswerVideo}
						>
						try again.
						</span>
					</>
				:	<>
						You may be experiencing some latency, make sure your connection is stable
					</>
			}
			</p>
		</animated.div>
	);
};

export {RetryBanner};
