import {useState, useRef, useEffect} from "react";
import {Setter} from "../types";

export interface UseCarouselReturn {
	ref: React.RefObject<HTMLDivElement>
	showArrows: boolean;
	canScrollLeft: boolean;
	canScrollRight: boolean;
	setShowArrows: Setter<boolean>;
	setCanScrollLeft: Setter<boolean>;
	setCanScrollRight: Setter<boolean>;
	handleScrollLeft: (amt: number) => void;
	handleScrollRight: (amt: number) => void;
}

export const useCarousel = (): UseCarouselReturn => {
	const ref = useRef<HTMLDivElement>(null);
	const [showArrows, setShowArrows] = useState(false);
	const [canScrollRight, setCanScrollRight] = useState(true);
	const [canScrollLeft, setCanScrollLeft] = useState(false);


	const handleScrollLeft = (amt: number): void => {
		if (!ref.current) return;
		ref.current.scrollBy({
			left: -amt,
			behavior: "smooth",
		});
	};

	const handleScrollRight = (amt: number): void => {
		if (!ref.current) return;
		ref.current.scrollBy({
			// eslint-disable-next-line no-implicit-coercion
			left: +amt,
			behavior: "smooth",
		});
	};

	const handleScroll = (): void => {
		if (!ref.current) return;
		const {current: {clientWidth, scrollWidth, scrollLeft}} = ref;
		setCanScrollRight(scrollLeft + clientWidth + 10 <= scrollWidth);
		setCanScrollLeft(scrollLeft > 0);
	};

	useEffect(() => {
		if (!ref.current) return;
		const {current: {clientWidth, scrollWidth}} = ref;
		const scrollable = clientWidth < scrollWidth;
		setShowArrows(scrollable);
		if (scrollable) {
			ref.current.addEventListener("scroll", handleScroll);
		}
		return () => ref.current?.removeEventListener("scroll", handleScroll);
	}, [ref, ref.current, ref.current?.scrollWidth, ref.current?.clientWidth]);


	return {
		ref,
		showArrows,
		canScrollLeft,
		canScrollRight,
		setShowArrows,
		setCanScrollLeft,
		setCanScrollRight,
		handleScrollLeft,
		handleScrollRight,
	};
};

