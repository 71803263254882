import React, {ReactElement, ReactNode} from "react";

import { Header } from "../header";
import { useNavigate } from "react-router-dom";

import { ArrowLeftIcon, VurveyLogoFullIcon, VurveyLogoIcon } from "../../../icons";

import styles from "./step-template.module.scss";

export interface StepTemplateProps {
	header?: string;
	children?: ReactNode;
	imageSrc: string;
	imageText: ReactNode;
	onRevert?: () => void;
	description?: string;
	revertRoute?: string;
	id?: string;
}

export const StepTemplate = ({
	imageSrc,
	imageText,
	header = "Create A Free Account",
	description = "It's quick and easy to start earning rewards",
	revertRoute = "/login",
	onRevert,
	children,
	id,
}: StepTemplateProps): ReactElement => {
	const navigate = useNavigate();

	return (
		<>
			<div className={styles.content} id={id}>
				<div className={styles.contentWrapper}>
					<VurveyLogoIcon className={styles.logo} />
					<VurveyLogoFullIcon className={styles.fullLogo} />
					<ArrowLeftIcon onClick={() => {
						onRevert ? onRevert() : navigate(revertRoute)
					}} className={styles.closeIcon} />
					<Header className={styles.header} variant="h4">
						{header}
					</Header>
					<div className={styles.description}>
						{description}
					</div>
					{children}
				</div>
			</div>
			<div className={styles.imageWrapper}>
				<img className={styles.image} src={imageSrc} alt="Login" />
				<div className={styles.imageTextWrapper}>
					{imageText}
				</div>
			</div>
		</>
	);
};
