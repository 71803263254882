import React, {ReactElement, useCallback, useEffect, useRef, useState} from "react";
import {PlayButton} from "../play-button";
import {PlayPreview} from "../play-preview";
import {RecordButton} from "../record-button";
import {TokState, UploadError} from "../../hooks/useTokBox";
import classNames from "classnames";
import {formatTime} from "../../utility/utility";
import styles from "./video-recorder.module.scss";
import {Icon} from "../icon";
import {useVideoUpload} from "../../hooks/useVideoUpload";
import {Spinner} from "../spinner";
import {Button} from "../button";

export interface VideoRecorderProps {
	/**
	 * Preview URL from TokBox
	 */
	previewUrl: string | undefined;
	/**
	 * Current session (if we have one)
	 */
	session: OT.Session | undefined;
	/**
	 * Can we record or not?
	 */
	canRecord: boolean;
	/**
	 * The question text
	 */
	text: string;
	/**
	 * Recording state
	 */
	recordingState: TokState;
	/**
	 * Time Limit
	 */
	timeLimit: number;
	/**
	 * Resets the video state
	 */
	reset: () => void;
	/**
	 * Start recording function
	 */
	startRecording: () => void;
	/**
	 * Starts the video
	 */
	startVideo: (publishDiv: HTMLDivElement) => Promise<void>;
	/**
	 * Stop recording function
	 */
	stopRecording: () => Promise<void>;
	/**
	 * Toggle between available camers
	 */
	toggleCamera: () => Promise<void>;
	/**
	 * Number of available cameras
	 */
	videoDevicesCount: number;
	uploadError: UploadError;
	offlineMode?: boolean;
	toggleMediaModal?: () => void;
	showMediaButton?: boolean;
}
/**
 * Video that will display either the current recording, or the live
 * feed from the user's camera
 * @param source Source created from URL.createObjectURL
 */
const VideoRecorder = (props: VideoRecorderProps): ReactElement => {
	const {
		session,
		text,
		canRecord,
		recordingState: {isProcessing, isRecording, isStarting},
		startRecording,
		startVideo,
		stopRecording,
		previewUrl,
		timeLimit,
		toggleCamera,
		videoDevicesCount,
		offlineMode,
		toggleMediaModal,
		showMediaButton
	} = props;
	const videoRef = useRef<HTMLVideoElement>(null);
	const publishRef = useRef<HTMLDivElement>(null);
	const inputRef = useRef<HTMLInputElement>(null);
	const [rawFile, setRawFile] = useState<FileList | undefined>(undefined);
	const [seconds, setSeconds] = useState<number>(timeLimit);
	const [countdown, setCountdown] = useState(3);
	const [startCountdown, setStartCountdown] = useState(false);
	const [selectRecord, setSelectRecord] = useState(false);
	const [isShowingPreview, setIsShowingPreview] = useState<boolean>(false);
	const [playingTimer, setPlayingTimer] = useState<string>("0:00");
	const [playing, setPlaying] = useState<boolean>(false);

	const {loading, handleChange} = useVideoUpload(rawFile, setRawFile);

	/**
	 * Function that will toggle recording state.
	 */
	const handleStopStartRecording = (): void => {
		if (isRecording) {
			setCountdown(3);
			stopRecording();
		} else {
			if (seconds !== timeLimit) {
				setSeconds(timeLimit);
			}
			setStartCountdown(true);
		}
	};
	useEffect(() => {
		if (publishRef.current && session) {
			startVideo(publishRef.current);
		}
	}, [publishRef.current, session, selectRecord]);

	/**
	 * This function will update the timer while the video is in playback mode
	 */
	const setPlayingTime = (): void => {
		if (videoRef.current) {
			let time = "0:00";
			if (videoRef.current.currentTime > 0) {
				time = new Date(videoRef.current.currentTime * 1000).toISOString().substr(15, 4);
			}
			setPlayingTimer(time);
		}
	};

	useEffect(() => {
		// eslint-disable-next-line prefer-const
		let interval: NodeJS.Timeout | undefined;
		if (!startCountdown) return clearInterval(interval);
		interval = setInterval(() => setCountdown(prev => prev - 1), 1000);
		setTimeout(() => {
			startRecording();
			setStartCountdown(false);
			clearInterval(interval);
			setCountdown(3);
		}, 3000);
		return () => clearInterval(interval);
	}, [startCountdown]);
	/**
	 * This is a timer for the recording only.
	 */
	useEffect(() => {
		let timeInterval: NodeJS.Timeout | undefined;
		if (isRecording && !isStarting) {
			timeInterval = setInterval(() => setSeconds(prevTime => prevTime - 1), 1000);
		} else {
			clearInterval(timeInterval);
		}
		return () => clearInterval(timeInterval);
	}, [isRecording, playing, isStarting]);
	/**
	 * Event handler for when the video is playing.
	 * Sets playing to true
	 */
	const handlePlaying = useCallback((): void => {
		if (videoRef.current && previewUrl) {
			setPlaying(true);
		}
	}, [previewUrl]);
	/**
	 * Event handler for when the video is paused.
	 * Sets playing to false
	 */
	const handlePaused = useCallback((): void => {
		if (videoRef.current && previewUrl) {
			setPlaying(false);
		}
	}, [previewUrl]);
	/**
	 * Toggles showing the preview video if it is available
	 */
	const togglePreview = (): void => {
		setIsShowingPreview(prev => !prev);
		if (videoRef.current && !videoRef.current.paused) {
			videoRef.current.pause();
		}
	};
	/**
	 * Triggers the video to play if paused, or pause if it is playing.
	 */
	const handlePlay = (): void => {
		if (videoRef.current && previewUrl) {
			if (videoRef.current.currentTime === 0 || videoRef.current.ended) {
				setSeconds(0);
			}
			if (videoRef.current.paused) {
				videoRef.current.play();
			} else {
				videoRef.current.pause();
			}
		}
	};

	/**
	 * New function for dealing with offline mode
	 */
	const handleDelete = (e: React.MouseEvent<HTMLOrSVGElement>): void => {
		e.preventDefault();
		e.stopPropagation();
		if (!inputRef.current) return;
		handleChange(null);
		// React weird. https://stackoverflow.com/questions/42192346/how-to-reset-reactjs-file-input
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		inputRef.current.value = null;
	};
	/**
	 * Determines styles for the recorder (mostly if it should dim or not)
	 */
	const getStyles = useCallback((): string => {
		const computedStyles = [styles.controls];
		if (
			isStarting ||
			(previewUrl && !playing && isShowingPreview)
		) computedStyles.push(styles.dim);

		return computedStyles.join(" ");
	}, [isStarting, previewUrl, playing, isShowingPreview]);
	/**
	 * This use effect sets up all event handlers for the preview video.
	 */
	useEffect(() => {
		if (videoRef.current && previewUrl) {
			if (playing) {
				setPlaying(false);
			}
			videoRef.current.srcObject = null;
			videoRef.current.autoplay = false;
			videoRef.current.muted = false;
			videoRef.current.onplaying = handlePlaying;
			videoRef.current.onpause = handlePaused;
			videoRef.current.ontimeupdate = setPlayingTime;
		}
		return () => {
			if (videoRef.current) {
				videoRef.current.srcObject = null;
				videoRef.current.onplaying = null;
				videoRef.current.ontimeupdate = null;
				videoRef.current.onpause = null;
			}
		};
	}, [videoRef, previewUrl]);

	/**
	 * Offline stuff. Might rework this later but for now it makes the most
	 * sense to do this here.
	 */
	if (offlineMode && !selectRecord) {
		return <div className={styles.offlineMode}>
			<p className={styles.questionText}>{text}</p>
			<div className={styles.actions}>
				<button onClick={() => setSelectRecord(true)}
					className={classNames(styles.button, styles.green)}
				>
					<div className={styles.dot}/>Record
				</button>
				<label
					htmlFor="upload-video"
					className={classNames(styles.button, styles.upload, loading && styles.disabled)}
				>
					<Icon name="upload" size="small" fill="white" /> Upload Video
					<input
						ref={inputRef}
						type="file"
						id="upload-video"
						onChange={e => handleChange(e.target.files)}
						disabled={loading}
					/>
				</label>
				{loading && <Spinner />}
				{rawFile && !loading ?
					<div className={styles.file}>
						<span>{rawFile[0].name}</span>
						<Icon
							name="garbage"
							size="medium"
							fill="var(--batterii-purple)"
							isClickable
							clicked={handleDelete}
						/>
					</div>
					: undefined}
			</div>
		</div>;
	}
	return (
		<>
			<div
				style={{
					display: previewUrl && isShowingPreview ? "none" : "flex",
				}}
				ref={publishRef}
				className={styles.recorder}
				id="tokbox-div"
			/>
			{startCountdown && <div className={styles.blocker}>
				<span>{countdown}</span>
			</div>}
			<video
				style={{display: isShowingPreview ? "initial" : "none"}}
				id="video-recording"
				className={styles.video}
				src={previewUrl}
				ref={videoRef}
				playsInline
			/>
			<PlayPreview
				isProcessing={isProcessing}
				hasPreview={Boolean(previewUrl)}
				isShowingPreview={isShowingPreview}
				togglePreview={togglePreview}
			/>

			<div className={getStyles()}>
				{previewUrl && isShowingPreview &&
					<PlayButton isPlaying={playing} onClick={handlePlay}/>
				}
				{ !playing && !isProcessing && !isShowingPreview &&
					<div className={styles.bottom}>
						<p className={styles.questionText}>{text}</p>
						<RecordButton
							isRecording={isRecording}
							isDisabled={!canRecord}
							isStarting={isStarting}
							onClick={handleStopStartRecording}
							hasPlayback={Boolean(previewUrl)}
						/>
						{ videoDevicesCount > 1 &&
						/* eslint-disable-next-line @typescript-eslint/no-misused-promises */
							<div className={styles.swap} onClick={toggleCamera}>
								<img src="images/swap.png" />
							</div>
						}

						{showMediaButton &&
							<Button onClick={toggleMediaModal} className={styles.showMediaButton}>Show image/video</Button>
						}
					</div>
				}
				{ !playing && !isProcessing && isRecording &&
					<div className={styles["timer-container"]}>
						Remaining:
						<span className={[
							styles.timer,
							seconds <= 15 && styles.red,
						].join(" ")}>
							{formatTime(seconds)}
						</span>
					</div>
				}
				{
					previewUrl && isShowingPreview &&
					<span className={styles["playback-timer"]}>{playingTimer}</span>
				}
			</div>
		</>
	);
};

export {VideoRecorder};
