import {User, linkWithCredential} from "firebase/auth";
import {useSignInWithEmailAndPassword} from "react-firebase-hooks/auth";
import React, {FormEvent, ReactElement, useEffect, useState} from "react";

import { SIGNUP_METHODS, auth } from "../../../utils/firebase";
import { Button, Input, Paragraph, StepTemplate } from "../../../components/sign-up-flow";
import { useNavigate } from "react-router-dom";
import { useSignUpFlow } from "../../../contexts/sign-up-flow-context";
import { sendAnalyticsEvent, setAnalyticsUser } from "../../../utils/analytics";

import styles from "./password.module.scss";

export const Password = (): ReactElement => {
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [passowordError, setPasswordError] = useState("");
	const surveyId = localStorage.getItem("surveyId");
	const {
		state: {
			email,
			firebaseCredentials,
		},
	} = useSignUpFlow();
	const [
		signInWithEmailAndPassword,
		/* userCredential */,
		loading,
		error,
	] = useSignInWithEmailAndPassword(auth);



	useEffect(() => {
		if (email === undefined) {
			navigate("/login/choose-login-method");
		}
	}, [email]);

	const connectNewProvider = async(user: User): Promise<void> => {
		if (!user || !firebaseCredentials) {
			throw new Error("User or firebase credentials are not defined");
		}
		await linkWithCredential(user, firebaseCredentials);
		navigate(`/${surveyId}`);
	};

	useEffect(() => {
		if (error) {
			if (error.code === "auth/wrong-password") {
				setPasswordError("The passoword you entered don't match");
				return;
			}
			setPasswordError(error.message);
		}
	}, [error]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		if (!password) {
			setPasswordError("Please enter a password");
			return;
		}

		signInWithEmailAndPassword(email ?? "", password).then(userCredential => {
			const user = userCredential?.user;
			if (user && firebaseCredentials) {
				connectNewProvider(user);
			} else if (user) {
				navigate(`/${surveyId}`);
				setAnalyticsUser(user.uid);
				sendAnalyticsEvent("login", SIGNUP_METHODS.EMAIL);
			}
		});
	};

	const handleRecoverPassword = (): void => {
		navigate("/login/recover-password");
	};

	return (
		<StepTemplate
			imageSrc="/images/sign-up-photo.jpg"
			header="Log in"
			revertRoute="/login/email?isLogin=true"
			imageText={
				<>
          “Vurvey is the future <br />
          of brand-consumer <br />
          relationships. <br />
          Be part of it.”
				</>
			}
		>
			<form className={styles.form} onSubmit={handleSubmit}>
				<Input
					placeholder="Password"
					type="password"
					value={password}
					errorMessage={passowordError}
					onChange={e => {
						setPassword(e.target.value);
						setPasswordError("");
					}}
				/>
				<Paragraph className={styles.recoverPasswordWrapper} onClick={handleRecoverPassword}>
          Trouble signing in?
				</Paragraph>
				<div className={styles.buttonsSection}>
					<Button
						id="sign-in-email-next"
						disabled={loading || Boolean(passowordError)}
						type="submit"
					>
            Next
					</Button>
				</div>
			</form>
		</StepTemplate>
	);
};
