/* eslint-disable max-len */
import React, {ReactElement} from "react";

import {Icon} from "../icon";
import {IconName} from "../icon/icon";

import styles from "./status-blocked.module.scss";

export type BlockedReason = "ANSWERED" | "CLOSED" | "PRIVATE" | "UNPUBLISHED" | "GENERIC" | "NOT_AUTHORIZED";

export interface StatusBlockedProps {
	blockedReason: BlockedReason;
}

interface StatusText {
	header: string;
	text: string;
	icon: IconName;
}
type IStatusText = {
	[key in BlockedReason]: StatusText;
};

const BLOCKED_REASONS: IStatusText = {
	ANSWERED: {
		header: "You've already responded to this vurvey",
		text: "The owner of this vurvey is only accepting one response per individual. If you think you reached this screen in error, please contact the owner of this vurvey.",
		icon: "big-grey-checkmark",
	},
	CLOSED: {
		header: "This vurvey is closed or private",
		text: "The owner of this vurvey is no longer accepting responses or has marked this survey as private. If you think you reached this screen in error, please contact the owner.",
		icon: "lock",
	},
	PRIVATE: {
		header: "You need an invite",
		text: "Only invited participants can join this vurvey. If you were invited, be sure you're signed in with the invited email address. If you think you reached this screen in error, please contact the owner of this vurvey.",
		icon: "envelope",
	},
	UNPUBLISHED: {
		header: "This vurvey is not yet published",
		text: "The owner of this vurvey has not yet made it available. If you think you reached this screen in error, please contact the owner.",
		icon: "eye",
	},
	GENERIC: {
		header: "There was a problem loading this vurvey",
		text: "To try and fix the issue, please try:",
		icon: "error",
	},
	NOT_AUTHORIZED: {
		header: "This vurvey is closed or private",
		text: "In order to view it you have to sign in",
		icon: "lock",
	},
};

const getSupportText = (blockedReason): ReactElement => {
	if (blockedReason === "GENERIC") {
		return <p>Still having problems? Visit the <a
			href="https://help.vurvey.co"
			className={styles.link}
			target="_blank"
			rel="noreferrer noopener"
		>
			support page
		</a>
		</p>;
	}
	return <p>Have more questions? Visit the <a
		href="https://help.vurvey.co"
		className={styles.link}
		target="_blank"
		rel="noreferrer noopener"
	>
		support page
	</a>
	</p>;
};

const StatusBlocked = (props: StatusBlockedProps): ReactElement => {
	const {blockedReason} = props;
	return (
		<div className={styles.container}>
			<img className={styles.logo} src="images/vurvey-logo-full.png" />
			<div className={styles.grid}>
				<div>
					<h1>{BLOCKED_REASONS[blockedReason].header}</h1>
					<p>{BLOCKED_REASONS[blockedReason].text}</p>
					{blockedReason === "GENERIC" &&
						<ul>
							<li>Signing out and signing back in</li>
							<li>Hard refreshing your browser (Ctrl+F5 or Shift+F5)</li>
							<li>Clearing your browser cache and cookies</li>
						</ul>
					}

					{getSupportText(blockedReason)}
				</div>
				<div className={styles.icon}>
					<Icon name={BLOCKED_REASONS[blockedReason].icon}/>
				</div>
			</div>
		</div>
	);
};

export {StatusBlocked};
