import React, {ReactElement, useEffect, useState} from "react";
import {Navigate, useLocation, useParams, useSearchParams} from "react-router-dom";
import {PreviewBar} from "../../components/preview-bar";
import {PulseButton} from "../../components/pulse-button";
import {SignedIn} from "../../components/signed-in";
import styles from "./intro.module.scss";
import {useGetIntroduction} from "../../hooks/useGetIntroduction";
import {useInnerWindowHeight} from "../../hooks/useInnerWindowHeight";
import {useIsPreview} from "../../hooks/useIsPreview";
import {ApolloQueryResult, useMutation} from "@apollo/client";
import {CREATE_RESPONSE, GET_USER_FROM_ACCESS_TOKEN} from "../../graphql/mutations/mutations";
import {SurveyAccessLevel, SurveyData, SurveyStatus} from "../../models/survey";
import {IntroDetails} from "../../components/intro-details";
import {getAuth, signInAnonymously, signOut} from "firebase/auth";
import { auth } from "../../utils/firebase";
import { useAuthentication } from "../../hooks/user/useAuthentication";

/**
 * Intro page for the vurvey.
 */
const Intro = (): ReactElement | null => {
	const {user, me} = useAuthentication();
	const {surveyId} = useParams<{surveyId: string}>();
	const isPreview = useIsPreview();
	const {search} = useLocation();
	const windowHeight = useInnerWindowHeight();
	const [canStart, setCanStart] = useState<boolean>(false);
	const [starting, setStarting] = useState(false);
	const {survey, currentResponse, loading, error, networkStatus, refetch} =
		useGetIntroduction(surveyId as string, (user?.isAnonymous || Boolean(user)), isPreview);

	function logout(): Promise<ApolloQueryResult<SurveyData>> {
		signOut(auth);
		return refetch();
	}

	const [createResponse] = useMutation(CREATE_RESPONSE);
	// For anonymous logging in
	const [getUserFromAccessToken] = useMutation(GET_USER_FROM_ACCESS_TOKEN);

	useEffect(() => {
		localStorage.removeItem("cursor");
	}, []);


	/**
	 * Sets the canStart state to true.
	 */
	const handleStart = (): void => {
		setStarting(true);
		if (!currentResponse && !isPreview) {
			createResponse({
				variables: {surveyId},
				onCompleted: data => {
					localStorage.setItem("responseId", data.createResponse.id);
					setCanStart(true);
				},
				onError: () => {
					setStarting(false);
				},
			});
		} else {
			setCanStart(true); // For preview mode.
		}
	};

	const downloadPdf = (): void => {
		if (!survey?.survey.offlineAssistPdf?.url) return;
		window.location.href = survey.survey.offlineAssistPdf.url;
	};

	const handleTakeAnonymously = (): void => {
		const auth = getAuth();
		signInAnonymously(auth).then(val => {
			getUserFromAccessToken({
				variables: {
					accessToken: (val.user as any)?.stsTokenManager?.accessToken,
					name: "Anonymous User",
				},
				onCompleted: data => {
					handleStart();
				},
				onError: error => {
					console.log(error);
					if (error.message.includes("token")) {
						localStorage.removeItem("token");
						logout();
					}
				},
			})
		});
	};

	/**
	 * Returns the button or nothing, if user cannot continue.
	 */
	const getButton = (): ReactElement | null => {
		if (!survey) return  null; // No survey no button
		// If we are loading or there is an error, no button
		if (loading || networkStatus === 4 || error) return null;
		// We first need to check if the access level is anonymous
		const isAnon = survey.survey.accessLevel === SurveyAccessLevel.ANONYMOUS;
		// If it's anonymous and we have no user, use this button instead.
		if (isAnon && !user) {
			return (
				<div className={styles.button}>
					<PulseButton
						text={starting ? "Starting up..." : survey.survey.startText}
						clicked={handleTakeAnonymously}
						size="large"
						isDisabled={starting}
					/>
				</div>
			);
		}
		// If a user doesn't have Response.create, they cannot start the survey.
		if (!survey.survey.permissions.includes("Response.create") && !isPreview) {
			return null;
		}
		if (
			(currentResponse?.completedAt ||
				survey.survey.status === SurveyStatus.CLOSED ||
				survey.survey.status === SurveyStatus.DRAFT) &&
			!isPreview // should always show button if this is a preview
		) {
			return null;
		}
		// Finally, if there is no signed in user, we don't want to show anything
		if (!user) return null;
		/**
		 * If we get this far, there should be a user that is signed in that has
		 * the Response.create permission, the survey isn't closed, and they haven't
		 * started the survey
		 */
		return (
			<div className={styles.button}>
				<PulseButton
					text={starting ? "Starting up..." : survey.survey.startText}
					clicked={handleStart}
					size="large"
					isDisabled={starting}
				/>
			</div>
		);
	};

	// Redirect to new preview page
	if (isPreview) return <Navigate to={{pathname: `/${surveyId}/preview`}} />;
	/**
	 * Displaying starts below here
	 */
	if (canStart && !loading && networkStatus !== 4) {
		// If we can start, go straight to the questions
		if (!user && survey?.survey.accessLevel === SurveyAccessLevel.ANONYMOUS) {
			return <Navigate
					to={{
						pathname: `/${surveyId}/questions`,
						search
					}}
				/>
		}
		if (user) {
			return (
				<Navigate
					to={{
						pathname: `/${surveyId}/questions`,
						search,
					}}
				/>
			);
		}
	}
	return (
		<div style={{height: windowHeight}} className={styles.page}>
			{isPreview && <PreviewBar />}
			<div className={styles.container}>
				<IntroDetails
					survey={survey?.survey}
					loading={loading}
					error={error}
					currentResponse={currentResponse}
					isPreview={isPreview}
				/>
				{survey?.survey.offlineAssist && (
					<div className={styles.offline}>
						<b>🌱 Going offline?</b>&nbsp;Take the questions with you and submit later.
						<button className={styles.download} onClick={downloadPdf}>
							Download PDF
						</button>
					</div>
				)}
			</div>
			<div className={styles.bottom}>
				{getButton()}
				{!loading && !user?.isAnonymous &&
					<SignedIn
					onLogout={logout}
					surveyId={surveyId}
					accessLevel={survey?.survey.accessLevel}
					/>
				}
				{survey?.survey.disclaimer && (
					<div className={styles.disclaimer}>{survey.survey.disclaimer}</div>
				)}
			</div>
		</div>
	);
};

export {Intro};
