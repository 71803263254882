import React, {ReactElement, useContext} from "react";
import {QuestionContext} from "../../context/question-context";
import {RangeSlider} from "../range-slider";
import {SliderQuestionType} from "../../models/question";
import {StarRating} from "../star-rating";
import styles from "./slider-question.module.scss";

export interface SliderQuestionProps {
	question: SliderQuestionType;
}

const SliderQuestion = (props: SliderQuestionProps): ReactElement | null => {
	const {question} = props;
	const {numberAnswer, setNumberAnswer} = useContext(QuestionContext);

	return (
		<div className={styles.container}>
			{question.subtype === "star"
				? (
					<StarRating
						max={question.max}
						onChange={setNumberAnswer}
						value={numberAnswer}
						na={question.na}
					/>
				)
				: <RangeSlider
					value={numberAnswer || question.min}
					max={question.max}
					min={question.min}
					leftLabel={question.leftLabel}
					rightLabel={question.rightLabel}
					onChange={setNumberAnswer}
				/>
			}
		</div>
	);
};

export {SliderQuestion};
