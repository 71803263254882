import React, {ReactElement} from "react";
import {CssSpinner} from "../css-spinner";
import {Icon} from "../icon";
import styles from "./play-preview.module.scss";

export interface PlayPreviewProps {
	/**
	 * If we are currently processing the video
	 */
	isProcessing: boolean;
	/**
	 * If there is a previewUrl or not
	 */
	hasPreview: boolean;
	/**
	 * Determines to show play button or "X"
	 */
	isShowingPreview: boolean;
	/**
	 * Toggle preview callback
	 */
	togglePreview: () => void;
}

/**
 * Displays a spinner, a play button, or an "X" depending on props given.
 * @param isProcessing If the video is currently processing or not
 * @param hasPreview If our video recorder has a preview URL or not
 * @param isShowingPreview If we are currently showing the preview. (Shows "X")
 * @param togglePreview Callback function to toggle if we are showing preview.
 */
const PlayPreview = (props: PlayPreviewProps): ReactElement | null => {
	const {hasPreview, isProcessing, isShowingPreview, togglePreview} = props;

	if (isProcessing) {
		return <div className={styles.container}>
			<CssSpinner size="small"/>
		</div>;
	}
	if (hasPreview && !isShowingPreview) {
		return <div
			className={styles.preview}
			onClick={togglePreview}
		/>;
	}
	if (hasPreview && isShowingPreview) {
		return <div
			className={styles.back}
			onClick={togglePreview}
		>
			<Icon name="cross" fill="black" size="medium"/>
		</div>;
	}
	return null;
};

export {PlayPreview};
