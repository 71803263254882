import React, { ReactElement } from "react"
import { Outlet } from "react-router-dom";

import { SignUpFlowContextProvider } from "../../../contexts/sign-up-flow-context"

import styles from "./sign-up-flow-wrapper.module.scss";

export const SignUpFlowWrapper = (): ReactElement => {
  return (
    <SignUpFlowContextProvider>
      <div className={styles.wrapper}>
        <Outlet />
      </div>
    </SignUpFlowContextProvider>
  )
};
