import React, {ReactElement, useEffect, useState} from "react";
import classNames from "classnames/bind";

import styles from "./progress-bar.module.scss";

const cx = classNames.bind(styles);

export interface ProgressBarProps {
	value: number;
	initialValue?: number;
	showNumber?: boolean;
}

export const ProgressBar = ({
	value,
	initialValue = value,
	showNumber = false,
}: ProgressBarProps): ReactElement => {
	const [width, setWidth] = useState(initialValue);

	useEffect(() => {
		setWidth(value);
	}, [value]);

	return (
		<div className={cx("wrapper", {showNumber})}>
			<span
				style={{width: `${width}%`}}
				className={styles.value}
			>
				{showNumber && (
					<span className={styles.number}>
						{value}%
					</span>
				)}
			</span>
		</div>
	);
};
