import React, {Dispatch, ReactElement, createContext, useContext, useReducer} from "react";

import { SignUpFlowAction, SignUpFlowReducer, SignUpFlowState } from "../reducers/sign-up-flow-reducer";

export interface SignUpFlowContextType {
	state: SignUpFlowState;
	dispatch: Dispatch<SignUpFlowAction>;
}

export const SignUpFlowContext = createContext<SignUpFlowContextType | undefined>(undefined);

export const SignUpFlowContextProvider = (
	{children}: {children: ReactElement},
): ReactElement => {
	const [state, dispatch] = useReducer(SignUpFlowReducer, {});

	return (
		<SignUpFlowContext.Provider value={{state, dispatch}}>
			{children}
		</SignUpFlowContext.Provider>
	);
};

export const useSignUpFlow = (): SignUpFlowContextType => {
	const context = useContext(SignUpFlowContext);

	if (context === undefined) {
		throw new Error("useSignUpFlow must be used within a SignUpFlowContextProvider");
	}

	return context;
};
