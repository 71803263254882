import classNames, {Argument} from "classnames";
import React, {ReactElement} from "react";

export interface InputProps {
	id: string;
	className?: Argument;
	onChange?: (newValue: any) => void;
	children?: React.ReactNode;
}

export const Input = ({className, children}: InputProps): ReactElement =>
	<div className={classNames(className)}>
		{children}
	</div>;
