import React, {ReactElement, useEffect} from "react";
import {
	signInWithPopup,
	OAuthProvider,
	OAuthCredential,
	fetchSignInMethodsForEmail,
	linkWithCredential,
	getAdditionalUserInfo,
} from "firebase/auth";
import {useSignInWithMicrosoft} from "react-firebase-hooks/auth";
import {useNavigate} from "react-router-dom";

import {SIGNUP_METHODS, auth} from "../../../utils/firebase";
import {useSignUpFlow} from "../../../contexts/sign-up-flow-context";
import {SignUpFlowActionTypes} from "../../../reducers/sign-up-flow-reducer";
import { sendAnalyticsEvent, setAnalyticsUser } from "../../../utils/analytics";

import styles from "./buttons.module.scss";

export interface MicrosoftSignInButtonProps {
	text?: string;
	id?: string;
}

export const MicrosoftSignInButton = ({
	text = "Sign up with Microsoft",
	id,
}: MicrosoftSignInButtonProps): ReactElement => {
	const navigate = useNavigate();
	const {dispatch} = useSignUpFlow();
	const surveyId = localStorage.getItem("surveyId");
	const [
		signInWithMicrosoft,
		/* userCredential */,
		/* loading */,
		error,
	] = useSignInWithMicrosoft(auth);
	const changeEmail = (payload: string): void => {
		dispatch({type: SignUpFlowActionTypes.SET_EMAIL, payload});
	};

	const changeFirebaseCredentials = (payload: OAuthCredential): void => {
		dispatch({type: SignUpFlowActionTypes.SET_FIREBASE_CREDENTIALS, payload});
	};

	const connectNewProvider = async (
		email: string,
		credentials: OAuthCredential,
	): Promise<void> => {
		const methods = await fetchSignInMethodsForEmail(auth, email);
		if (methods[0] === "password") {
			changeEmail(email);
			changeFirebaseCredentials(credentials);
			navigate("/login/password");
			return;
		}
		const exsistingProvider = new OAuthProvider(methods[0]);
		const result = await signInWithPopup(auth, exsistingProvider);
		linkWithCredential(result.user, credentials).then(() => {
			navigate(`/${surveyId}`);
		});
	};

	useEffect(() => {
		if (!error) {
			return;
		}

		const {code, customData: {email}} = error;
		const credentials = OAuthProvider.credentialFromError(error);

		if (
			code === "auth/account-exists-with-different-credential" &&
			credentials instanceof OAuthCredential
		) {
			connectNewProvider(email ?? "", credentials);
		}
	}, [error]);

	const handleSignIn = (): void => {
		if (text === "Sign up with Microsoft") {
			sendAnalyticsEvent("sign_up", SIGNUP_METHODS.MICROSOFT);
		}
		signInWithMicrosoft().then(result => {
			if (result) {
				const {isNewUser} = getAdditionalUserInfo(result) ?? {};
				setAnalyticsUser(result.user.uid);


				if (isNewUser) {
					sendAnalyticsEvent("create_account", SIGNUP_METHODS.MICROSOFT);
					sendAnalyticsEvent("complete_registration", SIGNUP_METHODS.MICROSOFT);
					sendAnalyticsEvent("login", SIGNUP_METHODS.MICROSOFT);

					navigate(`/${surveyId}`);
					return;
				}

				sendAnalyticsEvent("login", SIGNUP_METHODS.MICROSOFT);
				navigate(`/${surveyId}`);
			}
		});
	};

	return (
		<button id={id} onClick={handleSignIn} className={styles.authButton}>
			<img src="/images/microsoft-icon.svg" alt="Microsoft Icon" />
			<span className={styles.authButtonText}>{text}</span>
		</button>
	);
};
