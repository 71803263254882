import React, {ReactElement, useCallback, useContext, useEffect, useState} from "react";
import {Choice} from "../../models/answer";
import {DisplayContext} from "../../context/display-context";
import {OptionItem} from "../option-item";
import {Question} from "../../models/question";
import {QuestionContext} from "../../context/question-context";
import {shuffleArray} from "../../utility";
import styles from "./multiple-choice.module.scss";
import {reorder} from "../../utility/utility";


export interface MultipleChoiceProps {
	question: Question
}

const MultipleChoice = (props: MultipleChoiceProps): ReactElement | null => {
	const {
		question: {choices, shuffle},
	} = props;

	const {selected, questionType, setMulti, setSingle, setRanked} = useContext(QuestionContext);
	const {setHideVideo} = useContext(DisplayContext);

	const [choiceList, setChoiceList] = useState<Choice[]>(() => choices as Choice[]);
	const [hasBeenShuffled, setHasBeenShuffled] = useState<boolean>(false);

	if (shuffle && !hasBeenShuffled) {
		const shuffledChoices = choiceList.slice();
		shuffleArray(shuffledChoices);
		setChoiceList(shuffledChoices);
		setHasBeenShuffled(true);
	}

	// Detects if we have shuffled and then updates our current list if it is a ranked question
	useEffect(() => {
		if (hasBeenShuffled && questionType === "RANKED") {
			setRanked(choiceList.map(choice => choice.id));
		}
	}, [hasBeenShuffled]);

	const dragOption = useCallback((dragIndex: number, hoverIndex: number) => {
		setChoiceList((prevChoices: Choice[]) => reorder(prevChoices, dragIndex, hoverIndex));
	}, []);

	const moveOption = useCallback((): void => {
		const newRanking = choiceList.map(value => value.id);
		setRanked(newRanking);
	}, [choiceList]);


	const setSingleChoice = (newChoice: string): void => {
		setHideVideo(false);
		setSingle(newChoice);
	};

	return (
		<div className={styles.choices}>
			{choiceList.map((choice, i) =><OptionItem
				choice={choice}
				selected={selected.some(value => value === choice.id)}
				key={choice.id}
				onSelect={questionType === "CHOICE"
					? setSingleChoice :
					questionType === "MULTISELECT" ? setMulti : undefined
				}
				questionType={questionType}
				index={i}
				dragOption={dragOption}
				moveOption={moveOption}
			/>)}
		</div>
	);
};

export {MultipleChoice};
