import React, {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames/bind";

import styles from "./Body.module.scss";

const bStyles = classNames.bind(styles);

export type BodyProps = PropsWithChildren<{
	className?: string;
	size?: "xl" | "l" | "base" | "s" | "xs";
	type?: "regular" | "medium" | "semibold" | "bold";
	color?: "text-body" | "text-secondary" | "text-tertiary" | "gray-modern-900" | "volt-500"
	| "charcoal-elevation-100" | "purple-brand-300" | "purple-brand-400" | "purple-brand-500"
	| "purple-brand-600" | "red-600" | "red-500" | "gray-modern-400" | "gray-modern-500"
	| "gray-modern-800" | "charcoal-elevation-25";
	onClick?: () => void;
}>;

export const Body = ({
	children,
	size = "base",
	type = "regular",
	color = "text-body",
	className,
	onClick,
}: BodyProps): ReactElement => {
	return <div className={bStyles("body", size, type, color, className)} onClick={onClick}>
		{children}
	</div>;
};
