/* eslint-disable react-hooks/rules-of-hooks */
import React, {FormEvent, ReactElement, useContext, useEffect, useState} from "react";
import {useCreateUserWithEmailAndPassword} from "react-firebase-hooks/auth";

import { useSignUpFlow } from "../../../contexts/sign-up-flow-context";
import { SIGNUP_METHODS, auth, sendVerificationEmail } from "../../../utils/firebase";
import { useNavigate } from "react-router-dom";
import { Button, Input, ProgressBar, StepTemplate } from "../../../components/sign-up-flow";
import { sendAnalyticsEvent, setAnalyticsUser } from "../../../utils/analytics";
import { ToastContext } from "../../../context/toast-context";

import styles from "./create.module.scss";

export const Create = (): ReactElement => {
	const navigate = useNavigate();
	const {state: {email}} = useSignUpFlow();
	const {updateToast} = useContext(ToastContext);

	const [
		createUserWithEmailAndPassword,
		/* user */,
		loading,
		error,
	] = useCreateUserWithEmailAndPassword(auth);

	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [repeatPasswordError, setRepeatPasswordError] = useState("");

	useEffect(() => {
		if (email === undefined) {
			navigate("/login");
		}
	}, [email]);

	useEffect(() => {
		if (error) {
			if (error.code === "auth/weak-password") {
				setPasswordError("Password should be at least 6 characters");
				return;
			}
			setPasswordError(error.message);
		}
	}, [error]);

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();

		if (!password) {
			setPasswordError("Please enter a password");
			return;
		}

		if (!repeatPassword) {
			setRepeatPasswordError("Please enter a password");
			return;
		}

		if (password !== repeatPassword) {
			setPasswordError("Passwords do not match");
			setRepeatPasswordError("Passwords do not match");
			return;
		}


		createUserWithEmailAndPassword(email ?? "", password).then(result => {
			if (result) {
				setAnalyticsUser(result.user.uid);
				sendAnalyticsEvent("create_account", SIGNUP_METHODS.EMAIL);
				sendVerificationEmail(result.user);
				updateToast({
					type: "success",
					description: "Please check your email and verify your account.",
				});
			}
		});
	};

	return (
		<StepTemplate
			revertRoute="/login/email"
			imageSrc="/images/sign-up-photo.jpg"
			imageText={
				<>
          “Vurvey is the future <br />
          of brand-consumer <br />
          relationships. <br />
          Be part of it.”
				</>
			}
		>
			<form className={styles.form} onSubmit={handleSubmit}>
				<div className={styles.inputsSection}>
					<Input
						label="Email"
						value={email}
						disabled
					/>
					<Input
						label="Password"
						type="password"
						value={password}
						errorMessage={passwordError}
						onChange={e => {
							setPassword(e.target.value);
							setPasswordError("");
							setRepeatPasswordError("");
						}}
					/>
					<Input
						label="Confirm password"
						type="password"
						value={repeatPassword}
						errorMessage={repeatPasswordError}
						onChange={e => {
							setRepeatPassword(e.target.value);
							setPasswordError("");
							setRepeatPasswordError("");
						}}
					/>
				</div>
				<div className={styles.buttonsSection}>
					<Button
						id="create-account-next"
						disabled={loading || Boolean(passwordError) || Boolean(repeatPasswordError)}
						type="submit"
					>
						Next
					</Button>
				</div>
				<ProgressBar
					showNumber
					initialValue={33}
					value={66}
				/>
			</form>
		</StepTemplate>
	);
};
