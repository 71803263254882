import classNames from "classnames";
import React, {ReactElement, useState} from "react";
import {Icon} from "../icon";
import styles from "./star-rating.module.scss";

export interface StarRatingProps {
	max: number;
	value: number | null | undefined;
	na: boolean;
	onChange: (value: number | null | undefined) => void;
}


const StarRating = (props: StarRatingProps): ReactElement => {
	const {max, value, na, onChange} = props;
	const [hover, setHover] = useState<number>(0);

	const handleNa = (): void => {
		onChange(null);
		setHover(0);
	};
	return (
		<div className={styles.container}>
			{Array(max).fill(1).map((_, i) => <div
				key={`star-${i}`}
				className={classNames(styles.star, hover >= i + 1 && styles.selected)}
				onMouseEnter={() => setHover(i)}
				onMouseLeave={() => setHover(Number(value))}
			>
				<Icon
					name="star"
					clicked={() => onChange(i + 1)}
				/>
			</div>)}
			{na &&
				<div
					className={styles.checkContainer}
					onClick={handleNa}
				>
					<div className={classNames(styles.check, value === null && styles.selected)}>
						<Icon
							name="big-grey-checkmark"
							size="small"
							fill={value === null ? "white" : "var(--batterii-grey-light)"}
							className={styles.icon}
						/>
					</div>
					<span>N/A</span>
				</div>
			}
		</div>
	);
};

export {StarRating};
