import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import {Navigate, useLocation, useParams} from "react-router-dom";
import {AnswerUpload, CreateAnswerData, CreateAnswerVars} from "../../models/answer";
import {CREATE_ANSWER} from "../../graphql/mutations/mutations";
import {DisplayContextProvider} from "../../context/display-context";
import {EndLayout} from "../../hoc/end-layout";
import {ImageModal} from "../../components/image-modal";
import {Layout} from "../../hoc";
import {QuestionDisplay} from "../../components/question";
import {QuestionNav} from "../../components/question-nav";
import {QuestionType} from "../../models/question";
import {RequestCamera} from "../../components/request-camera";
import {QuestionContextProvider} from "../../context/question-context";
import {Spinner} from "../../components/spinner";
import {VideoRecorder} from "../../components/video-recorder";
import {useGetQuestion} from "../../hooks/useGetQuestion";
import {useIsPreview} from "../../hooks/useIsPreview";
import {useMutation} from "@apollo/client";
import {UploadError, useTokBox} from "../../hooks/useTokBox";
import {RetryBanner} from "../../components/retry-banner";
import {StatusBlocked} from "../../components/status-blocked";
import {LoadingPage} from "../loading-page";
import {GenericErrorPage} from "../../components/generic-error-page";
import {SurveyAccessLevel} from "../../models/survey";

/**
 * Question page component. Gets the questions in order as a user
 * goes through them.
 */
const QuestionPage = (): ReactElement | null => {
	/**
	 * General state and param declarations.
	 */
	const {surveyId} = useParams<{surveyId: string}>();
	const {search} = useLocation();
	const isPreview = useIsPreview();
	const [choice, setChoice] = useState<string[]>([]);
	const [text, setText] = useState<string>();
	const [numberAnswer, setNumberAnswer] = useState<number | null>();
	const [files, setFiles] = useState<string[]>([]);
	const [pdf, setPdf] = useState<string[]>([]);
	const [barcode, setBarcode] = useState<string[]>([]);
	const [video, setVideo] = useState<string | null>(null);
	const [showModal, setShowModal] = useState<boolean>(false);
	const responseIdRef = useRef<string | null>(localStorage.getItem("responseId"));
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [surveyClosed, setSurveyClosed] = useState<boolean>(false);
	const videoState = useState(true);
	/**
	 * Our getQuestion hook returns.
	 */
	const {
		question,
		loading,
		error,
		surveyStats,
		getNextQuestion,
		isFinished,
	} = useGetQuestion(surveyId as string, isPreview);
	/**
	 * Getting media recorder items to pass down
	 */
	const {
		reset,
		startVideo,
		getRecording,
		retryAnswerVideo,
		accessDenied,
		stopRecording,
		previewUrl,
		startRecording: startRecordTok,
		session,
		recordingId,
		recordingState,
		toggleCamera,
		videoDevicesCount,
		uploadError,
		setUploadError,
	} = useTokBox();

	const resetValues = (): void => {
		setChoice([]);
		setText(undefined);
		setNumberAnswer(undefined);
		setFiles([]);
		setPdf([]);
		setVideo(null);
	};

	const [showDeniedBanner, setShowDeniedBanner] = useState(accessDenied)

	useEffect(() => {
		setShowDeniedBanner(accessDenied);
	}, [accessDenied]);
	/**
	 * Fetches the next question using the nextQuestion refetch query
	 */
	const handleNextQuestion = async(): Promise<void> => {
		await getNextQuestion();
		setIsSubmitting(false);
	};

	const hideDenied = (): void => {
		setShowDeniedBanner(false);
	};
	/**
	 * Create answer mutation. On a success we will store the responseId if it is not
	 * already stored, and then move on to the next question.
	 * On an error, we mostly just move on to the next question as it doesn't
	 * concern the end user too much right now.
	 *
	 * We may need to handle errors differently in the future, but for now these
	 * are the requirements.
	 */
	const [createAnswer] = useMutation<CreateAnswerData, CreateAnswerVars>(CREATE_ANSWER, {
		onCompleted: data => {
			if (!localStorage.getItem("responseId")) {
				responseIdRef.current = data.createAnswer.responseId;
				localStorage.setItem("responseId", data.createAnswer.responseId);
			}
			if (uploadError !== UploadError.NONE) setUploadError(UploadError.NONE);
			resetValues();
			reset();
			handleNextQuestion();
		},
		onError: createError => {
			if (createError.networkError) {
				setUploadError(UploadError.SUBMIT);
				setIsSubmitting(false);
				return;
			}
			const name = createError.graphQLErrors[0]?.extensions?.name;
			/**
			 * Right now we only have requirements to handle these two errors,
			 * may need to handle other ones differently in the future.
			 */
			if (name === "NotFoundError" || name === "InvalidAnswerError") {
				resetValues();
				reset();
				handleNextQuestion();
			} else if (name === "AccessDeniedError") {
				setSurveyClosed(true);
			} else {
				resetValues();
				reset();
				handleNextQuestion();
			}
		},
	});

	// Used to doubly make sure document doesn't scroll. Had some weird errors before this.
	useEffect(() => {
		document.body.style.overflow = "hidden";
		return () => {
			document.body.style.overflow = "unset";
		};
	}, []);


	/**
	 * Stores the full answer for the question, then fetches the next question
	 * @param video video file received from the video question
	 */
	const handleAcceptVideo = async(): Promise<void> => {
		setIsSubmitting(true);
		if (question) {
			const newAnswer: AnswerUpload = {
				questionId: question?.id,
				choiceIds: choice.length > 0 ? choice : undefined,
				videoId: surveyStats?.offlineAssist ? (video || recordingId) : recordingId,
				responseId: localStorage.getItem("responseId"),
				numberAnswer,
				text: typeof text === "string" ? text : undefined,
				uploadedImageIds: files.length ? files : undefined,
				uploadedVideoId: surveyStats?.offlineAssist ? undefined : video ?? undefined,
				uploadedPdfIds: pdf.length ? pdf : undefined,
				barcodeIds: barcode.length > 0 ? barcode : undefined,
			};
			await createAnswer({variables: {input: newAnswer}});
		}
	};

	const handleSkipQuestion = async(): Promise<void> => {
		setIsSubmitting(true);
		if (!question) return;
		const newAnswer: AnswerUpload = {
			questionId: question.id,
			responseId: localStorage.getItem("responseId"),
			skip: true,
		};
		await createAnswer({variables: {input: newAnswer}});
	};

	/**
	 * Toggles the state of our image modal
	 */
	const handleShowModal = (): void => setShowModal(prevState => !prevState);
	/* eslint-disable max-len */
	const canContinue = useMemo((): boolean => {
		if (isSubmitting) {
			return false;
		}
		if (question && !loading) {
			if (question.type === QuestionType.BARCODE) return barcode.length > 0;
			if (!question.videoResponse) {
				return (choice.length > 0 || numberAnswer !== undefined || Boolean(text) || files.length > 0 || Boolean(video) || pdf.length > 0);
			}
			if (question.type === QuestionType.VIDEO) {
				if (surveyStats?.offlineAssist) return Boolean(previewUrl) || Boolean(video);
				return Boolean(previewUrl);
			}
			if (surveyStats?.offlineAssist) {
				return ((choice.length > 0 || numberAnswer !== undefined || Boolean(text) || files.length > 0 || pdf.length > 0) && (Boolean(video) || Boolean(previewUrl)));
			}
			return (choice.length > 0 || numberAnswer !== undefined || Boolean(text) || files.length > 0 || pdf.length > 0 || Boolean(video)) && Boolean(previewUrl);
		}
		return false;
	}, [question, barcode, loading, previewUrl, choice, isSubmitting, numberAnswer, text, files, video, pdf, surveyStats?.offlineAssist]);
	// eslint-disable-next-line max-len

	/**
	 * Displays the bulk of this page.
	 * Future fun enhancement project, probably.
	 */
	const displayQuestion = (): JSX.Element => {
		if (loading) return <Spinner />;
		if (question && !loading) {
			let canRecord = false;
			if (question.type === QuestionType.VIDEO) {
				canRecord = true;
			} else if (choice !== undefined) {
				canRecord = true;
			}
			const showRecorder = session && !accessDenied;
			const videoRecorder = showRecorder
				? <VideoRecorder
					session={session}
					previewUrl={previewUrl}
					startVideo={startVideo}
					reset={reset}
					canRecord={canRecord}
					stopRecording={stopRecording}
					startRecording={() => startRecordTok(question.id)}
					text={question.followUp || question.text}
					recordingState={recordingState}
					timeLimit={question.timeLimit}
					toggleCamera={toggleCamera}
					videoDevicesCount={videoDevicesCount}
					uploadError={uploadError}
					offlineMode={surveyStats?.offlineAssist}
					toggleMediaModal={handleShowModal}
					showMediaButton={Boolean(question.image || question.video || question.arModel)}
				/>
				: null;
			return <QuestionDisplay
				question={question}
				videoRecorder={videoRecorder}
				handleShowModal={handleShowModal}
			/>;
		}
		if (!question && !error) {
			return <EndLayout>
				<StatusBlocked blockedReason="CLOSED"/>
			</EndLayout>;
		}
		return <div>An error occurred {error?.message}</div>;
	};

	/**
	 * Begin rendering component
	 */
	if (isFinished) {
		return <Navigate
			to={{
				pathname: `/${surveyId}/thank-you`,
				search,
			}}
			state={{
				thankYouMessage: surveyStats?.thankYouMessage,
				redirectUrl: surveyStats?.redirectUrl,
				accessLevel: surveyStats?.accessLevel,
			}}
		/>;
	}
	if (surveyClosed) {
		return <EndLayout>
			<StatusBlocked blockedReason="CLOSED"/>
		</EndLayout>;
	}

	if (loading && !(question && surveyStats)) return <LoadingPage />;

	// this happens when there is no questions added to the campaign
	if (!responseIdRef.current && !isPreview) return <Navigate to={`/${surveyId}`} />;
	if (!(question && surveyStats)) return <GenericErrorPage />;

	return (
		<QuestionContextProvider
			selected={choice}
			barcode={barcode}
			numberAnswer={numberAnswer}
			text={text}
			files={files}
			setText={setText}
			setFiles={setFiles}
			setNumberAnswer={setNumberAnswer}
			setBarcode={setBarcode}
			setSelected={setChoice}
			question={question}
			video={video}
			setVideo={setVideo}
			pdf={pdf}
			setPdf={setPdf}
		>
			<Layout
				withPreview={surveyStats?.status === "DRAFT" || isPreview}
				hasDisclaimer={Boolean(surveyStats?.disclaimer)}
			>
				<DisplayContextProvider videoState={videoState} question={question}>
					{
						showDeniedBanner && <RequestCamera handleHide={hideDenied}/>
					}
					{
						isSubmitting ? <Spinner /> : displayQuestion()
					}
					<QuestionNav
						canContinue={canContinue}
						currentQuestion={question?.index + 1}
						questionCount={surveyStats.questionCount}
						acceptAnswer={handleAcceptVideo}
						isSkippable={!question.required}
						skipQuestion={handleSkipQuestion}
						disclaimer={surveyStats.disclaimer}
						anonymous={surveyStats.accessLevel === SurveyAccessLevel.ANONYMOUS}
					/>
					{ (question.image || question.video || question.arModel) &&
						<ImageModal
							media={{
								image: question.image,
								video: question.video,
								arModel: question.arModel,
							}}
							isShowing={showModal}
							handleClose={handleShowModal}
							muteAudio={recordingState.isRecording}
						/>
					}
					<RetryBanner
						retry={{
							getRecording,
							acceptAnswer: handleAcceptVideo,
							retryAnswerVideo,
						}}
						uploadError={uploadError}
					/>
				</DisplayContextProvider>
			</Layout>
		</QuestionContextProvider>
	);
};

export {QuestionPage};
