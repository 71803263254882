import {QuestionPage} from "./question";

export interface Survey extends SurveyStats {
	id: string;
	name: string;
	accessLevel: SurveyAccessLevel;
	startText: string;
	title: string;
	introduction: string;
	instructions: string;
	answeredByMe: boolean;
	introImage?: SurveyIntroImage;
	introVideo?: SurveyIntroVideo;
	responseToken: string | null;
	disclaimer: string | null;
	permissions: string[];
	multiResponse: boolean;
	completedResponseCount?: number;
	closeAt?: Date;
	closeAfterResponsesCount?: number;
	incentiveAmount?: string;
	incentiveCurrency: CurrencyType;
	estimatedCompletionTime?: number;
	offlineAssistPdf?: {url: string};
}

export interface SurveyIntroVideo {
	id: string;
	small: string;
	medium: string;
	video: string;
	mp4: string;
}
export interface SurveyIntroImage {
	id: string;
	small: string;
	medium: string;
	full: string;
}

export enum SurveyStatus {
	DRAFT = "DRAFT",
	OPEN = "OPEN",
	CLOSED = "CLOSED",
	ARCHIVED = "ARCHIVED",
}

export enum SurveyAccessLevel {
	PRIVATE = "PRIVATE",
	PUBLIC = "PUBLIC",
	ANONYMOUS = "ANONYMOUS",
}

export interface SurveyData {
	survey: Survey;
}

export interface SurveyStatsData {
	survey: SurveyStats;
}

export interface SurveyStats {
	accessLevel: SurveyAccessLevel;
	redirectUrl: string | null;
	status: SurveyStatus;
	questionCount: number;
	disclaimer: string | null;
	offlineAssist: boolean;
	thankYouMessage?: string;
}

export enum CurrencyType {
	USD = "USD",
	EUR = "EUR",
	CAD = "CAD",
	THB = "THB",
	CNY = "CNY",
	GBP = "GBP",
}

export interface PreviewData {
	survey: Survey;
	questions: QuestionPage;
}
