import {ApolloError, useQuery} from "@apollo/client";
import {GET_NEXT_QUESTION} from "../graphql/queries/queries";
import {NextQuestionData, Question} from "../models/question";
import {SurveyStats, SurveyStatsData} from "../models/survey";
import {useRef, useState} from "react";

export interface UseGetQuestionReturn {
	/**
	 * The question data returned by the query
	 */
	question?: Question;
	/**
	 * If the query is loading or not
	 */
	loading: boolean;
	/**
	 * Error
	 */
	error: ApolloError | undefined;
	/**
	 * If there are no more questions
	 */
	isFinished: boolean;
	/**
	 * Survey stats
	 */
	surveyStats?: SurveyStats;
	/**
	 * Get next question using refetch
	 */
	getNextQuestion: () => Promise<void>;
}

/**
 * Returns just the current question that should be displayed.
 */
export const useGetQuestion = (surveyId: string, isPreview: boolean): UseGetQuestionReturn => {
	const [isFinished, setIsFinished] = useState<boolean>(false);
	// const questionsRef = useRef<Question>();
	const statsRef = useRef<SurveyStats>();
	const {data: next, loading: loadingNext, error: errorNext, refetch} =
	useQuery<NextQuestionData & SurveyStatsData>(GET_NEXT_QUESTION, {
		skip: isPreview || !localStorage.getItem("responseId"),
		variables: {responseId: localStorage.getItem("responseId"), surveyId},
		notifyOnNetworkStatusChange: true,
	});

	const getNextQuestion = async(): Promise<void> => {
		await refetch().then(data => {
			if (data && !data.data?.nextQuestion) {
				setIsFinished(true);
			}
		});
	};

	return {
		question: next?.nextQuestion,
		loading: loadingNext,
		error: errorNext,
		surveyStats: next?.survey || statsRef.current,
		isFinished,
		getNextQuestion,
	};
};
