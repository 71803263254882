import classNames from "classnames/bind";
import React, { PropsWithChildren, ReactElement } from "react";

import styles from "./paragraph.module.scss";

const bStyles = classNames.bind(styles);

export type ParagraphProps = PropsWithChildren<{
	className?: string;
	color?: "black" | "gray-800";
	onClick?: () => void;
}>;

export const Paragraph = ({
	children,
	className,
	color = "black",
	onClick,
}: ParagraphProps): ReactElement => (
	<p className={bStyles("paragraph", color, className)} onClick={onClick}>
		{children}
	</p>
);
