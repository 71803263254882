import queryString from "query-string";
import {useLocation} from "react-router-dom";

/**
 * Returns a boolean value or undefined. If preview=true
 * exists in the search params, will return true.
 * Otherwise this will return undefined.
 */
export const useIsPreview = (): boolean => {
	const {search} = useLocation();
	const parsed = queryString.parse(search, {parseBooleans: true});
	return parsed.preview as boolean;
};

