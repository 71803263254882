import React, {FormEvent, ReactElement, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";

import {useAuthentication} from "../../../hooks/user/useAuthentication";
import {Button, ProgressBar, StepTemplate} from "../../../components/sign-up-flow";
import {ToastContext} from "../../../context/toast-context";
import {SIGNUP_METHODS, sendVerificationEmail } from "../../../utils/firebase";
import { sendAnalyticsEvent } from "../../../utils/analytics";

import styles from "./verify-email.module.scss";

export const VerifyEmail = (): ReactElement => {
	const {updateToast} = useContext(ToastContext);
	const navigate = useNavigate();
	const {logout} = useAuthentication();
	const {user} = useAuthentication();
	const [descriptionText, setDescriptionText] = React.useState<string>(
		"Please check your email and verify your account.",
	);


	useEffect(() => {
		// sends Google Analytics event when user confirms email
		const interval = setInterval(() => {
			if (user) {
				user.reload().then(() => {
					if (interval && user.emailVerified) {
						clearInterval(interval);
						sendAnalyticsEvent("complete_registration", SIGNUP_METHODS.EMAIL);
						sendAnalyticsEvent("login", SIGNUP_METHODS.EMAIL);
						updateToast({
							type: "success",
							description: "Your email has been verified. Thank you for signing up!",
						});
						window.location.reload();
					}
				});
			}
		}, 3000);
	}, []);

	const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		window.location.reload();
	};

	const handleResendVerificationEmail = (): void => {
		if (user) {
			sendVerificationEmail(user);
			updateToast({
				type: "success",
				description: "Please check your email and verify your account.",
			});
			const newLocal = `We sent an email to ${user.email} with a link to verify your account. Refresh this page after confirming your email.`;
			setDescriptionText(newLocal);
		}
	};

	return (
		<StepTemplate
			id="final-step-create"
			imageSrc="/images/sign-up-photo.jpg"
			description={descriptionText}
			onRevert={() => {
				logout().then(() => navigate("/login"));
			}}
			imageText={
				<>
					“Vurvey is the future <br />
					of brand-consumer <br />
					relationships. <br />
					Be part of it.”
				</>
			}
		>
			<form className={styles.form} onSubmit={handleSubmit}>
				<div className={styles.buttonsSection}>
					<Button type="submit" id="resend-email-button">
						Reload Vurvey
					</Button>
					<button
						type="button"
						onClick={handleResendVerificationEmail}
						className={styles.resendEmailButton}
					>
						Don’t see the email? Click to resend.
					</button>
				</div>
				<ProgressBar showNumber initialValue={66} value={85} />
			</form>
		</StepTemplate>
	);
};
