/* eslint-disable max-len */
import React, {ReactElement, useContext, useState} from "react";
import {CREATE_ANSWER_IMAGE, CREATE_PDF, UPLOAD_FILE} from "../../graphql/mutations/mutations";
import {QuestionContext} from "../../context/question-context";
import {FileInput} from "../file-input";
import styles from "./file-upload.module.scss";
import {useMutation} from "@apollo/client";
import {ToastContext} from "../../context/toast-context";
import {CreateAnswerImage, CreateAnswerPDF, UploadFileReturn} from "../../models/mutation-returns";
import {Question} from "../../models/question";

export interface FileUploadQuestionProps {
	question: Question;
	mobile?: boolean;
	type: "image" | "pdf"
}

const FileUploadQuestion = ({question, mobile = false, type}: FileUploadQuestionProps): ReactElement => {
	const {files, setFiles, setPdf} = useContext(QuestionContext);
	const {updateToast} = useContext(ToastContext);
	const [fileArray, setFileArray] = useState<File[]>([]);
	const [loading, setLoading] = useState(false);


	const [uploadItem] = useMutation<UploadFileReturn>(UPLOAD_FILE);
	const [createAnswerImage] = useMutation<CreateAnswerImage>(CREATE_ANSWER_IMAGE);
	const [createPdfAnswer] = useMutation<CreateAnswerPDF>(CREATE_PDF);

	const errorHelper = type === "image"
		? "Error uploading image. Supports JPEG, PNG, and GIF with max file size of 25 MB" :
		"Error uploading PDF. Make sure you have selected the correct file";

	const startUpload = async(files: File[]): Promise<any> => {
		const uploadPromises = await Promise.all(files.map(file => {
			return uploadItem({
				variables: {file},
				onError: () => {
					setLoading(false);
					updateToast({type: "failure", description: errorHelper});
				},
			});
		}));
		if (uploadPromises) {
			if (type === "image") {
				return Promise.all(uploadPromises.map(result => {
					return createAnswerImage({
						variables: {uploadItemId: result.data?.upload.id},
						onError: () => {
							updateToast({description: errorHelper, type: "failure"});
							setLoading(false);
						},
					});
				})).then(result => {
					setLoading(false);
					const failure = result.some(res => res.errors);
					if (failure) {
						// We should just want to return here with how it's handled now
						return;
					}
					const ids = result.map(res => res.data?.createAnswerImage.id);
					setFiles(prev => [...prev, ...ids as string[]]);
					setFileArray(prev => [...prev, ...files]);
				}).catch(() => {
					updateToast({description: errorHelper, type: "failure"});
					setLoading(false);
				});
			}
			// We do this if it is a PDF file.
			return Promise.all(uploadPromises.map(result => {
				return createPdfAnswer({
					variables: {uploadItemId: result.data?.upload.id},
				});
			})).then(result => {
				setLoading(false);
				const failure = result.some(res => res.errors);
				if (failure) {
					setFileArray([]);
					setPdf([]);
					return;
				}
				const ids = result.map(res => res.data?.createAnswerPdf.id);
				setPdf(ids as string[]);
				setFileArray([...files]);
			}).catch(() => {
				updateToast({description: errorHelper, type: "failure"});
				setFileArray([]);
				setLoading(false);
			});
		}
	};

	const handleChange = (fileList: FileList | null): void => {
		if (!fileList) {
			setFiles([]);
			setFileArray([]);
			setPdf([]);
			return;
		}
		if (fileList.length + fileArray.length > 3) return updateToast({description: "You may upload a max of 3 files", type: "failure"});
		const arrayFromList = Array.from(fileList);
		setLoading(true);
		startUpload(arrayFromList)
	};

	const handleRemoveFile = (index: number): void => {
		if (!fileArray.length) return;
		const copyFileIds = [...files];
		const copyFileArray = [...fileArray];
		copyFileIds.splice(index, 1);
		copyFileArray.splice(index, 1);
		setFiles(copyFileIds);
		setFileArray(copyFileArray);
		setPdf([]);
	};

	return (
		<div className={styles.container}>
			<FileInput
				id="file-upload"
				onChange={handleChange}
				loading={loading}
				supportedFileTypes={type === "image" ? ["JPEG", "PNG", "GIF"] : ["PDF"]}
				maxFileSize="25 MB"
				allowMultiple={question.max === 3}
				files={fileArray}
				handleRemoveFile={handleRemoveFile}
			/>
			{question.max && question.max > 1 && !mobile &&
				<p className={styles.helpText}>
					Hint: on a computer? Hold CTRL or CMD to select multiple files!
				</p>
			}
		</div>
	);
};

export {FileUploadQuestion};
