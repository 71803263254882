import {useEffect, useState} from "react";

export interface WindowDimensions {
	height: number;
	width: number;
}

export const useWindowDimensions = (): WindowDimensions => {
	const [dimensions, setDimensions] =
	useState<WindowDimensions>({height: window.innerHeight, width: window.innerWidth});
	useEffect(() => {
		const handleResize = (): void => {
			setDimensions({height: window.innerHeight, width: window.innerWidth});
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return dimensions;
};
