import React, {ReactElement} from "react";
import {XYCoord, useDragLayer} from "react-dnd";
import {DragItemTypes} from "../../constants/constants";
import styles from "./custom-drag-layer.module.scss";
import optionStyles from "../option-item/option-item.module.scss";
import {Icon} from "../icon";



interface OptionDragPreviewProps {
	text: string;
	index: number;
}

const OptionDragPreview = (props: OptionDragPreviewProps): ReactElement => <div
	// preview takes into account margin, removes left and right margin for good alignment
	style={{marginLeft: 0, marginRight: 0}}
	className={optionStyles.option}
>
	<div className={optionStyles.number}>{props.index + 1}</div>
	{props.text}
	<div
		className={optionStyles.handle}
	>
		<Icon name="handle"/>
	</div>
</div>;

const getItemStyles = (
	initialOffset: XYCoord | null,
	currentOffset: XYCoord | null,
): React.CSSProperties => {
	if (!initialOffset || !currentOffset) {
		return {
			display: "none",
		};
	}
	const {x, y} = currentOffset;
	const transform = `translate(${x}px, ${y}px)`;
	return {
		transform,
		WebkitTransform: transform,
	};
};

const CustomDragLayer = (): ReactElement | null => {
	const {itemType, isDragging, item, initialOffset, currentOffset} =
	useDragLayer(monitor => ({
		item: monitor.getItem(),
		itemType: monitor.getItemType(),
		initialOffset: monitor.getInitialSourceClientOffset(),
		currentOffset: monitor.getSourceClientOffset(),
		isDragging: monitor.isDragging(),
	}));

	if (!isDragging) return null;
	if (itemType !== DragItemTypes.OPTION) return null;
	return <div className={styles.layer}>
		<div style={getItemStyles(initialOffset, currentOffset)}>
			<OptionDragPreview text={item.text} index={item.index}/>
		</div>
	</div>;
};

export {CustomDragLayer};
