import React, {ReactElement} from "react";
import {useLocation} from "react-router";

import {config} from "../../config";
import {RichTextInput} from "../../components/rich-text-input";
import styles from "./thank-you.module.scss";
import {isParseable} from "../../utility/helpers";
import {SurveyAccessLevel} from "../../models/survey";
import {useQuery} from "@apollo/client";
import {GET_ME} from "../../graphql/queries/queries";
import {Spinner} from "../../components/spinner";
import {MeData} from "../../models/user";
import {PulseButton} from "../../components/pulse-button";

interface CustomizedState {
	thankYouMessage: string;
	redirectUrl: string | null;
	accessLevel: SurveyAccessLevel;
}
/**
 * Simple thank you page for when a user completes the survey
 */
const ThankYou = (): ReactElement => {
	const location = useLocation();
	const {data, loading} = useQuery<MeData>(GET_ME);
	console.log("hello?", location.search);

	const {thankYouMessage, redirectUrl, accessLevel} = location?.state as CustomizedState;

	if (redirectUrl) {
		setTimeout(() => {
			window.location.href = `${redirectUrl}${location?.search}`;
		}, 3000);
	}

	const handleNav = (): void => {
		window.location.href = `${config.creatorHost}/me`;
	};
	if (loading) {
		return (
			<div className={styles.gridContainer}>
				<div className={styles.thanksContainer}>
					<Spinner />
				</div>
			</div>
		);
	}
	return <div className={styles.gridContainer}>
		<div className={styles.thanksContainer}>
			<div className={styles.completed}>
				<svg
					width="52"
					height="38"
					viewBox="0 0 52 38"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M2 20L17 35L50 2" stroke="white" strokeWidth="4"/>
				</svg>
			</div>
			<div className={styles.thankYou}>
				{(!thankYouMessage && data?.me?.moreInfo && !redirectUrl)
					? <div className={styles.more}>
						Thank you!
						<p>You can unlock more opportunities by filling out your profile</p>
						<PulseButton text="Go to profile" clicked={handleNav}/>
					</div>
					: isParseable(thankYouMessage) ?
						thankYouMessage && <RichTextInput text={thankYouMessage} /> :
						thankYouMessage || "Thank you for responding! 🎉"}
				{redirectUrl && <div className={styles.redirecting}>
					You will be redirected to another page momentarily.
					If you are not redirected automatically, <a href={`${redirectUrl}${location?.search}`}>click here.</a>
				</div>
				}
			</div>
		</div>
		{!loading && !data?.me?.moreInfo &&
		<div className={styles.footerContainer}>
			<span className={styles.footerText}>
			Do More with Vurvey
			</span>
			<button className={styles.footerButton} onClick={() => {
				if (redirectUrl) {
					// Open in a new window if this window is about to be redirected
					window.open(config.creatorHost);
				} else {
					window.location.href = config.creatorHost;
				}
			} }>
				{accessLevel === "ANONYMOUS" ? "Create an Account" : "Go To My Profile"}
			</button>
		</div>
		}
	</div>;
};

export {ThankYou};
