import React, {ReactElement} from "react";
import {Input, InputProps} from "../input";
import styles from "./textarea.module.scss";

export interface TextareaProps extends InputProps {
	value: string | undefined;
	onChange: (newValue: string) => void;
	placeholder?: string;
}

const Textarea = ({value, id, onChange, placeholder, ...props}: TextareaProps): ReactElement => {
	return (
		<Input {...props} id={id}>
			<textarea
				id={id}
				className={styles.textarea}
				value={value || ""}
				placeholder={placeholder}
				onChange={e => onChange(e.target.value)}
			/>
		</Input>
	);
};

export {Textarea};
