import {useEffect, useState} from "react";

/**
 * Returns the inner height of the current window
 */
export const useInnerWindowHeight = (): number => {
	const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight);
	useEffect(() => {
		const handleResize = (): void => {
			setWindowHeight(window.innerHeight);
		};
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	return windowHeight;
};
