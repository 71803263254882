/* eslint-disable multiline-ternary */
import React, {ReactElement, useContext, useEffect, useRef, useState} from "react";
import styles from "./scan-question.module.scss";
import {Question} from "../../models/question";
import {useScanner} from "../../hooks/useScanner";
import classNames from "classnames";
import {useMutation} from "@apollo/client";
import {CONFIRM_BARCODE, CREATE_BARCODE} from "../../graphql/mutations/mutations";
import {QuestionContext} from "../../context/question-context";
import {singularOrPlural} from "../../utility/utility";
import {Spinner} from "../spinner";
import {Barcode, ConfirmBarcodeProps} from "../../models/barcode";
import {ConfirmBarcodeReturn, CreateBarcode} from "../../models/mutation-returns";

export interface ScanQuestionProps {
	question: Question;
}

enum CreateStatus {
	WAITING,
	UPLOADING,
	DONE,
	USER_PICKING,
	CONFIRMING,
	CONFIRMED,
	ERROR,
}

const ScanQuestion = ({question}: ScanQuestionProps): ReactElement => {
	const {video, scannedText, setScannedText, startVideo, stopScan} = useScanner();

	const ref = useRef<HTMLInputElement>(null);
	const [status, setStatus] = useState<CreateStatus>(CreateStatus.WAITING);
	const [currentBar, setCurrentBar] = useState<Barcode>();
	const [index, setIndex] = useState(0);
	const [edit, setEdit] = useState(false);
	const [selectedIndex, setSelected] = useState<number>();
	const {setBarcode, barcode} = useContext(QuestionContext);
	// const {updateToast} = useContext(ToastContext);

	const [createBarcode] = useMutation<CreateBarcode>(CREATE_BARCODE);
	const [confirmBarcode] = useMutation<ConfirmBarcodeReturn, ConfirmBarcodeProps>(CONFIRM_BARCODE);

	const reset = (): void => {
		setScannedText("");
		setCurrentBar(undefined);
		setIndex(0);
		setSelected(undefined);
	};

	const toggleEdit = (): void => {
		if (!ref.current) return;
		setEdit(true);
		ref.current.focus();
	};

	const handleSaveChanged = (): void => {
		stopScan();
		setEdit(false);
	};

	const handleCreateBarcode = (): void => {
		setStatus(CreateStatus.UPLOADING);
		createBarcode({
			variables: {barcode: scannedText},
			onCompleted: data => {
				setStatus(CreateStatus.USER_PICKING);
				if (data.createBarcode) setCurrentBar(data.createBarcode);
			},
			onError: () => setStatus(CreateStatus.ERROR),
		});
	};

	const handleConfirmBarcode = (id: string, prodId: string, dontPush: boolean): void => {
		setStatus(CreateStatus.CONFIRMING);
		confirmBarcode({
			variables: {id, productId: prodId},
			onCompleted: data => {
				if (!data.confirmBarcodeProduct) return;
				setStatus(CreateStatus.CONFIRMED);
				setSelected(index);
				if (dontPush) return;
				const copy = [...barcode]; // Copy our current barcodeIds
				// Pop the most recent value, then replace it with new
				copy.push(data.confirmBarcodeProduct.id);
				setBarcode(copy); // Set to new array of barcodes
			},
		});
	};

	const handleResume = (): void => {
		setStatus(CreateStatus.WAITING);
		reset();
		startVideo();
	};

	const changeIndex = (action: "inc" | "dec"): void => {
		if (action === "inc") return setIndex(prev => prev + 1);
		setIndex(prev => prev - 1);
	};

	useEffect(() => {
		if (scannedText && status !== CreateStatus.UPLOADING && !edit) handleCreateBarcode();
	}, [scannedText, edit]);

	const products = currentBar?.possibleProducts;
	return (
		<div className={styles.container}>
			<h1 className={styles.questionText}>{question.text}</h1>
			<div className={styles.vidContainer}>
				{video}
				{status === CreateStatus.UPLOADING && <div className={styles.loading}>
					<Spinner />
				</div>}
				{(status === CreateStatus.USER_PICKING
					|| status === CreateStatus.CONFIRMING
					|| status === CreateStatus.CONFIRMED || status === CreateStatus.ERROR)
					&& <div className={styles.decision}>
						{products && products.length > 0 ?
							<div className={styles.productContainer}>
								{products.length > 1 && <>
									<span
										className={classNames(styles.left, index === 0 && styles.disabled)}
										onClick={() => changeIndex("dec")}
									/>
									<span
									// eslint-disable-next-line max-len
										className={classNames(styles.right, index === products.length - 1 && styles.disabled)}
										onClick={() => changeIndex("inc")}/>
								</>}
								<div className={styles.product}>
									<span className={styles.productName}>{products[index].title}</span>
									{products[index].images.length > 0 && <img
										className={styles.image}
										alt={products[index].title}
										src={products[index].images[0]}
									/>}
									<div className={styles.actions}>
										<button
											className={classNames(styles.button, styles.confirm)}
											// eslint-disable-next-line max-len
											onClick={() => handleConfirmBarcode(currentBar.id, products[index].id, (Boolean(selectedIndex) || selectedIndex === 0))}
											disabled={status === CreateStatus.CONFIRMING || selectedIndex === index}
										>
											{status === CreateStatus.CONFIRMING
												? "Confirming..."
												: selectedIndex === index ?
													"Selected"
													: "Confirm"}
										</button>
										{status === CreateStatus.USER_PICKING &&
									<button
										className={classNames(styles.button, styles.tryAgain)}
										onClick={handleResume}
									>
										Try again
									</button>}
									</div>
								</div>
							</div>
							// We should get here if CreateStatus.ERROR is true, also if there are no products
							: <div className={styles.empty}>
								<h2>There were no products found matching the barcode scanned</h2>
								<button
									className={classNames(styles.button, styles.tryAgain)}
									onClick={handleResume}
								>
									Try again
								</button>
							</div>}
					</div>
				}
			</div>
			<div className={styles.inputContainer}>
				<div className={styles.scanActions}>
					{status === CreateStatus.CONFIRMED && <button
						onClick={handleResume}
						className={classNames(styles.button, styles.another)}
					>
						Scan another
					</button>}
					{barcode.length > 0 && status !== CreateStatus.WAITING &&
					<span className={styles.scanned}>
							Saved {barcode.length} {singularOrPlural("scan", barcode.length)}
					</span>}
				</div>
				<div className={styles.inputWithButton}>
					<input
						value={scannedText}
						onChange={e => setScannedText(e.target.value)}
						readOnly={!edit}
						className={styles.input}
						ref={ref}
					/>
					{edit ?
						<button
							className={styles.save}
							onClick={handleSaveChanged}
							disabled={!scannedText}
						>Save</button>
						: <button
							className={styles.edit}
							onClick={toggleEdit}
						>Edit</button>}
				</div>
			</div>
		</div>
	);
};

export {ScanQuestion};
