import classNames, {Argument} from "classnames";
import React, {ReactElement} from "react";
import styles from "./pulse-button.module.scss";

export interface PulseButtonProps {
	/**
	 * Text of the button
	 */
	text: string;
	/**
	 * What to do when clicking the button
	 */
	clicked?: () => any;
	/**
	 * If button is disabled or not
	 */
	isDisabled?: boolean;
	/**
	 * Size of button. Default will not specify any size
	 */
	size?: "large" | "default";
	className?: Argument;
}

/**
 * This button periodically "pulses" while it is active
 * @param text The text of the button
 * @param isDisabled If button should be disabled or not
 * @param clicked What happens when button is clicked
 */
const PulseButton = (props: PulseButtonProps): ReactElement => {
	const {text, clicked, isDisabled, size = "default", className} = props;
	const style: string[] = [styles.button];
	if (size && size !== "default") {
		style.push(styles[size]);
	}
	return (
		<button
			disabled={isDisabled}
			onClick={clicked}
			className={classNames(styles.button, styles[size], className)}
		>
			{text}
		</button>
	);
};

export {PulseButton};
