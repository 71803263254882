import {gql} from "@apollo/client";

export const LOGIN_WITH_ACCESS_TOKEN = gql`
	mutation LoginWithAccessToken($accessToken: String!, $remember: Boolean) {
		loginWithAccessToken(type:COOKIE, accessToken: $accessToken, remember: $remember) {
			user {
				id
				name
				email
			}
		}
	}
`;

export const GET_USER_FROM_ACCESS_TOKEN = gql`
	mutation GetUserFromAccessToken($accessToken: String!, $name: String) {
		login(accessToken: $accessToken, name: $name) {
			user {
				id
				name
				email
			}
		}
	}
`;

export const LOGIN_ANONYMOUSLY = gql`
	mutation loginAnonymously {
		loginAnonymously {
			user {
				id
				name
				email
			}
		}
	}
`;

export const LOGOUT = gql`
	mutation LogoutLegacy {
		logout
	}
`;

/**
 * Mutation will register using given email. We want to be able to login the user
 * immediately after now. Old way required us to do email verification immediately.
 *
 * Should be able to login immediately, and also accept the terms.
 */
export const REGISTER = gql`
	mutation Register (
		$email: EmailAddress!
		$password: String!
		$firstName: String!
		$lastName: String!
		) {
		registerWithEmail(type: COOKIE, email: $email registration: {
			password: $password,
			firstName: $firstName,
			lastName: $lastName,
		}) {
			user {
				id
				name
				email
			}
		}
		acceptTerms
	}
`;

/* Uploady boi */
export const UPLOAD_FILE = gql`
	mutation Upload ($file: Upload!) {
		upload(file: $file) {
			id
			filename
			mimetype
		}
	}
`;

export const CREATE_BARCODE = gql`
	mutation CreateBarcode ($barcode: String!) {
		createBarcode(barcode: $barcode) {
			id
			barcode
			possibleProducts {
				id
				barcodeNumber
				barcodeFormats
				description
				images
				title
			}
		}
	}
`;

/**
 * Needed in order to confirm the barcode we want.
 */
export const CONFIRM_BARCODE = gql`
	mutation ConfirmBarcode (
		$id: String!
		$noneOfTheOptions: Boolean
		$productId: String
		$customProduct: CustomProduct
	) {
			confirmBarcodeProduct(
			barcodeId: $id
			noneOfTheOptions: $noneOfTheOptions
			productId: $productId,
			customProduct: $customProduct
		) {
			id
			barcode
		}
	}
`;
/**
 * Creates image file that we allow to upload
 */
export const CREATE_ANSWER_IMAGE = gql`
	mutation CreateAnswerImage ($uploadItemId: String!) {
		createAnswerImage(uploadItemId: $uploadItemId) {
			id
		}
	}
`;
/**
 * Creates the video answer to associate with a questions' answer.
 * We mostly just want the ID from it, but might grab other stuff eventually.
 */
export const CREATE_ANSWER_VIDEO = gql`
	mutation AnswerVideo ($recordingId: String!) {
		createAnswerVideo(recordingId: $recordingId) {
			id
			url (version:ORIGINAL)
		}
	}
`;
/**
 * Creates the PDF to associate with a questions' answer.
 * Same return as an AnswerImage.
 */
export const CREATE_PDF = gql`
	mutation CreatePdfAnswer($uploadItemId: String!) {
		createAnswerPdf(uploadItemId: $uploadItemId) {
			id
		}
	}
`;

/**
 * Used for video upload type questions. May bring up that we probably shouldn't
 * need to use the questionId for this, but unsure what we're doing on the backend
 * for this right now.
 */
export const UPLOAD_ANSWER_VIDEO = gql`
	mutation UploadAnswer ($uploadItemId: String! $questionId: String!) {
		uploadAnswerVideo(uploadItemId: $uploadItemId questionId: $questionId) {
			id
			transcodingStatus
		}
	}
`;

/**
 * Creates the TokBox session needed to start live recording
 */
export const CREATE_RECORDING_SESSION = gql`
	mutation CreateRecordingSession {
		createRecordingSession {
			id
			apiKey
			token
		}
	}
`;

/**
 * Starts the recording
 */
export const START_RECORDING = gql`
	mutation StartRecording ($sessionId: String! $questionId: GUID!) {
		createRecording(sessionId: $sessionId, questionId: $questionId) {
			id
		}
	}
`;
/**
 * Stops recording session
 */
export const STOP_RECORDING_SESSION = gql`
	mutation StopRecordingSession ($id: String!) {
		stopRecording(id: $id) {
			id
		}
	}
`;

/**
 * Creates a singular answer.
 */
export const CREATE_ANSWER = gql`
	mutation CreateAnswer ($input: NewAnswer) {
		createAnswer(input: $input) {
			id
			questionId
			responseId
			userId
		}
	}
`;


export const CREATE_RESPONSE = gql`
	mutation CreateResponse ($surveyId: GUID!) {
		createResponse(surveyId: $surveyId) {
			id
			surveyId
		}
	}
`;
