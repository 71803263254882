import React, {ReactElement} from "react";
import {Input, InputProps} from "../input";
import styles from "./number-input.module.scss";

export interface NumberInputProps extends InputProps{
	value: number | undefined | null;
	onChange: (newValue: number | undefined | null) => void;
}

const NumberInput = ({value, onChange, ...props}: NumberInputProps): ReactElement => {
	const checkValue = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (e.target.value === "") return onChange(undefined);
		onChange(parseFloat(e.target.value));
	};
	return (
		<Input {...props}>
			<input
				className={styles.input}
				type="number"
				value={value === undefined ? "" : Number(value)}
				maxLength={100} // No set limit, but 100 seems reasonable
				onChange={checkValue}
			/>
			<p className={styles.helper}>Numbers only</p>
		</Input>
	);
};

export {NumberInput};
